import React, { FC } from "react";
import { IconProps } from "../types";

export const ArrowTop: FC<IconProps> = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M12 19a1 1 0 0 1-1-1V7.414l-4.293 4.293a1 1 0 0 1-1.414-1.414l6-6a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1-1.414 1.414L13 7.414V18a1 1 0 0 1-1 1z"
      />
    </svg>
  );
};
