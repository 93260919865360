import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type AboutUsReport = {
  __typename?: 'AboutUsReport';
  totalReviews: Scalars['Int'];
  countriesWithGuides: Scalars['Int'];
  registeredGuides: Scalars['Int'];
};

export type AddCouponToBookingInput = {
  bookingId: Scalars['Int'];
  couponCode: Scalars['String'];
};

export type Announcement = {
  __typename?: 'Announcement';
  id: Scalars['Int'];
  announcement: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  status: Scalars['Boolean'];
  priority: Scalars['Int'];
};

export type ApiInfoType = {
  __typename?: 'ApiInfoType';
  enviroment: Scalars['String'];
};

export enum AuthRequestType {
  Signin = 'SIGNIN',
  Signup = 'SIGNUP'
}

export type AuthenticateInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type AuthenticatePayload = {
  __typename?: 'AuthenticatePayload';
  token: Scalars['String'];
};

export type AuthenticateWithFacebookInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  facebookId: Scalars['String'];
  request: AuthRequestType;
};

export type AuthenticateWithFacebookPayload = {
  __typename?: 'AuthenticateWithFacebookPayload';
  token: Scalars['String'];
};

export type AuthenticateWithGoogleInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  googleId: Scalars['String'];
  request: AuthRequestType;
};

export type AuthenticateWithGooglePayload = {
  __typename?: 'AuthenticateWithGooglePayload';
  token: Scalars['String'];
};

export enum AuthorType {
  Supplier = 'SUPPLIER',
  Company = 'COMPANY'
}

export type AuthorizePayPalOrderInput = {
  bookingId: Scalars['Int'];
  orderId: Scalars['String'];
};

export type AuthorizePayPalOrderPayload = {
  __typename?: 'AuthorizePayPalOrderPayload';
  booking: Booking;
};

export type BaseUser = {
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  isSupplier: Scalars['Boolean'];
  isActivity: Scalars['Boolean'];
  isBlogger: Scalars['Boolean'];
  guideApply: Scalars['Boolean'];
  guideApplyStep?: Maybe<Scalars['String']>;
  isSupplierApply?: Maybe<Scalars['Boolean']>;
  isGuide?: Maybe<Scalars['Boolean']>;
  isAdmin: Scalars['Boolean'];
  isBan: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  displayName: Scalars['String'];
  picture?: Maybe<Media>;
  pictureId?: Maybe<Scalars['Int']>;
  gallery?: Maybe<Array<Media>>;
  country?: Maybe<Country>;
  phone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  nationality?: Maybe<Country>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  isSocialLoginEnabled: Scalars['Boolean'];
  biography?: Maybe<Scalars['String']>;
  isBiographyUpdated: Scalars['Boolean'];
  bookings: BookingsConnection;
  bookingById: Booking;
  messageById: Message;
  messages: MessageConnection;
  blogs: BlogsConnection;
};


export type BaseUserBookingsArgs = {
  filters?: Maybe<BookingFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type BaseUserBookingByIdArgs = {
  id: Scalars['Int'];
};


export type BaseUserMessageByIdArgs = {
  id: Scalars['Int'];
};


export type BaseUserMessagesArgs = {
  filters?: Maybe<MessageFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type BaseUserBlogsArgs = {
  filters?: Maybe<MyPostFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};

export type BlogContentImage = {
  imageId: Scalars['Int'];
  src: Scalars['String'];
};

export type BlogsConnection = {
  __typename?: 'BlogsConnection';
  pageInfo: PageInfo;
  edges: Array<BlogsEdge>;
  totalCount: Scalars['Int'];
};

export type BlogsEdge = {
  __typename?: 'BlogsEdge';
  node: Post;
  cursor: Scalars['String'];
};

export type Booking = {
  __typename?: 'Booking';
  id: Scalars['Int'];
  userId: Scalars['Float'];
  supplierId: Scalars['Float'];
  supplier?: Maybe<Supplier>;
  user: User;
  paymentType: Scalars['String'];
  items: Array<BookingItem>;
  invoice: BookingInvoice;
  status: BookingStatus;
  payoutStatus: PayoutStatus;
  payoutAt?: Maybe<Scalars['DateTime']>;
  payoutMethod: PayoutMethod;
  payoutTransactionId?: Maybe<Scalars['String']>;
  payoutAmount?: Maybe<Scalars['Float']>;
  payoutError?: Maybe<Scalars['String']>;
  meetingDate: Scalars['DateTime'];
  meetingTime?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  paymentDate?: Maybe<Scalars['DateTime']>;
  payout: BookingPayout;
  review?: Maybe<Review>;
  city?: Maybe<City>;
  duration: Scalars['String'];
  adultsCount: Scalars['Int'];
  childrenCount: Scalars['Int'];
  infantCount: Scalars['Int'];
  code: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  itinerary?: Maybe<Array<TourItinerary>>;
  tour?: Maybe<Tour>;
  meetinPoint: Scalars['String'];
  meetingPointDescription?: Maybe<Scalars['String']>;
  endingTime?: Maybe<Scalars['String']>;
  endingPoint?: Maybe<Scalars['String']>;
  included?: Maybe<Scalars['String']>;
  excluded?: Maybe<Scalars['String']>;
  important?: Maybe<Scalars['String']>;
  hotel?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  bookingType: BookingType;
  latestMessageId: Scalars['Int'];
  overview?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  paymentId?: Maybe<Scalars['String']>;
  cancellationPolicyId?: Maybe<Scalars['String']>;
  cancellationPolicyRules?: Maybe<Scalars['String']>;
  canCancel?: Maybe<Scalars['Boolean']>;
  canReview: Scalars['Boolean'];
  refundPercent?: Maybe<Scalars['Int']>;
  getPeopleSummary: Scalars['String'];
  currency: Currency;
  showNamePhoneInUi: Scalars['Boolean'];
};

export type BookingFilters = {
  MEETING_DATE_TO?: Maybe<Scalars['String']>;
  MEETING_DATE_FROM?: Maybe<Scalars['String']>;
  MEETING_DATE_MONTH?: Maybe<Scalars['String']>;
  BOOKINGS_STATUS?: Maybe<Array<BookingStatus>>;
  SUPPLIERS_IDS?: Maybe<Array<Scalars['Int']>>;
  USER_IDS?: Maybe<Array<Scalars['Int']>>;
  SEARCH?: Maybe<Scalars['String']>;
  ORDER_BY?: Maybe<BookingOrderBy>;
  WITH_REVIEW?: Maybe<Scalars['Boolean']>;
};

export type BookingInvoice = {
  __typename?: 'BookingInvoice';
  type: Scalars['String'];
  cardFeePercent: Scalars['Float'];
  cardFee: Scalars['Float'];
  net: Scalars['Float'];
  total: Scalars['Float'];
  supplierFee: Scalars['Float'];
  serviceFee: Scalars['Float'];
  gwgBilledAmount?: Maybe<Scalars['Float']>;
  cash: Scalars['Float'];
  online: Scalars['Float'];
  currencyId: Scalars['String'];
};

export type BookingItem = {
  __typename?: 'BookingItem';
  id: Scalars['Int'];
  bookingId: Scalars['Float'];
  booking: Booking;
  description: Scalars['String'];
  quantity: Scalars['Float'];
  unitPrice: Scalars['Float'];
  totalPrice: Scalars['Float'];
  type: BookingItemType;
};

export enum BookingItemType {
  Coupon = 'COUPON',
  Refund = 'REFUND',
  Normal = 'NORMAL'
}

export enum BookingOrderBy {
  PaymentDateDesc = 'PAYMENT_DATE_DESC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  MeetingDateAsc = 'MEETING_DATE_ASC',
  LatestUpdate = 'LATEST_UPDATE',
  MeetingDateDesc = 'MEETING_DATE_DESC'
}

export type BookingPaymentErrorInput = {
  bookingId: Scalars['Int'];
  errorLog: Scalars['String'];
};

export type BookingPayout = {
  __typename?: 'BookingPayout';
  method: PayoutMethod;
  payoutAt?: Maybe<Scalars['DateTime']>;
  status: PayoutStatus;
  amount: Scalars['Float'];
  transactionId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type BookingQuotationItem = {
  __typename?: 'BookingQuotationItem';
  type: BookingQuotationItemType;
  description: Scalars['String'];
  price: Scalars['Float'];
};

export enum BookingQuotationItemType {
  Default = 'DEFAULT',
  Coupon = 'COUPON'
}

export enum BookingStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Paid = 'PAID',
  Confirmed = 'CONFIRMED',
  Canceled = 'CANCELED'
}

export enum BookingType {
  Default = 'DEFAULT',
  PaymentRequest = 'PAYMENT_REQUEST'
}

export type BookingsConnection = {
  __typename?: 'BookingsConnection';
  pageInfo: PageInfo;
  edges: Array<BookingsEdge>;
  totalCount: Scalars['Int'];
};

export type BookingsEdge = {
  __typename?: 'BookingsEdge';
  node: Booking;
  cursor: Scalars['String'];
};

export type CalculateBookingCancellationPolicyRulePayLoad = {
  __typename?: 'CalculateBookingCancellationPolicyRulePayLoad';
  meetingDateAt: Scalars['String'];
  cancelAt: Scalars['String'];
  daysDiff: Scalars['String'];
  refundPercent: Scalars['Int'];
};

export type Calendar = {
  __typename?: 'Calendar';
  minDate: Scalars['String'];
  maxDate: Scalars['String'];
  bookingsDates: Array<Scalars['String']>;
  notWorkingDates: Array<Scalars['String']>;
  workingDates: Array<Scalars['String']>;
  daysOfWeekAvailable: Array<Scalars['Int']>;
  daysOfWeekBlocked: Array<Scalars['Int']>;
  tourIsSpecificDates?: Maybe<Scalars['Boolean']>;
};

export type CancelBookingInput = {
  bookingId: Scalars['Int'];
  cancelReason: Scalars['String'];
  charge?: Maybe<Scalars['Int']>;
  skipPaymentGateway?: Maybe<Scalars['Boolean']>;
};

export type CancelBookingPayload = {
  __typename?: 'CancelBookingPayload';
  booking: Booking;
};

export type CancelPolicyRule = {
  __typename?: 'CancelPolicyRule';
  from: Scalars['Float'];
  to: Scalars['Float'];
  charge: Scalars['Float'];
};

export type CaptureBookingPayload = {
  __typename?: 'CaptureBookingPayload';
  booking: Booking;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  seoContent?: Maybe<SeoContent>;
  categoryType: CollectionType;
  collection: Scalars['String'];
  isActive: Scalars['Boolean'];
  hex_color_code?: Maybe<Scalars['String']>;
  picture?: Maybe<Media>;
  countPosts: Scalars['Float'];
  stats: CategoryStatsPayload;
};


export type CategoryStatsArgs = {
  filters?: Maybe<CategoryStatsFilters>;
};

export type CategoryFilters = {
  PRODUCT_TYPE?: Maybe<ProductType>;
  CATEGORIES_IDS?: Maybe<Array<Scalars['Int']>>;
  ORDER_BY_MOST_POSTS_COUNT?: Maybe<Scalars['Boolean']>;
  INCLUDE_CATEGORIES_SLUG?: Maybe<Array<Scalars['String']>>;
};

export type CategoryStatsFilters = {
  SUPPLIER_ID?: Maybe<Scalars['Int']>;
};

export type CategoryStatsPayload = {
  __typename?: 'CategoryStatsPayload';
  tourCount: Scalars['Float'];
};

export type ChangeUserPasswordByTokenInput = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type ChangeUserPasswordByTokenPayload = {
  __typename?: 'ChangeUserPasswordByTokenPayload';
  token: Scalars['String'];
};

export enum ChildrenInfantPriceRule {
  AdultPrice = 'ADULT_PRICE',
  HalfAdultPrice = 'HALF_ADULT_PRICE',
  Free = 'FREE'
}

export type City = {
  __typename?: 'City';
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  countryId: Scalars['String'];
  timezone: Scalars['String'];
  relatedCities?: Maybe<Array<City>>;
  country: Country;
  picture?: Maybe<Media>;
  url: Scalars['String'];
  seoContent: SeoContentPayload;
  faqs?: Maybe<Array<FaqContent>>;
  stats: LocationStats;
  tours: Array<Tour>;
  suppliers: Array<Supplier>;
  hasOnlineTours: Scalars['Boolean'];
  hasPrivateTours: Scalars['Boolean'];
  hasSuppliers: Scalars['Boolean'];
  hasTransportationTours: Scalars['Boolean'];
};


export type CityStatsArgs = {
  filters?: Maybe<LocationStatsFilters>;
};


export type CityToursArgs = {
  limit?: Maybe<Scalars['Int']>;
  filters?: Maybe<TourFilters>;
};


export type CitySuppliersArgs = {
  limit?: Maybe<Scalars['Int']>;
  filters?: Maybe<SupplierFilters>;
};

export type CityConnection = {
  __typename?: 'CityConnection';
  pageInfo: PageInfo;
  edges: Array<CityEdge>;
  totalCount: Scalars['Int'];
};

export type CityEdge = {
  __typename?: 'CityEdge';
  node: City;
  cursor: Scalars['String'];
};

export type CityFilters = {
  ORDER_BY?: Maybe<CityOrderBy>;
  CITIES_IDS?: Maybe<Array<Scalars['Int']>>;
  COUNTRIES_IDS?: Maybe<Array<Scalars['String']>>;
  EXCLUDE_COUNTRIES_IDS?: Maybe<Array<Scalars['String']>>;
  COUNTRIES_SLUGS?: Maybe<Array<Scalars['String']>>;
  SEARCH?: Maybe<Scalars['String']>;
  MATCH?: Maybe<Scalars['String']>;
  EXCLUDE_IDS?: Maybe<Array<Scalars['Int']>>;
  HAS_TOURS_OR_GUIDES?: Maybe<Scalars['Boolean']>;
};

export enum CityOrderBy {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  BookingAsc = 'BOOKING_ASC',
  BookingDesc = 'BOOKING_DESC',
  RankingAsc = 'RANKING_ASC',
  RankingDesc = 'RANKING_DESC',
  SearchMatch = 'SEARCH_MATCH',
  SortingPoints = 'SORTING_POINTS'
}

export type CityTopPagePayload = {
  __typename?: 'CityTopPagePayload';
  city: City;
  tours: Array<Tour>;
  suppliers: Array<Supplier>;
  newTours: Array<Tour>;
  newSuppliers: Array<Supplier>;
  onlineTours: Array<Tour>;
  transportationTours: Array<Tour>;
  hasMoreSuppliers: Scalars['Boolean'];
  hasMoreTours: Scalars['Boolean'];
  hasMoreNewSuppliers: Scalars['Boolean'];
  hasMoreNewTours: Scalars['Boolean'];
  hasMoreTransportationTours: Scalars['Boolean'];
};

export enum CollectionType {
  Blog = 'BLOG',
  Collection = 'COLLECTION',
  Tour = 'TOUR',
  Car = 'CAR'
}

export type CompleteBlogCreationInput = {
  blogId: Scalars['Int'];
  coverPicId: Scalars['Int'];
  content: Scalars['String'];
  contentImage?: Maybe<Array<BlogContentImage>>;
};

export type CompleteBlogCreationPayload = {
  __typename?: 'CompleteBlogCreationPayload';
  post: Post;
};

export type CompleteBookingInput = {
  email: Scalars['String'];
  phone: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  accomodation: Scalars['String'];
  countryId: Scalars['String'];
  message: Scalars['String'];
};

export type CompleteBookingPayload = {
  __typename?: 'CompleteBookingPayload';
  booking?: Maybe<Booking>;
  messageId?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

export type ContactInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  topic: Scalars['String'];
  message: Scalars['String'];
};

export type ContactPayload = {
  __typename?: 'ContactPayload';
  isSent: Scalars['Boolean'];
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  currencyId: Scalars['String'];
  isActive: Scalars['Boolean'];
  picture?: Maybe<Media>;
  currency?: Maybe<Currency>;
  url: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  payoutMethod: Scalars['String'];
  dialCode?: Maybe<Scalars['String']>;
  seoContent: SeoContentPayload;
  faqs?: Maybe<Array<FaqContent>>;
  stats: LocationStats;
  hasOnlineTours: Scalars['Boolean'];
  hasPrivateTours: Scalars['Boolean'];
  hasSuppliers: Scalars['Boolean'];
  hasTransportationTours: Scalars['Boolean'];
};


export type CountryStatsArgs = {
  filters?: Maybe<LocationStatsFilters>;
};

export type CountryConnection = {
  __typename?: 'CountryConnection';
  pageInfo: PageInfo;
  edges: Array<CountryEdge>;
  totalCount: Scalars['Int'];
};

export type CountryEdge = {
  __typename?: 'CountryEdge';
  node: Country;
  cursor: Scalars['String'];
};

export type CountryFilters = {
  ORDER_BY?: Maybe<CountryOrderBy>;
  COUNTRIES_IDS?: Maybe<Array<Scalars['String']>>;
  SEARCH?: Maybe<Scalars['String']>;
  MATCH?: Maybe<Scalars['String']>;
  PRODUCT_TYPES?: Maybe<Array<ProductType>>;
  STATUS?: Maybe<Scalars['Boolean']>;
  WITH_BLOGS?: Maybe<Scalars['Boolean']>;
  SHOW_ALL_COUNTRIES?: Maybe<Scalars['Boolean']>;
  EXCLUDE_IDS?: Maybe<Array<Scalars['String']>>;
};

export enum CountryOrderBy {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  BookingAsc = 'BOOKING_ASC',
  BookingDesc = 'BOOKING_DESC',
  RankingAsc = 'RANKING_ASC',
  RankingDesc = 'RANKING_DESC',
  SearchMatch = 'SEARCH_MATCH',
  SortingPoints = 'SORTING_POINTS'
}

export type CountryTopPagePayload = {
  __typename?: 'CountryTopPagePayload';
  country: Country;
  tours: Array<Tour>;
  suppliers: Array<Supplier>;
  newTours: Array<Tour>;
  newSuppliers: Array<Supplier>;
  cities: Array<City>;
  onlineTours: Array<Tour>;
  transportationTours: Array<Tour>;
  hasMoreSuppliers: Scalars['Boolean'];
  hasMoreTours: Scalars['Boolean'];
  hasMoreNewSuppliers: Scalars['Boolean'];
  hasMoreNewTours: Scalars['Boolean'];
  hasMoreTransportationTours: Scalars['Boolean'];
  hasMoreCities: Scalars['Boolean'];
};

export type Coupon = {
  __typename?: 'Coupon';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type CreateBlogInput = {
  title: Scalars['String'];
  categoryId?: Maybe<Scalars['Int']>;
  languageId?: Maybe<Scalars['Int']>;
  countryId?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['Int']>;
  isPublished: Scalars['Boolean'];
};

export type CreateBlogPayload = {
  __typename?: 'CreateBlogPayload';
  post: Post;
};

export type CreateBookingQuotationInput = {
  bookingId: Scalars['Int'];
  couponCode?: Maybe<Scalars['String']>;
};

export type CreateBookingQuotationPayload = {
  __typename?: 'CreateBookingQuotationPayload';
  tour?: Maybe<Tour>;
  coupon?: Maybe<Coupon>;
  items: Array<BookingQuotationItem>;
  onlinePrice: Scalars['Float'];
  cashPrice?: Maybe<Scalars['Float']>;
  currency: Currency;
  payoutMethod: PayoutMethod;
};

export type CreateFileInput = {
  collection: FileCollection;
  tableName: Scalars['String'];
  tableId: Scalars['Int'];
  fileName: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

export type CreateFilePayload = {
  __typename?: 'CreateFilePayload';
  picture: Picture;
};

export type CreateFileSignedUrlInput = {
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  tableName: Scalars['String'];
  tableId: Scalars['Int'];
};

export type CreateFileSignedUrlPayload = {
  __typename?: 'CreateFileSignedUrlPayload';
  fileName: Scalars['String'];
  url: Scalars['String'];
};

export type CreateMessageInput = {
  toUserId: Scalars['Int'];
  tourId?: Maybe<Scalars['Int']>;
  meetingDate: Scalars['DateTime'];
  meetingTime?: Maybe<Scalars['String']>;
  adultsCount: Scalars['Int'];
  childrenCount: Scalars['Int'];
  infantCount: Scalars['Int'];
  cityId?: Maybe<Scalars['Int']>;
  accomodationAddress?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type CreateMessagePayload = {
  __typename?: 'CreateMessagePayload';
  message: Message;
  isSent: Scalars['Boolean'];
};

export type CreateMessageTemplateInput = {
  title: Scalars['String'];
  content: Scalars['String'];
  isActive: Scalars['Boolean'];
  parentId?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

export type CreateMessageTemplatePayload = {
  __typename?: 'CreateMessageTemplatePayload';
  messageTemplate: MessageTemplate;
};

export type CreatePayPalOrderInput = {
  bookingId: Scalars['Int'];
};

export type CreatePayPalOrderPayload = {
  __typename?: 'CreatePayPalOrderPayload';
  orderId: Scalars['String'];
};

export type CreatePaymentRequestInput = {
  userId: Scalars['Int'];
  title: Scalars['String'];
  content: Scalars['String'];
  countryId: Scalars['String'];
  cityId: Scalars['Int'];
  price: Scalars['Float'];
  meetingDate: Scalars['String'];
  message: Scalars['String'];
};

export type CreatePaymentRequestPayload = {
  __typename?: 'CreatePaymentRequestPayload';
  message: Message;
  booking: Booking;
};

export type CreateReviewInput = {
  bookingId: Scalars['Int'];
  title: Scalars['String'];
  message: Scalars['String'];
  rating: Scalars['Int'];
};

export type CreateReviewPayload = {
  __typename?: 'CreateReviewPayload';
  review: Review;
};

export type CreateTourBookingInput = {
  tourId: Scalars['Int'];
  meetingDate: Scalars['String'];
  meetingTime: Scalars['String'];
  adultsCount: Scalars['Int'];
  childrenCount?: Maybe<Scalars['Int']>;
  infantsCount?: Maybe<Scalars['Int']>;
  couponCode?: Maybe<Scalars['String']>;
};

export type CreateTourInput = {
  countryId: Scalars['String'];
  cityId: Scalars['Int'];
};

export type CreateTourOfferInput = {
  userId: Scalars['Int'];
  tourId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  content: Scalars['String'];
  countryId: Scalars['String'];
  cityId: Scalars['Int'];
  meetingDate: Scalars['String'];
  duration: Scalars['String'];
  adultsCount: Scalars['Int'];
  childrenCount: Scalars['Int'];
  infantsCount: Scalars['Int'];
  meetingTime: Scalars['String'];
  meetingPoint: Scalars['String'];
  meetingPointDescription?: Maybe<Scalars['String']>;
  endingPoint: Scalars['String'];
  endingTime: Scalars['String'];
  included: Scalars['String'];
  notIncluded: Scalars['String'];
  itinerary: Array<ItineraryInput>;
  price: Scalars['Float'];
  message: Scalars['String'];
  accomodation?: Maybe<Scalars['String']>;
};

export type CreateTourOfferPayload = {
  __typename?: 'CreateTourOfferPayload';
  message: Message;
  booking: Booking;
};

export type CreateTourPayload = {
  __typename?: 'CreateTourPayload';
  tour: Tour;
  success: Scalars['Boolean'];
};

export type CreateTourQuotationInput = {
  tourId: Scalars['Int'];
  meetingDate: Scalars['String'];
  meetingTime: Scalars['String'];
  adultsCount: Scalars['Int'];
  childrenCount?: Maybe<Scalars['Int']>;
  infantsCount?: Maybe<Scalars['Int']>;
  couponCode?: Maybe<Scalars['String']>;
};

export type CreateTourQuotationPayload = {
  __typename?: 'CreateTourQuotationPayload';
  tour: Tour;
  coupon?: Maybe<Coupon>;
  items: Array<TourQuotationItem>;
  onlinePrice: Scalars['Float'];
  cashPrice?: Maybe<Scalars['Float']>;
  currency: Currency;
  payoutMethod: PayoutMethod;
};

export type CreateUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  facebookId?: Maybe<Scalars['String']>;
  googleId?: Maybe<Scalars['String']>;
  googleEmail?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  token: Scalars['String'];
};

export type CreateVeritransPaymentInput = {
  bookingId: Scalars['Int'];
  token: Scalars['String'];
};

export type CreateVeritransPaymentPayload = {
  __typename?: 'CreateVeritransPaymentPayload';
  booking: Booking;
};

export type Currency = {
  __typename?: 'Currency';
  id: Scalars['String'];
  symbol: Scalars['String'];
  quotes: Array<Quote>;
  minPrice: Scalars['Float'];
};

export type CurrencyConnection = {
  __typename?: 'CurrencyConnection';
  pageInfo: PageInfo;
  edges: Array<CurrencyEdge>;
  totalCount: Scalars['Int'];
};

export type CurrencyEdge = {
  __typename?: 'CurrencyEdge';
  node: Currency;
  cursor: Scalars['String'];
};

export type CurrencyFilters = {
  CURRENCY_IDS?: Maybe<Array<Scalars['String']>>;
};

export type CustomerNotification = {
  __typename?: 'CustomerNotification';
  unReadMessagesCount: Scalars['Int'];
};

export type DatabaseInfoType = {
  __typename?: 'DatabaseInfoType';
  name: Scalars['String'];
  host: Scalars['String'];
};


export type DeleteMessageTemplatePayload = {
  __typename?: 'DeleteMessageTemplatePayload';
  messageTemplate: MessageTemplate;
  success: Scalars['Boolean'];
};

export type DeleteTourPayload = {
  __typename?: 'DeleteTourPayload';
  success: Scalars['Boolean'];
};

export type DuplicateTourPayload = {
  __typename?: 'DuplicateTourPayload';
  tour: Tour;
  success: Scalars['Boolean'];
};

export enum DurationEnum {
  LessThreeHours = 'LESS_THREE_HOURS',
  HalfDay = 'HALF_DAY',
  FullDay = 'FULL_DAY',
  MultipleDays = 'MULTIPLE_DAYS'
}

export enum DurationType {
  Less_3Hours = 'LESS_3_HOURS',
  HalfDay = 'HALF_DAY',
  FullDay = 'FULL_DAY',
  MultipleDays = 'MULTIPLE_DAYS'
}

export type EnviromentType = {
  __typename?: 'EnviromentType';
  database: DatabaseInfoType;
  api: ApiInfoType;
};

export type FaqCategoriesConnection = {
  __typename?: 'FaqCategoriesConnection';
  pageInfo: PageInfo;
  edges: Array<FaqCategoriesEdge>;
  totalCount: Scalars['Int'];
};

export type FaqCategoriesEdge = {
  __typename?: 'FaqCategoriesEdge';
  node: FaqCategory;
  cursor: Scalars['String'];
};

export enum FaqCategoriesOrderBy {
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC'
}

export type FaqCategory = {
  __typename?: 'FaqCategory';
  id: Scalars['Int'];
  parentId: Scalars['Int'];
  title: Scalars['String'];
  title_en: Scalars['String'];
  position: Scalars['Int'];
  status: Scalars['Int'];
  url: Scalars['String'];
  subCategories?: Maybe<Array<FaqCategory>>;
  parentCategory?: Maybe<FaqCategory>;
  faqsCount?: Maybe<Scalars['Int']>;
  faqs: Array<Faqs>;
};

export type FaqCategoryFilters = {
  SEARCH?: Maybe<Scalars['String']>;
  ORDER_BY?: Maybe<FaqCategoriesOrderBy>;
  ONLY_PARENT?: Maybe<Scalars['Boolean']>;
  ONLY_ACTIVE?: Maybe<Scalars['Boolean']>;
  EXCLUDE_IDS?: Maybe<Array<Scalars['Int']>>;
};

export type FaqContent = {
  __typename?: 'FaqContent';
  question: Scalars['String'];
  answer: Scalars['String'];
};

export type Faqs = {
  __typename?: 'Faqs';
  id: Scalars['Int'];
  categoryId: Scalars['Int'];
  category: FaqCategory;
  title: Scalars['String'];
  title_en: Scalars['String'];
  content: Scalars['String'];
  content_en: Scalars['String'];
  position: Scalars['Int'];
  product: Scalars['Int'];
  important: Scalars['Int'];
  mail: Scalars['String'];
  url: Scalars['String'];
  status: Scalars['Int'];
  countViews: Scalars['Int'];
  contentBackup: Scalars['String'];
  content_en_backup: Scalars['String'];
  relatedFaqs: Array<Faqs>;
};

export type FaqsConnection = {
  __typename?: 'FaqsConnection';
  pageInfo: PageInfo;
  edges: Array<FaqsEdge>;
  totalCount: Scalars['Int'];
};

export type FaqsEdge = {
  __typename?: 'FaqsEdge';
  node: Faqs;
  cursor: Scalars['String'];
};

export type FaqsFilters = {
  SEARCH?: Maybe<Scalars['String']>;
  ORDER_BY?: Maybe<FaqsOrderBy>;
  IMPORTANT?: Maybe<Scalars['Boolean']>;
  ONLY_ACTIVE?: Maybe<Scalars['Boolean']>;
  EXCLUDE_IDS?: Maybe<Array<Scalars['Int']>>;
};

export enum FaqsOrderBy {
  Popular = 'POPULAR',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC'
}

export enum FileCollection {
  Default = 'DEFAULT',
  Gallery = 'GALLERY',
  Application = 'APPLICATION'
}

export enum GroupByType {
  Tour = 'TOUR',
  Supplier = 'SUPPLIER',
  Country = 'COUNTRY',
  City = 'CITY',
  Category = 'CATEGORY'
}

export enum GuideBookingNotice {
  SameDay = 'SAME_DAY',
  OneDay = 'ONE_DAY',
  TwoDays = 'TWO_DAYS',
  ThreeDays = 'THREE_DAYS',
  SevenDays = 'SEVEN_DAYS',
  FourteenDays = 'FOURTEEN_DAYS'
}

export enum GuideBookingWindow {
  AllFutureDays = 'ALL_FUTURE_DAYS',
  TwelveMonths = 'TWELVE_MONTHS',
  NineMonths = 'NINE_MONTHS',
  SixMonths = 'SIX_MONTHS',
  ThreeMonths = 'THREE_MONTHS',
  OneMonth = 'ONE_MONTH'
}

export type HasSupportAccessResponse = {
  __typename?: 'HasSupportAccessResponse';
  success: Scalars['Boolean'];
};

export type InactiveTourPayload = {
  __typename?: 'InactiveTourPayload';
  tour: Tour;
  success: Scalars['Boolean'];
};

export type IncrementPostViewsPayload = {
  __typename?: 'IncrementPostViewsPayload';
  isUpdated: Scalars['Boolean'];
};

export type IncrementTourViewsPayload = {
  __typename?: 'IncrementTourViewsPayload';
  isUpdated: Scalars['Boolean'];
};

export type ItineraryInput = {
  time?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type Language = {
  __typename?: 'Language';
  id: Scalars['Int'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export type LanguageConnection = {
  __typename?: 'LanguageConnection';
  pageInfo: PageInfo;
  edges: Array<LanguageEdge>;
  totalCount: Scalars['Int'];
};

export type LanguageEdge = {
  __typename?: 'LanguageEdge';
  node: Language;
  cursor: Scalars['String'];
};

export type LanguageFilters = {
  WITH_TOURS?: Maybe<Scalars['Boolean']>;
  LANGUAGES_IDS?: Maybe<Array<Scalars['Int']>>;
  LANGUAGES_CODES?: Maybe<Array<Scalars['String']>>;
};

export enum LanguageLevel {
  Conversational = 'CONVERSATIONAL',
  Business = 'BUSINESS',
  Native = 'NATIVE'
}

export type Location = City | Country;

export type LocationFilters = {
  MATCH?: Maybe<Scalars['String']>;
  EXCLUDE_IDS?: Maybe<Array<Scalars['Int']>>;
};

export type LocationStats = {
  __typename?: 'LocationStats';
  reviewsCount: Scalars['Float'];
  reviewsRating: Scalars['Float'];
};

export type LocationStatsFilters = {
  PRODUCT_TYPES?: Maybe<Array<ProductType>>;
};

export type Media = {
  __typename?: 'Media';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  name: Scalars['String'];
  tableId: Scalars['String'];
  tableName: Scalars['String'];
  fileName: Scalars['String'];
  filePath: Scalars['String'];
  position: Scalars['Int'];
  fileType: Scalars['String'];
  fileExtension: Scalars['String'];
  disk: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  collection: Scalars['String'];
  url: Scalars['String'];
  serpImage: Scalars['String'];
  urlXS: Scalars['String'];
  urlLG: Scalars['String'];
  urlSM: Scalars['String'];
};


export type MediaUrlArgs = {
  quality?: Maybe<Scalars['Int']>;
  size?: Maybe<ThumbSize>;
};


export type MediaSerpImageArgs = {
  size?: Maybe<ThumbSize>;
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['Int'];
  flowCheck: Scalars['Int'];
  security: Scalars['Int'];
  thread: Scalars['String'];
  message: Scalars['String'];
  from: UserOrSupplier;
  to: UserOrSupplier;
  booking?: Maybe<Booking>;
  tour?: Maybe<Tour>;
  tourRequest?: Maybe<TourRequest>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hide?: Maybe<Scalars['Boolean']>;
  isRead: Scalars['Boolean'];
  isImportant: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  messageThread: MessageThread;
  status?: Maybe<BookingStatus>;
};

export type MessageBookingsPayload = {
  __typename?: 'MessageBookingsPayload';
  message: Message;
  bookings: Array<Booking>;
  hasMoreBookings: Scalars['Boolean'];
};

export type MessageConnection = {
  __typename?: 'MessageConnection';
  pageInfo: PageInfo;
  edges: Array<MessageEdge>;
  totalCount: Scalars['Int'];
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  node: Message;
  cursor: Scalars['String'];
};

export type MessageFilters = {
  SEARCH?: Maybe<Scalars['String']>;
  INBOX?: Maybe<MessageInbox>;
  FROM?: Maybe<Scalars['String']>;
};

export enum MessageInbox {
  Default = 'DEFAULT',
  Unread = 'UNREAD',
  Unreply = 'UNREPLY',
  Important = 'IMPORTANT',
  Booked = 'BOOKED',
  Archived = 'ARCHIVED',
  All = 'ALL'
}

export type MessagePayload = {
  __typename?: 'MessagePayload';
  message: Message;
  messageThread: MessageThread;
};

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  id: Scalars['Int'];
  parentId: Scalars['Float'];
  userId: Scalars['Float'];
  content: Scalars['String'];
  title: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['Boolean'];
  user: Supplier;
};

export type MessageTemplatesFilter = {
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type MessageThread = {
  __typename?: 'MessageThread';
  id: Scalars['String'];
  latestMessage: Message;
  messages: Array<Message>;
  totalCount: Scalars['Int'];
  isImportant: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isBooked: Scalars['Boolean'];
  hasMoreMessage: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  incrementTourViews: IncrementTourViewsPayload;
  CreateTour: CreateTourPayload;
  DeleteTour: DeleteTourPayload;
  DuplicateTour: DuplicateTourPayload;
  InactiveTour: InactiveTourPayload;
  SubmitTour: SubmitTourPayload;
  CreateTourQuotation: CreateTourQuotationPayload;
  CreateTourBooking: Booking;
  CreateVeritransPayment: CreateVeritransPaymentPayload;
  AcceptBooking: CaptureBookingPayload;
  CancelBooking: CancelBookingPayload;
  CreatePayPalOrder: CreatePayPalOrderPayload;
  AuthorizePayPalOrder: AuthorizePayPalOrderPayload;
  CompleteBooking: CompleteBookingPayload;
  UpdateBooking: UpdateBookingPayload;
  CalculateBookingCancellationPolicyRule: CalculateBookingCancellationPolicyRulePayLoad;
  CreateBookingQuotation: CreateBookingQuotationPayload;
  AddCouponToBooking: Booking;
  DeletePendingBooking: Scalars['Boolean'];
  BookingPaymentError: Scalars['Boolean'];
  CreateFile: CreateFilePayload;
  CreateFileSignedUrl: CreateFileSignedUrlPayload;
  UpdateTour: UpdateTourPayload;
  Authenticate: AuthenticatePayload;
  AuthenticateWithFacebook: AuthenticateWithFacebookPayload;
  AuthenticateWithGoogle: AuthenticateWithGooglePayload;
  CreateUser: CreateUserPayload;
  RecoverUserPassword: RecoverUserPasswordPayload;
  ChangeUserPasswordByToken: ChangeUserPasswordByTokenPayload;
  Contact: ContactPayload;
  UpdateSupplierBank: UpdateSupplierBankPayload;
  RemoveSupplierPayPal: RemoveSupplierPayPalPayload;
  UpdateSupplierPaypal: UpdateSupplierPaypalPayload;
  SupplierApply: SupplierApplyPayload;
  UpdateSupplierAccount: UpdateSupplierAccountPayload;
  SupplierRequestChange: Scalars['Boolean'];
  UpdateSupplierGallery: UpdateSupplierGalleryPayload;
  UpdateCustomerAccount: UpdateCustomerAccountPayload;
  CreateReview: CreateReviewPayload;
  ReplyReview: ReplyReviewPayload;
  updateReviewPhotoFlag: Review;
  incrementPostViews: IncrementPostViewsPayload;
  CreateBlog: CreateBlogPayload;
  CompleteBlogCreation: CompleteBlogCreationPayload;
  UpdateBlog: UpdateBlogPayload;
  DeleteBlog: Scalars['Boolean'];
  CreateMessage: CreateMessagePayload;
  UpdateMessageThread: UpdateMessageThreadPayload;
  ReportMessageById: ReportMessageByIdPayload;
  ReplyMessage: ReplyMessagePayload;
  CreateTourOffer: CreateTourOfferPayload;
  CreatePaymentRequest: CreatePaymentRequestPayload;
  UpdateMessage: UpdateMessagePayload;
  ShouldMessageSecured: Scalars['Boolean'];
  NewMessageWithBooking: NewMessageWithBookingPayload;
  IsMessageThreadExists: Scalars['Boolean'];
  UpdateMessageAsRead: UpdateMessageAsReadPayload;
  CreateMessageTemplate: CreateMessageTemplatePayload;
  UpdateMessageTemplate: UpdateMessageTemplatePayload;
  DeleteMessageTemplate: DeleteMessageTemplatePayload;
  OpenCalendarDate: Scalars['Boolean'];
  CloseCalendarDate: Scalars['Boolean'];
  OpenAiDataRequest: OpenAiDataRequestPayload;
  OpenAiRawRequest: OpenAiRawRequestPayload;
  OpenAiChatResultFeedback: Scalars['Boolean'];
  SupplierContactSupport: Scalars['Boolean'];
  IncrementFaqViewCount: Scalars['Boolean'];
};


export type MutationIncrementTourViewsArgs = {
  id: Scalars['Int'];
};


export type MutationCreateTourArgs = {
  input: CreateTourInput;
};


export type MutationDeleteTourArgs = {
  id: Scalars['Int'];
};


export type MutationDuplicateTourArgs = {
  id: Scalars['Int'];
};


export type MutationInactiveTourArgs = {
  id: Scalars['Int'];
};


export type MutationSubmitTourArgs = {
  id: Scalars['Int'];
};


export type MutationCreateTourQuotationArgs = {
  input: CreateTourQuotationInput;
};


export type MutationCreateTourBookingArgs = {
  input: CreateTourBookingInput;
};


export type MutationCreateVeritransPaymentArgs = {
  input: CreateVeritransPaymentInput;
};


export type MutationAcceptBookingArgs = {
  bookingId: Scalars['Int'];
};


export type MutationCancelBookingArgs = {
  input: CancelBookingInput;
};


export type MutationCreatePayPalOrderArgs = {
  input: CreatePayPalOrderInput;
};


export type MutationAuthorizePayPalOrderArgs = {
  input: AuthorizePayPalOrderInput;
};


export type MutationCompleteBookingArgs = {
  input: CompleteBookingInput;
  id: Scalars['Int'];
};


export type MutationUpdateBookingArgs = {
  input: UpdateBookingInput;
  id: Scalars['Int'];
};


export type MutationCalculateBookingCancellationPolicyRuleArgs = {
  id: Scalars['Int'];
};


export type MutationCreateBookingQuotationArgs = {
  input: CreateBookingQuotationInput;
};


export type MutationAddCouponToBookingArgs = {
  input: AddCouponToBookingInput;
};


export type MutationDeletePendingBookingArgs = {
  bookingId: Scalars['Int'];
};


export type MutationBookingPaymentErrorArgs = {
  input: BookingPaymentErrorInput;
};


export type MutationCreateFileArgs = {
  input: CreateFileInput;
};


export type MutationCreateFileSignedUrlArgs = {
  input: CreateFileSignedUrlInput;
};


export type MutationUpdateTourArgs = {
  input: UpdateTourInput;
  id: Scalars['Int'];
};


export type MutationAuthenticateArgs = {
  input: AuthenticateInput;
};


export type MutationAuthenticateWithFacebookArgs = {
  input: AuthenticateWithFacebookInput;
};


export type MutationAuthenticateWithGoogleArgs = {
  input: AuthenticateWithGoogleInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationRecoverUserPasswordArgs = {
  input: RecoverUserPasswordInput;
};


export type MutationChangeUserPasswordByTokenArgs = {
  input: ChangeUserPasswordByTokenInput;
};


export type MutationContactArgs = {
  input: ContactInput;
};


export type MutationUpdateSupplierBankArgs = {
  input: UpdateSupplierBankInput;
};


export type MutationUpdateSupplierPaypalArgs = {
  input: UpdateSupplierPaypalInput;
};


export type MutationSupplierApplyArgs = {
  input: SupplierApplyInput;
};


export type MutationUpdateSupplierAccountArgs = {
  input: UpdateSupplierAccountInput;
};


export type MutationSupplierRequestChangeArgs = {
  input: SupplierRequestChangeInput;
};


export type MutationUpdateSupplierGalleryArgs = {
  input: UpdateSupplierGalleryInput;
};


export type MutationUpdateCustomerAccountArgs = {
  input: UpdateCustomerAccountInput;
};


export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
};


export type MutationReplyReviewArgs = {
  input: ReplyReviewInput;
};


export type MutationUpdateReviewPhotoFlagArgs = {
  id: Scalars['Int'];
};


export type MutationIncrementPostViewsArgs = {
  id: Scalars['Int'];
};


export type MutationCreateBlogArgs = {
  input: CreateBlogInput;
};


export type MutationCompleteBlogCreationArgs = {
  input: CompleteBlogCreationInput;
};


export type MutationUpdateBlogArgs = {
  id: Scalars['Int'];
  input: UpdateBlogInput;
};


export type MutationDeleteBlogArgs = {
  id: Scalars['Int'];
};


export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};


export type MutationUpdateMessageThreadArgs = {
  input: UpdateMessageThreadInput;
  id: Scalars['Int'];
};


export type MutationReportMessageByIdArgs = {
  input: ReportMessageByIdInput;
};


export type MutationReplyMessageArgs = {
  input: ReplyMessageInput;
};


export type MutationCreateTourOfferArgs = {
  input: CreateTourOfferInput;
};


export type MutationCreatePaymentRequestArgs = {
  input: CreatePaymentRequestInput;
};


export type MutationUpdateMessageArgs = {
  input: UpdateMessageInput;
  id: Scalars['Int'];
};


export type MutationShouldMessageSecuredArgs = {
  content: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationNewMessageWithBookingArgs = {
  input: NewMessageWithBookingInput;
};


export type MutationIsMessageThreadExistsArgs = {
  userId2: Scalars['Int'];
  userId1: Scalars['Int'];
};


export type MutationUpdateMessageAsReadArgs = {
  isRead: Scalars['Boolean'];
  id: Scalars['Int'];
};


export type MutationCreateMessageTemplateArgs = {
  input: CreateMessageTemplateInput;
};


export type MutationUpdateMessageTemplateArgs = {
  id: Scalars['Int'];
  input: UpdateMessageTemplateInput;
};


export type MutationDeleteMessageTemplateArgs = {
  id: Scalars['Int'];
};


export type MutationOpenCalendarDateArgs = {
  forceToOpen?: Maybe<Scalars['Boolean']>;
  date: Scalars['String'];
};


export type MutationCloseCalendarDateArgs = {
  date: Scalars['String'];
};


export type MutationOpenAiDataRequestArgs = {
  input: OpenAiDataRequestInput;
};


export type MutationOpenAiRawRequestArgs = {
  input: OpenAiRawRequestInput;
};


export type MutationOpenAiChatResultFeedbackArgs = {
  input: OpenAiChatResultFeedbackInput;
};


export type MutationSupplierContactSupportArgs = {
  input: SupplierContactSupportInput;
};


export type MutationIncrementFaqViewCountArgs = {
  id: Scalars['Int'];
};

export type MyPostFilters = {
  SEARCH?: Maybe<Scalars['String']>;
};

export type NewMessageWithBookingInput = {
  bookingId: Scalars['Int'];
  message: Scalars['String'];
};

export type NewMessageWithBookingPayload = {
  __typename?: 'NewMessageWithBookingPayload';
  message: Message;
  isSent: Scalars['Boolean'];
};

export enum OpenAiChatFeedbackType {
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE'
}

export type OpenAiChatResultFeedbackInput = {
  chatRequestId: Scalars['Int'];
  message: Scalars['String'];
  type: OpenAiChatFeedbackType;
};

export type OpenAiCompletionResponse = {
  __typename?: 'OpenAiCompletionResponse';
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  duration?: Maybe<DurationType>;
  numberOfPeople?: Maybe<Scalars['Float']>;
  adults?: Maybe<Scalars['Float']>;
  children?: Maybe<Scalars['Float']>;
  infants?: Maybe<Scalars['Float']>;
  numberOfDays?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  type: Array<QueryType>;
  keywords?: Maybe<Array<Scalars['String']>>;
};

export type OpenAiDataPayload = {
  __typename?: 'OpenAiDataPayload';
  tours?: Maybe<OpenAiDataResultsPerType>;
  guides?: Maybe<OpenAiDataResultsPerType>;
  private_cars?: Maybe<OpenAiDataResultsPerType>;
  virtual_tours?: Maybe<OpenAiDataResultsPerType>;
};

export type OpenAiDataRequestInput = {
  userId?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  numberOfPeople?: Maybe<Scalars['Float']>;
  adults?: Maybe<Scalars['Float']>;
  children?: Maybe<Scalars['Float']>;
  infants?: Maybe<Scalars['Float']>;
  numberOfDays?: Maybe<Scalars['Float']>;
  budget?: Maybe<Scalars['Float']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  type: Array<QueryType>;
  duration?: Maybe<DurationType>;
  language?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  requestFrom: RequestFrom;
  rawMessage: Scalars['String'];
};

export type OpenAiDataRequestPayload = {
  __typename?: 'OpenAiDataRequestPayload';
  resultPayload: OpenAiDataPayload;
  id?: Maybe<Scalars['Int']>;
};

export type OpenAiDataResult = {
  __typename?: 'OpenAiDataResult';
  id: Scalars['Int'];
  title: Scalars['String'];
  abstract: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Scalars['String']>>;
};

export type OpenAiDataResultsPerType = {
  __typename?: 'OpenAiDataResultsPerType';
  results: Array<OpenAiDataResult>;
  viewMore: Scalars['String'];
  comments?: Maybe<Array<Scalars['String']>>;
};

export type OpenAiRawRequestInput = {
  message: Scalars['String'];
};

export type OpenAiRawRequestPayload = {
  __typename?: 'OpenAiRawRequestPayload';
  resultPayload?: Maybe<OpenAiCompletionResponse>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
  endCursor: Scalars['String'];
  page: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type Payment = {
  __typename?: 'Payment';
  period: Scalars['String'];
  currencyId: Scalars['String'];
  supplierFee: Scalars['Float'];
  gwgBilledAmount: Scalars['Float'];
  bookingsCount: Scalars['Float'];
  status: PaymentStatus;
};

export type PaymentFilters = {
  GROUP_BY?: Maybe<PaymentGroupBy>;
  SUPPLIERS_IDS?: Maybe<Array<Scalars['Int']>>;
  PERIOD_MONTH?: Maybe<Scalars['String']>;
  PERIOD_YEAR?: Maybe<Scalars['String']>;
  PAGE_NO?: Maybe<Scalars['Int']>;
  SIZE_PER_PAGE?: Maybe<Scalars['Int']>;
};

export enum PaymentGroupBy {
  Year = 'YEAR',
  Month = 'MONTH'
}

export enum PaymentStatus {
  Pending = 'PENDING',
  Paid = 'PAID',
  Failed = 'FAILED'
}

export type PaymentsPayload = {
  __typename?: 'PaymentsPayload';
  payments: Array<Payment>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
};

export enum PayoutMethod {
  Bank = 'BANK',
  Cash = 'CASH',
  Paypal = 'PAYPAL'
}

export enum PayoutStatus {
  Scheduled = 'SCHEDULED',
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Retry = 'RETRY',
  Onhold = 'ONHOLD'
}

export type Picture = {
  __typename?: 'Picture';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  name: Scalars['String'];
  tableId: Scalars['String'];
  tableName: Scalars['String'];
  fileName: Scalars['String'];
  filePath: Scalars['String'];
  position: Scalars['Int'];
  fileType: Scalars['String'];
  fileExtension: Scalars['String'];
  disk: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  collection: Scalars['String'];
  url: Scalars['String'];
  serpImage: Scalars['String'];
  urlXS: Scalars['String'];
  urlLG: Scalars['String'];
  urlSM: Scalars['String'];
};


export type PictureUrlArgs = {
  quality?: Maybe<Scalars['Int']>;
  size?: Maybe<ThumbSize>;
};


export type PictureSerpImageArgs = {
  size?: Maybe<ThumbSize>;
};

export type Post = {
  __typename?: 'Post';
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  isActive: Scalars['Boolean'];
  countryId?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  languageId?: Maybe<Scalars['Int']>;
  userId: Scalars['Float'];
  language?: Maybe<Language>;
  category?: Maybe<Category>;
  user: UserOrSupplier;
  country?: Maybe<Country>;
  city?: Maybe<City>;
  url: Scalars['String'];
  countViews: Scalars['Int'];
  picture?: Maybe<Media>;
  content?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  contentUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export type PostCategoryConnection = {
  __typename?: 'PostCategoryConnection';
  pageInfo: PageInfo;
  edges: Array<PostCategoryEdge>;
  totalCount: Scalars['Int'];
};

export type PostCategoryEdge = {
  __typename?: 'PostCategoryEdge';
  node: Category;
  cursor: Scalars['String'];
};

export type PostConnection = {
  __typename?: 'PostConnection';
  pageInfo: PageInfo;
  edges: Array<PostEdge>;
  totalCount: Scalars['Int'];
};

export type PostEdge = {
  __typename?: 'PostEdge';
  node: Post;
  cursor: Scalars['String'];
};

export type PostFilters = {
  USERS_IDS?: Maybe<Array<Scalars['Int']>>;
  CATEGORIES_SLUGS?: Maybe<Array<Scalars['String']>>;
  SEARCH?: Maybe<Scalars['String']>;
  MATCH?: Maybe<Scalars['String']>;
  AUTHOR?: Maybe<Array<AuthorType>>;
  ORDER_BY?: Maybe<PostOrderBy>;
  CITY_ID?: Maybe<Scalars['Int']>;
  COUNTRY_ID?: Maybe<Scalars['String']>;
  IGNORE_POSTS?: Maybe<Array<Scalars['Int']>>;
  TAG_SLUG?: Maybe<Scalars['String']>;
  COUNTRY_SLUG?: Maybe<Scalars['String']>;
};

export enum PostOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  RankingAsc = 'RANKING_ASC',
  RankingDesc = 'RANKING_DESC',
  SearchMatch = 'SEARCH_MATCH',
  Latest = 'LATEST',
  Best = 'BEST'
}

export enum PriceModel {
  Person = 'PERSON',
  Group = 'GROUP'
}

export type PriceRules = {
  __typename?: 'PriceRules';
  people?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
};

export enum ProductType {
  Online = 'ONLINE',
  Tour = 'TOUR',
  Car = 'CAR'
}

export type Query = {
  __typename?: 'Query';
  tours: TourConnection;
  getToursFiltersAvailable: TourFiltersResponse;
  tourById?: Maybe<Tour>;
  tourPrice?: Maybe<TourPrice>;
  tourScarcity?: Maybe<TourScarCityPayload>;
  tourCalendar?: Maybe<TourCalendarPayload>;
  RelatedToursByPost: RelatedToursByPostPayload;
  bookingById?: Maybe<Booking>;
  cities: CityConnection;
  countries: CountryConnection;
  recommendedDataByBookingId: RecommendedDataByBookingIdPayload;
  getAvifImageUrl?: Maybe<Scalars['String']>;
  getHighQualityImageFromUrl: Scalars['String'];
  hello: Scalars['String'];
  me: UserOrSupplier;
  meForSupplierApply: Supplier;
  userById?: Maybe<User>;
  users: UserConnection;
  supplierScarcity?: Maybe<SupplierScarCityPayload>;
  getSuppliersFiltersAvailable: SupplierFiltersResponse;
  blogWriterById?: Maybe<Supplier>;
  reviews: ReviewConnection;
  suppliers: SupplierConnection;
  supplierById?: Maybe<Supplier>;
  posts: PostConnection;
  locations: Array<Location>;
  countryBySlug?: Maybe<Country>;
  countryById?: Maybe<Country>;
  cityBySlug?: Maybe<City>;
  cityById?: Maybe<City>;
  currencies: CurrencyConnection;
  postById?: Maybe<Post>;
  postBySlug?: Maybe<Post>;
  RelatedPosts: RelatedPostsPayload;
  postCategories: PostCategoryConnection;
  tourCategories: TourCategoryConnection;
  categoryById?: Maybe<Category>;
  categoryBySlug?: Maybe<Category>;
  blogCategoryBySlug?: Maybe<Category>;
  tourCategoriesForTourCreation: Array<Category>;
  stats: StatsPayload;
  languages: LanguageConnection;
  GetMessageById: MessagePayload;
  GetBookingsByMessageId: MessageBookingsPayload;
  tagBySlug?: Maybe<Tags>;
  faqs: FaqsConnection;
  faqCategories: FaqCategoriesConnection;
  faqCategoryById?: Maybe<FaqCategory>;
  faqById: Faqs;
  hasSupportAccess: HasSupportAccessResponse;
  activeAnnouncement?: Maybe<Announcement>;
  getEnviromentInfo: EnviromentType;
  searchPageTabsData: Search;
  sitemap: Sitemap;
  countryTopPage: CountryTopPagePayload;
  cityTopPage: CityTopPagePayload;
  topPage: TopPagePayload;
  GetDataToAboutUs: AboutUsReport;
};


export type QueryToursArgs = {
  filters?: Maybe<TourFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type QueryGetToursFiltersAvailableArgs = {
  filters?: Maybe<TourFilters>;
};


export type QueryTourByIdArgs = {
  id: Scalars['Int'];
};


export type QueryTourPriceArgs = {
  input: TourPriceInput;
  id: Scalars['Int'];
};


export type QueryTourScarcityArgs = {
  input: TourScarCityInput;
  id: Scalars['Int'];
};


export type QueryTourCalendarArgs = {
  id: Scalars['Int'];
};


export type QueryRelatedToursByPostArgs = {
  postId: Scalars['Int'];
};


export type QueryBookingByIdArgs = {
  id: Scalars['Int'];
};


export type QueryCitiesArgs = {
  filters?: Maybe<CityFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type QueryCountriesArgs = {
  filters?: Maybe<CountryFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type QueryRecommendedDataByBookingIdArgs = {
  bookingId: Scalars['Int'];
};


export type QueryGetAvifImageUrlArgs = {
  url: Scalars['String'];
};


export type QueryGetHighQualityImageFromUrlArgs = {
  url: Scalars['String'];
};


export type QueryUserByIdArgs = {
  id: Scalars['Int'];
};


export type QueryUsersArgs = {
  filters?: Maybe<UserFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type QuerySupplierScarcityArgs = {
  date?: Maybe<Scalars['String']>;
  supplierId: Scalars['Int'];
};


export type QueryGetSuppliersFiltersAvailableArgs = {
  filters?: Maybe<SupplierFilters>;
};


export type QueryBlogWriterByIdArgs = {
  id: Scalars['Int'];
};


export type QueryReviewsArgs = {
  filters?: Maybe<ReviewFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type QuerySuppliersArgs = {
  filters?: Maybe<SupplierFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type QuerySupplierByIdArgs = {
  id: Scalars['Int'];
};


export type QueryPostsArgs = {
  filters?: Maybe<PostFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type QueryLocationsArgs = {
  filters?: Maybe<LocationFilters>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCountryBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryCountryByIdArgs = {
  id: Scalars['String'];
};


export type QueryCityBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryCityByIdArgs = {
  id: Scalars['Int'];
};


export type QueryCurrenciesArgs = {
  filters?: Maybe<CurrencyFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type QueryPostByIdArgs = {
  id: Scalars['Int'];
};


export type QueryPostBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryRelatedPostsArgs = {
  postId: Scalars['Int'];
};


export type QueryPostCategoriesArgs = {
  filters?: Maybe<CategoryFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type QueryTourCategoriesArgs = {
  filters?: Maybe<CategoryFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type QueryCategoryByIdArgs = {
  id: Scalars['Int'];
};


export type QueryCategoryBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryBlogCategoryBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryTourCategoriesForTourCreationArgs = {
  filters?: Maybe<CategoryFilters>;
};


export type QueryStatsArgs = {
  filters?: Maybe<StatsFilters>;
};


export type QueryLanguagesArgs = {
  filters?: Maybe<LanguageFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type QueryGetMessageByIdArgs = {
  pageNo: Scalars['Int'];
  id: Scalars['Int'];
};


export type QueryGetBookingsByMessageIdArgs = {
  pageNo: Scalars['Int'];
  id: Scalars['Int'];
};


export type QueryTagBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryFaqsArgs = {
  filters?: Maybe<FaqsFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type QueryFaqCategoriesArgs = {
  filters?: Maybe<FaqCategoryFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type QueryFaqCategoryByIdArgs = {
  id: Scalars['Int'];
};


export type QueryFaqByIdArgs = {
  id: Scalars['Int'];
};


export type QuerySearchPageTabsDataArgs = {
  filters: SearchFilters;
};


export type QueryCountryTopPageArgs = {
  slug: Scalars['String'];
};


export type QueryCityTopPageArgs = {
  slug: Scalars['String'];
};

export enum QueryType {
  Tours = 'TOURS',
  Guides = 'GUIDES',
  PrivateCars = 'PRIVATE_CARS',
  VirtualTours = 'VIRTUAL_TOURS'
}

export type Quote = {
  __typename?: 'Quote';
  currency: Scalars['String'];
  rate: Scalars['Float'];
};

export type RecommendedDataByBookingIdPayload = {
  __typename?: 'RecommendedDataByBookingIdPayload';
  tours: Array<Tour>;
  cities: Array<City>;
  hasMoreCities: Scalars['Boolean'];
  countries: Array<Country>;
  country: Country;
};

export type RecoverUserPasswordInput = {
  email: Scalars['String'];
};

export type RecoverUserPasswordPayload = {
  __typename?: 'RecoverUserPasswordPayload';
  isSent: Scalars['Boolean'];
};

export type RelatedPostsPayload = {
  __typename?: 'RelatedPostsPayload';
  post: Array<Post>;
};

export type RelatedToursByPostPayload = {
  __typename?: 'RelatedToursByPostPayload';
  tours: Array<Tour>;
};

export type RemoveSupplierPayPalPayload = {
  __typename?: 'RemoveSupplierPayPalPayload';
  supplier: Supplier;
};

export type ReplyMessageInput = {
  toUserId: Scalars['Int'];
  tourId?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type ReplyMessagePayload = {
  __typename?: 'ReplyMessagePayload';
  message: Message;
  isSent: Scalars['Boolean'];
};

export type ReplyReviewInput = {
  bookingId: Scalars['Int'];
  message: Scalars['String'];
};

export type ReplyReviewPayload = {
  __typename?: 'ReplyReviewPayload';
  review: Review;
};

export type ReportMessageByIdInput = {
  id: Scalars['Int'];
  reason: Scalars['String'];
};

export type ReportMessageByIdPayload = {
  __typename?: 'ReportMessageByIdPayload';
  message: Message;
  success: Scalars['Boolean'];
};

export enum RequestFrom {
  Plugin = 'PLUGIN',
  Chat = 'CHAT'
}

export type Review = {
  __typename?: 'Review';
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
  message: Scalars['String'];
  response?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  user: User;
  supplier: Supplier;
  booking?: Maybe<Booking>;
  photos: Array<Media>;
};

export type ReviewConnection = {
  __typename?: 'ReviewConnection';
  pageInfo: PageInfo;
  edges: Array<ReviewEdge>;
  totalCount: Scalars['Int'];
};

export type ReviewEdge = {
  __typename?: 'ReviewEdge';
  node: Review;
  cursor: Scalars['String'];
};

export type ReviewFilters = {
  SUPPLIERS_IDS?: Maybe<Array<Scalars['Int']>>;
  SEARCH?: Maybe<Scalars['String']>;
  COUNTRY_SLUG?: Maybe<Scalars['String']>;
  CITY_SLUG?: Maybe<Scalars['String']>;
  PRODUCT_TYPES?: Maybe<Array<Scalars['String']>>;
};

export type Search = {
  __typename?: 'Search';
  hasSupplier: Scalars['Boolean'];
  hasTour: Scalars['Boolean'];
  hasVirtualTour: Scalars['Boolean'];
  hasCar: Scalars['Boolean'];
  country?: Maybe<Country>;
  city?: Maybe<City>;
};

export type SearchFilters = {
  SEARCH?: Maybe<Scalars['String']>;
  COUNTRY_SLUG?: Maybe<Scalars['String']>;
  CITY_SLUG?: Maybe<Scalars['String']>;
};

export type SeoContent = {
  __typename?: 'SeoContent';
  title: Scalars['String'];
  content: Scalars['String'];
};

export type SeoContentPayload = {
  __typename?: 'SeoContentPayload';
  top: SeoContent;
  tours: SeoContent;
  guides: SeoContent;
  online: SeoContent;
  transportation: SeoContent;
};

export type Sitemap = {
  __typename?: 'Sitemap';
  tours: Array<SitemapItem>;
  suppliers: Array<SitemapItem>;
  blogs: Array<SitemapItem>;
  destinations: Array<SitemapItem>;
  authors: Array<SitemapItem>;
};

export type SitemapItem = {
  __typename?: 'SitemapItem';
  url: Scalars['String'];
  lastMod?: Maybe<Scalars['String']>;
};

export type StatsFilters = {
  COUNTRIES_SLUGS?: Maybe<Array<Scalars['String']>>;
  COUNTRIES_IDS?: Maybe<Array<Scalars['String']>>;
  CITIES_SLUGS?: Maybe<Array<Scalars['String']>>;
  CITIES_IDS?: Maybe<Array<Scalars['Int']>>;
  PRODUCT_TYPES?: Maybe<Array<ProductType>>;
};

export type StatsPayload = {
  __typename?: 'StatsPayload';
  reviewsCount: Scalars['Float'];
  reviewsRate: Scalars['Float'];
};

export type SubmitTourPayload = {
  __typename?: 'SubmitTourPayload';
  tour: Tour;
  success: Scalars['Boolean'];
};

export type Supplier = BaseUser & {
  __typename?: 'Supplier';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  isSupplier: Scalars['Boolean'];
  isActivity: Scalars['Boolean'];
  isBlogger: Scalars['Boolean'];
  guideApply: Scalars['Boolean'];
  guideApplyStep?: Maybe<Scalars['String']>;
  isSupplierApply?: Maybe<Scalars['Boolean']>;
  isGuide?: Maybe<Scalars['Boolean']>;
  isAdmin: Scalars['Boolean'];
  isBan: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  displayName: Scalars['String'];
  picture?: Maybe<Media>;
  pictureId?: Maybe<Scalars['Int']>;
  gallery?: Maybe<Array<Media>>;
  country?: Maybe<Country>;
  phone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  nationality?: Maybe<Country>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  isSocialLoginEnabled: Scalars['Boolean'];
  biography?: Maybe<Scalars['String']>;
  isBiographyUpdated: Scalars['Boolean'];
  bookings: BookingsConnection;
  bookingById: Booking;
  messageById: Message;
  messages: MessageConnection;
  blogs: BlogsConnection;
  url: Scalars['String'];
  isOnline: Scalars['Boolean'];
  commission: Scalars['Float'];
  guideMaxPeople: Scalars['Int'];
  guideMaxPeopleExtra: Scalars['Int'];
  maxAllowedPeople: Scalars['Int'];
  city?: Maybe<City>;
  cities: Array<City>;
  workingCountries?: Maybe<Array<Country>>;
  languages: Array<SupplierLanguage>;
  payoutMethod?: Maybe<Scalars['String']>;
  payoutMethodType: PayoutMethod;
  responseTimeMessage?: Maybe<Scalars['String']>;
  responseTime: Scalars['String'];
  calendarUpdatedAt?: Maybe<Scalars['DateTime']>;
  video?: Maybe<Scalars['String']>;
  important?: Maybe<Scalars['String']>;
  guideBookingNotice?: Maybe<GuideBookingNotice>;
  guideBookingWindow?: Maybe<GuideBookingWindow>;
  workingDays?: Maybe<SupplierWorkingDays>;
  countMessages?: Maybe<Scalars['Int']>;
  countReviews?: Maybe<Scalars['Int']>;
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  bank?: Maybe<SupplierBank>;
  paypal?: Maybe<SupplierPaypal>;
  payoutMethodStatus: SupplierPayoutMethodStatus;
  guidePhotoVerification?: Maybe<Scalars['Int']>;
  guidePhotoVerificationUrl?: Maybe<Media>;
  guidePhotoWorkingPermit?: Maybe<Scalars['Int']>;
  guidePhotoWorkingPermitUrl?: Maybe<Media>;
  youNeedGuideLicense?: Maybe<Scalars['Int']>;
  haveGuideLicense?: Maybe<Scalars['Int']>;
  guidePhotoLicense?: Maybe<Scalars['Int']>;
  guidePhotoLicenseUrl?: Maybe<Media>;
  notes?: Maybe<Scalars['String']>;
  guideProfileSteps?: Maybe<Scalars['Int']>;
  videoId?: Maybe<Scalars['String']>;
  isPersonalInfoUpdated: Scalars['Boolean'];
  isPasswordUpdated: Scalars['Boolean'];
  isProfileVideoUpdated: Scalars['Boolean'];
  isWorkingAreasUpdated: Scalars['Boolean'];
  isLanguagesUpdated: Scalars['Boolean'];
  isGlobalPreferenceUpdated: Scalars['Boolean'];
  isBookingConditionsUpdated: Scalars['Boolean'];
  isPaymentsUpdated: Scalars['Boolean'];
  isCar?: Maybe<Scalars['Boolean']>;
  supplierStatus?: Maybe<SupplierStatus>;
  analytics: SupplierAnalytics;
  reviews: ReviewConnection;
  stats: SupplierStats;
  bookingsAsSupplier: BookingsConnection;
  payments?: Maybe<PaymentsPayload>;
  messageTemplates: Array<MessageTemplate>;
  messageTemplateById: MessageTemplate;
  supplierTours: ToursConnection;
  allTours: Array<Tour>;
  supplierBookingDates: Array<Scalars['String']>;
  tourById: Tour;
  tours: Array<Tour>;
  supplierTourBadges: Array<Category>;
  supplierNotification: SupplierNotification;
  calendar: Calendar;
};


export type SupplierBookingsArgs = {
  filters?: Maybe<BookingFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type SupplierBookingByIdArgs = {
  id: Scalars['Int'];
};


export type SupplierMessageByIdArgs = {
  id: Scalars['Int'];
};


export type SupplierMessagesArgs = {
  filters?: Maybe<MessageFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type SupplierBlogsArgs = {
  filters?: Maybe<MyPostFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type SupplierLanguagesArgs = {
  filtersLanguage?: Maybe<SupplierLanguageFilters>;
};


export type SupplierReviewsArgs = {
  filters?: Maybe<ReviewFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type SupplierBookingsAsSupplierArgs = {
  filters?: Maybe<BookingFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type SupplierPaymentsArgs = {
  filters?: Maybe<PaymentFilters>;
};


export type SupplierMessageTemplatesArgs = {
  filters?: Maybe<MessageTemplatesFilter>;
};


export type SupplierMessageTemplateByIdArgs = {
  id: Scalars['Int'];
};


export type SupplierSupplierToursArgs = {
  filters?: Maybe<SupplierTourFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type SupplierTourByIdArgs = {
  id: Scalars['Int'];
};


export type SupplierSupplierTourBadgesArgs = {
  filters?: Maybe<SupplierTourBadgesFilters>;
};

export type SupplierAnalytics = {
  __typename?: 'SupplierAnalytics';
  conversionRate: Scalars['Float'];
  calendarUpdate?: Maybe<Scalars['Float']>;
  responseTime?: Maybe<Scalars['Float']>;
  profileVideo: Scalars['Boolean'];
};

export type SupplierApplyInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  nationalityId?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<SupplierLanguageInput>>;
  cityIds?: Maybe<Array<Scalars['Int']>>;
  bookingConditions?: Maybe<Scalars['String']>;
  availability?: Maybe<SupplierAvailabilityInput>;
  guidePhotoVerification?: Maybe<Scalars['Int']>;
  guidePhotoWorkingPermit?: Maybe<Scalars['Int']>;
  guidePhotoLicense?: Maybe<Scalars['Int']>;
  pictureId?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  submitSupplierApplication?: Maybe<Scalars['Boolean']>;
  guideProfileSteps?: Maybe<Scalars['Int']>;
  youNeedGuideLicense?: Maybe<Scalars['Boolean']>;
  youHaveLicense?: Maybe<Scalars['Boolean']>;
};

export type SupplierApplyPayload = {
  __typename?: 'SupplierApplyPayload';
  supplier: Supplier;
};

export type SupplierAvailabilityInput = {
  Mon?: Maybe<Scalars['String']>;
  Tue?: Maybe<Scalars['String']>;
  Wed?: Maybe<Scalars['String']>;
  Thu?: Maybe<Scalars['String']>;
  Fri?: Maybe<Scalars['String']>;
  Sat?: Maybe<Scalars['String']>;
  Sun?: Maybe<Scalars['String']>;
};

export type SupplierBank = {
  __typename?: 'SupplierBank';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  bankName: Scalars['String'];
  bankCode: Scalars['String'];
  branchName: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountName: Scalars['String'];
  accountType: AccountType;
};

export type SupplierConnection = {
  __typename?: 'SupplierConnection';
  pageInfo: PageInfo;
  edges: Array<SupplierEdge>;
  totalCount: Scalars['Int'];
};

export type SupplierContactSupportInput = {
  subject: Scalars['String'];
  message: Scalars['String'];
  images?: Maybe<Array<Scalars['String']>>;
};

export type SupplierEdge = {
  __typename?: 'SupplierEdge';
  node: Supplier;
  cursor: Scalars['String'];
};

export type SupplierFilters = {
  USERS_IDS?: Maybe<Array<Scalars['Int']>>;
  COUNTRIES_SLUGS?: Maybe<Array<Scalars['String']>>;
  COUNTRIES_IDS?: Maybe<Array<Scalars['String']>>;
  CITIES_SLUGS?: Maybe<Array<Scalars['String']>>;
  CITIES_IDS?: Maybe<Array<Scalars['Int']>>;
  LANGUAGES_IDS?: Maybe<Array<Scalars['Int']>>;
  LANGUAGES_CODES?: Maybe<Array<Scalars['String']>>;
  SEARCH?: Maybe<Scalars['String']>;
  CALENDAR?: Maybe<Array<Scalars['String']>>;
  ORDER_BY?: Maybe<SupplierOrderBy>;
  PEOPLE?: Maybe<Scalars['Int']>;
  ADULTS?: Maybe<Scalars['Int']>;
  CHILDREN?: Maybe<Scalars['Int']>;
  INFANTS?: Maybe<Scalars['Int']>;
  EXCLUDE_IDS?: Maybe<Array<Scalars['Int']>>;
  TOP_SUPPLIER_PER_COUNTRY?: Maybe<Scalars['Boolean']>;
};

export type SupplierFiltersResponse = {
  __typename?: 'SupplierFiltersResponse';
  categories: Array<Category>;
  durations: Array<DurationEnum>;
  languages: Array<Language>;
};

export type SupplierLanguage = {
  __typename?: 'SupplierLanguage';
  id: Scalars['Int'];
  level: LanguageLevel;
  language: Language;
};

export type SupplierLanguageFilters = {
  LEVELS: Array<LanguageLevel>;
};

export type SupplierLanguageInput = {
  languageId?: Maybe<Scalars['Int']>;
  level?: Maybe<LanguageLevel>;
};

export type SupplierNotification = {
  __typename?: 'SupplierNotification';
  unReadMessagesCount: Scalars['Int'];
  paidBookingsCount: Scalars['Int'];
  reviewNotRepliedCount: Scalars['Int'];
};

export enum SupplierOrderBy {
  CreatedAtDesc = 'CREATED_AT_DESC',
  Recommended = 'RECOMMENDED',
  TopReviewed = 'TOP_REVIEWED'
}

export enum SupplierPayoutMethodStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED'
}

export type SupplierPaypal = {
  __typename?: 'SupplierPaypal';
  id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
};

export type SupplierRequestChangeInput = {
  topic: SupplierRequestChangeType;
  message: Scalars['String'];
};

export enum SupplierRequestChangeType {
  Language = 'LANGUAGE',
  WorkingArea = 'WORKING_AREA'
}

export type SupplierScarCityPayload = {
  __typename?: 'SupplierScarCityPayload';
  slot: Scalars['Int'];
  month: Scalars['String'];
};

export type SupplierStats = {
  __typename?: 'SupplierStats';
  reviewsCount: Scalars['Float'];
  rating: Scalars['Float'];
  bookingsCount: Scalars['Int'];
};

export enum SupplierStatus {
  Supplier = 'SUPPLIER',
  SupplierApply = 'SUPPLIER_APPLY',
  SupplierFailed = 'SUPPLIER_FAILED'
}

export type SupplierTourBadgesFilters = {
  ACTIVE_BADGES?: Maybe<Scalars['Boolean']>;
  ALLOWED_CATEGORY_SLUGS?: Maybe<Array<Scalars['String']>>;
};

export type SupplierTourFilters = {
  SEARCH?: Maybe<Scalars['String']>;
};

export type SupplierWorkingDays = {
  __typename?: 'SupplierWorkingDays';
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type Tags = {
  __typename?: 'Tags';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum ThumbSize {
  Xs = 'XS',
  Sm = 'SM',
  Md = 'MD',
  Lg = 'LG',
  Original = 'ORIGINAL',
  Feature = 'FEATURE',
  MobileHero = 'MOBILE_HERO'
}

export type TopPagePayload = {
  __typename?: 'TopPagePayload';
  tours: Array<Tour>;
  suppliers: Array<Supplier>;
  newTours: Array<Tour>;
  newSuppliers: Array<Supplier>;
  cars: Array<Tour>;
  countries: Array<Country>;
  citiesJapan: Array<City>;
  citiesOthers: Array<City>;
  stats: StatsPayload;
};

export type Tour = {
  __typename?: 'Tour';
  id: Scalars['Int'];
  title: Scalars['String'];
  productType: ProductType;
  supplierId: Scalars['Float'];
  priceModel: PriceModel;
  slug: Scalars['String'];
  overview?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  important?: Maybe<Scalars['String']>;
  conditions?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  video?: Maybe<Scalars['String']>;
  meetingPoint: Scalars['String'];
  meetingPointDescription?: Maybe<Scalars['String']>;
  endingPoint: Scalars['String'];
  country: Country;
  city: City;
  supplier: Supplier;
  picture?: Maybe<Media>;
  gallery?: Maybe<Array<Media>>;
  category?: Maybe<Category>;
  people: TourPeople;
  language?: Maybe<Language>;
  url: Scalars['String'];
  included?: Maybe<Scalars['String']>;
  notIncluded?: Maybe<Scalars['String']>;
  duration: Scalars['String'];
  faqs?: Maybe<Array<TourFaq>>;
  calendar: Calendar;
  tourSpecificCalendar: Calendar;
  status: TourStatus;
  countViews: Scalars['Int'];
  meetingTime?: Maybe<Array<Scalars['String']>>;
  childrenPriceRule?: Maybe<ChildrenInfantPriceRule>;
  infantPriceRule?: Maybe<ChildrenInfantPriceRule>;
  countBookings: Scalars['Int'];
  tourContentUpdatedAt?: Maybe<Scalars['DateTime']>;
  timeFrom?: Maybe<Scalars['String']>;
  priceRules: Array<PriceRules>;
  hasPriceRules: Scalars['Boolean'];
  availability: Scalars['String'];
  isAvailabilityUpdated: Scalars['Boolean'];
  price: TourPrice;
  displayPrice: TourPrice;
  itinerary: Array<TourItinerary>;
  cancelPolicyRules: Array<CancelPolicyRule>;
};

export enum TourAvailability {
  Profile = 'PROFILE',
  SpecificDaysOfWeek = 'SPECIFIC_DAYS_OF_WEEK',
  SpecificDates = 'SPECIFIC_DATES'
}

export type TourCalendarPayload = {
  __typename?: 'TourCalendarPayload';
  calendar: Calendar;
};

export type TourCategoryConnection = {
  __typename?: 'TourCategoryConnection';
  pageInfo: PageInfo;
  edges: Array<TourCategoryEdge>;
  totalCount: Scalars['Int'];
};

export type TourCategoryEdge = {
  __typename?: 'TourCategoryEdge';
  node: Category;
  cursor: Scalars['String'];
};

export type TourConnection = {
  __typename?: 'TourConnection';
  pageInfo: PageInfo;
  edges: Array<TourEdge>;
  totalCount: Scalars['Int'];
};

export type TourEdge = {
  __typename?: 'TourEdge';
  node: Tour;
  cursor: Scalars['String'];
};

export type TourFaq = {
  __typename?: 'TourFaq';
  title: Scalars['String'];
  content: Scalars['String'];
};

export type TourFilters = {
  SUPPLIERS_IDS?: Maybe<Array<Scalars['Int']>>;
  TOURS_IDS?: Maybe<Array<Scalars['Int']>>;
  PRODUCT_TYPES?: Maybe<Array<ProductType>>;
  SEARCH?: Maybe<Scalars['String']>;
  COUNTRIES_SLUGS?: Maybe<Array<Scalars['String']>>;
  COUNTRIES_IDS?: Maybe<Array<Scalars['String']>>;
  CITIES_SLUGS?: Maybe<Array<Scalars['String']>>;
  CITIES_IDS?: Maybe<Array<Scalars['Int']>>;
  EXCLUDE_CITY_IDS?: Maybe<Array<Scalars['Int']>>;
  GROUP_BY?: Maybe<GroupByType>;
  DURATION?: Maybe<DurationType>;
  CATEGORIES_IDS?: Maybe<Array<Scalars['Int']>>;
  EXCLUDE_CATEGORIES_IDS?: Maybe<Array<Scalars['Int']>>;
  CATEGORIES_SLUGS?: Maybe<Array<Scalars['String']>>;
  LANGUAGES_IDS?: Maybe<Array<Scalars['Int']>>;
  LANGUAGES_CODES?: Maybe<Array<Scalars['String']>>;
  CALENDAR?: Maybe<Array<Scalars['String']>>;
  PEOPLE?: Maybe<Scalars['Int']>;
  ADULTS?: Maybe<Scalars['Int']>;
  CHILDREN?: Maybe<Scalars['Int']>;
  INFANTS?: Maybe<Scalars['Int']>;
  ORDER_BY?: Maybe<TourOrderBy>;
  EXCLUDE_IDS?: Maybe<Array<Scalars['Int']>>;
  TOP_TOUR_PER_COUNTRY?: Maybe<Scalars['Boolean']>;
};

export type TourFiltersResponse = {
  __typename?: 'TourFiltersResponse';
  categories: Array<Category>;
  durations: Array<DurationEnum>;
  languages: Array<Language>;
};

export type TourItinerary = {
  __typename?: 'TourItinerary';
  type: Scalars['String'];
  time?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export enum TourOrderBy {
  CreatedAtDesc = 'CREATED_AT_DESC',
  RankAsc = 'RANK_ASC',
  RankDesc = 'RANK_DESC',
  Recommended = 'RECOMMENDED',
  TopReviewed = 'TOP_REVIEWED',
  BestSellers = 'BEST_SELLERS'
}

export type TourPeople = {
  __typename?: 'TourPeople';
  min: Scalars['Float'];
  max: Scalars['Float'];
};

export type TourPrice = {
  __typename?: 'TourPrice';
  currency: Currency;
  amount: Scalars['Float'];
  model: PriceModel;
};

export type TourPriceInput = {
  adults: Scalars['Int'];
  children?: Maybe<Scalars['Int']>;
  infants?: Maybe<Scalars['Int']>;
};

export type TourPriceRule = {
  people?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
};

export type TourQuotationItem = {
  __typename?: 'TourQuotationItem';
  type: BookingQuotationItemType;
  description: Scalars['String'];
  price: Scalars['Float'];
};

export type TourRequest = {
  __typename?: 'TourRequest';
  city?: Maybe<City>;
  tour?: Maybe<Tour>;
  meetingDate?: Maybe<Scalars['DateTime']>;
  meetingTime?: Maybe<Scalars['String']>;
  adultsCount: Scalars['Int'];
  childrenCount: Scalars['Int'];
  infantCount: Scalars['Int'];
  accomodationAddress?: Maybe<Scalars['String']>;
};

export type TourScarCityInput = {
  selectedDate?: Maybe<Scalars['String']>;
};

export type TourScarCityPayload = {
  __typename?: 'TourScarCityPayload';
  slot: Scalars['Int'];
  month: Scalars['String'];
};

export enum TourStatus {
  Draft = 'DRAFT',
  Inactive = 'INACTIVE',
  Published = 'PUBLISHED',
  InReview = 'IN_REVIEW',
  Rejected = 'REJECTED'
}

export type ToursConnection = {
  __typename?: 'ToursConnection';
  pageInfo: PageInfo;
  edges: Array<ToursEdge>;
  totalCount: Scalars['Int'];
};

export type ToursEdge = {
  __typename?: 'ToursEdge';
  node: Tour;
  cursor: Scalars['String'];
};

export type UpdateBlogInput = {
  title?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  languageId?: Maybe<Scalars['Int']>;
  countryId?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  coverPicId?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  contentImage?: Maybe<Array<BlogContentImage>>;
};

export type UpdateBlogPayload = {
  __typename?: 'UpdateBlogPayload';
  post: Post;
};

export type UpdateBookingInput = {
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['Int']>;
  meetingDate?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  meetingTime?: Maybe<Scalars['String']>;
  meetingPoint?: Maybe<Scalars['String']>;
  meetingPointDescription?: Maybe<Scalars['String']>;
  endingTime?: Maybe<Scalars['String']>;
  endingPoint?: Maybe<Scalars['String']>;
  itinerary?: Maybe<Array<ItineraryInput>>;
  adultsCount?: Maybe<Scalars['Int']>;
  childCount?: Maybe<Scalars['Int']>;
  infantCount?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  included?: Maybe<Scalars['String']>;
  notIncluded?: Maybe<Scalars['String']>;
  accommodation?: Maybe<Scalars['String']>;
};

export type UpdateBookingPayload = {
  __typename?: 'UpdateBookingPayload';
  booking: Booking;
};

export type UpdateCustomerAccountInput = {
  pictureId?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  currentPassword?: Maybe<Scalars['String']>;
};

export type UpdateCustomerAccountPayload = {
  __typename?: 'UpdateCustomerAccountPayload';
  user: User;
};

export type UpdateFilesInput = {
  id: Scalars['Int'];
  caption?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

export type UpdateMessageAsReadPayload = {
  __typename?: 'UpdateMessageAsReadPayload';
  message: Message;
};

export type UpdateMessageInput = {
  hide?: Maybe<Scalars['Boolean']>;
};

export type UpdateMessagePayload = {
  __typename?: 'UpdateMessagePayload';
  message: Message;
};

export type UpdateMessageTemplateInput = {
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
};

export type UpdateMessageTemplatePayload = {
  __typename?: 'UpdateMessageTemplatePayload';
  messageTemplate: MessageTemplate;
};

export type UpdateMessageThreadInput = {
  isArchived?: Maybe<Scalars['Boolean']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isRead?: Maybe<Scalars['Boolean']>;
  hide?: Maybe<Scalars['Boolean']>;
};

export type UpdateMessageThreadPayload = {
  __typename?: 'UpdateMessageThreadPayload';
  message: Message;
};

export type UpdateSupplierAccountInput = {
  pictureId?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  nationalityId?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  currentPassword?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  profileVideoId?: Maybe<Scalars['String']>;
  maxPeople?: Maybe<Scalars['Int']>;
  noticeBeforeBooking?: Maybe<GuideBookingNotice>;
  guideBookingWindow?: Maybe<GuideBookingWindow>;
  availability?: Maybe<SupplierAvailabilityInput>;
  bookingConditions?: Maybe<Scalars['String']>;
  preferredLanguageId?: Maybe<Scalars['Int']>;
  guideBookingConditionsUpdatedFirstTime?: Maybe<Scalars['Boolean']>;
};

export type UpdateSupplierAccountPayload = {
  __typename?: 'UpdateSupplierAccountPayload';
  supplier: Supplier;
};

export type UpdateSupplierBankInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  bankName: Scalars['String'];
  bankCode: Scalars['String'];
  branchName: Scalars['String'];
  branchCode: Scalars['String'];
  accountNumber: Scalars['String'];
  accountName: Scalars['String'];
  accountType: AccountType;
};

export type UpdateSupplierBankPayload = {
  __typename?: 'UpdateSupplierBankPayload';
  supplier: Supplier;
};

export type UpdateSupplierGalleryFilesInput = {
  updateInput?: Maybe<UpdateFilesInput>;
  createInput?: Maybe<CreateFileInput>;
};

export type UpdateSupplierGalleryInput = {
  updateGalleryInput?: Maybe<Array<UpdateSupplierGalleryFilesInput>>;
  deleteIds?: Maybe<Array<Scalars['Int']>>;
};

export type UpdateSupplierGalleryPayload = {
  __typename?: 'UpdateSupplierGalleryPayload';
  supplier: Supplier;
};

export type UpdateSupplierPaypalInput = {
  accessCode: Scalars['String'];
};

export type UpdateSupplierPaypalPayload = {
  __typename?: 'UpdateSupplierPaypalPayload';
  supplier: Supplier;
};

export type UpdateTourFileInput = {
  id: Scalars['Int'];
  caption?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

export type UpdateTourFilesInput = {
  updateInput?: Maybe<UpdateTourFileInput>;
  createInput?: Maybe<CreateFileInput>;
};

export type UpdateTourGalleryInput = {
  updateGalleryInput?: Maybe<Array<UpdateTourFilesInput>>;
  deleteIds?: Maybe<Array<Scalars['Int']>>;
};

export type UpdateTourInput = {
  countryId?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  productType?: Maybe<ProductType>;
  languageId?: Maybe<Scalars['Int']>;
  overview?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  meetingTimeFrom?: Maybe<Scalars['String']>;
  meetingTimeTo?: Maybe<Scalars['String']>;
  timeFrom?: Maybe<Scalars['String']>;
  meetingPlace?: Maybe<Scalars['String']>;
  meetingPointDescription?: Maybe<Scalars['String']>;
  timeTo?: Maybe<Scalars['String']>;
  endingPlace?: Maybe<Scalars['String']>;
  itinerary?: Maybe<Array<ItineraryInput>>;
  important?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  peopleMax?: Maybe<Scalars['Int']>;
  priceModel?: Maybe<PriceModel>;
  priceRules?: Maybe<Array<TourPriceRule>>;
  childrenPriceRule?: Maybe<ChildrenInfantPriceRule>;
  infantPriceRule?: Maybe<ChildrenInfantPriceRule>;
  included?: Maybe<Scalars['String']>;
  notIncluded?: Maybe<Scalars['String']>;
  availability?: Maybe<TourAvailability>;
  availabilityRules?: Maybe<Array<Scalars['String']>>;
  conditions?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['Int']>;
  gallery?: Maybe<UpdateTourGalleryInput>;
};

export type UpdateTourPayload = {
  __typename?: 'UpdateTourPayload';
  tour: Tour;
  success: Scalars['Boolean'];
};

export type User = BaseUser & {
  __typename?: 'User';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  isSupplier: Scalars['Boolean'];
  isActivity: Scalars['Boolean'];
  isBlogger: Scalars['Boolean'];
  guideApply: Scalars['Boolean'];
  guideApplyStep?: Maybe<Scalars['String']>;
  isSupplierApply?: Maybe<Scalars['Boolean']>;
  isGuide?: Maybe<Scalars['Boolean']>;
  isAdmin: Scalars['Boolean'];
  isBan: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  displayName: Scalars['String'];
  picture?: Maybe<Media>;
  pictureId?: Maybe<Scalars['Int']>;
  gallery?: Maybe<Array<Media>>;
  country?: Maybe<Country>;
  phone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  nationality?: Maybe<Country>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  isSocialLoginEnabled: Scalars['Boolean'];
  biography?: Maybe<Scalars['String']>;
  isBiographyUpdated: Scalars['Boolean'];
  bookings: BookingsConnection;
  bookingById: Booking;
  messageById: Message;
  messages: MessageConnection;
  blogs: BlogsConnection;
  url: Scalars['String'];
  isPersonalInfoUpdated: Scalars['Boolean'];
  isPasswordUpdated: Scalars['Boolean'];
  customerNotification: CustomerNotification;
};


export type UserBookingsArgs = {
  filters?: Maybe<BookingFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type UserBookingByIdArgs = {
  id: Scalars['Int'];
};


export type UserMessageByIdArgs = {
  id: Scalars['Int'];
};


export type UserMessagesArgs = {
  filters?: Maybe<MessageFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};


export type UserBlogsArgs = {
  filters?: Maybe<MyPostFilters>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  random?: Maybe<Scalars['Int']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  pageInfo: PageInfo;
  edges: Array<UserEdge>;
  totalCount: Scalars['Int'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  node: User;
  cursor: Scalars['String'];
};

export type UserFilters = {
  USERS_IDS?: Maybe<Array<Scalars['Int']>>;
  USER_TYPE?: Maybe<UserType>;
  COUNTRIES_SLUGS?: Maybe<Array<Scalars['String']>>;
  COUNTRIES_IDS?: Maybe<Array<Scalars['String']>>;
  CITIES_SLUGS?: Maybe<Array<Scalars['String']>>;
  CITIES_IDS?: Maybe<Array<Scalars['Int']>>;
  SEARCH?: Maybe<Scalars['String']>;
};

export type UserOrSupplier = User | Supplier;

export enum UserType {
  All = 'ALL',
  Supplier = 'SUPPLIER',
  Admin = 'ADMIN',
  Guide = 'GUIDE'
}

export enum AccountType {
  SavingAccount = 'SAVING_ACCOUNT',
  CheckingAccount = 'CHECKING_ACCOUNT'
}

export type GetToursFiltersAvailableQueryVariables = Exact<{
  filters?: Maybe<TourFilters>;
}>;


export type GetToursFiltersAvailableQuery = (
  { __typename?: 'Query' }
  & { getToursFiltersAvailable: (
    { __typename?: 'TourFiltersResponse' }
    & Pick<TourFiltersResponse, 'durations'>
    & { categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'slug'>
    )>, languages: Array<(
      { __typename?: 'Language' }
      & Pick<Language, 'id' | 'name' | 'code'>
    )> }
  ) }
);

export type GetSuppliersFiltersAvailableQueryVariables = Exact<{
  filters?: Maybe<SupplierFilters>;
}>;


export type GetSuppliersFiltersAvailableQuery = (
  { __typename?: 'Query' }
  & { getSuppliersFiltersAvailable: (
    { __typename?: 'SupplierFiltersResponse' }
    & Pick<SupplierFiltersResponse, 'durations'>
    & { categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'slug'>
    )>, languages: Array<(
      { __typename?: 'Language' }
      & Pick<Language, 'id' | 'name' | 'code'>
    )> }
  ) }
);

export type ActiveAnnouncementQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveAnnouncementQuery = (
  { __typename?: 'Query' }
  & { activeAnnouncement?: Maybe<(
    { __typename?: 'Announcement' }
    & Pick<Announcement, 'id' | 'announcement' | 'expiresAt' | 'status' | 'priority'>
  )> }
);

export type BlogCategoryBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type BlogCategoryBySlugQuery = (
  { __typename?: 'Query' }
  & { blogCategoryBySlug?: Maybe<(
    { __typename?: 'Category' }
    & CategoryCardFragment
  )> }
);

export type CategoryBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type CategoryBySlugQuery = (
  { __typename?: 'Query' }
  & { categoryBySlug?: Maybe<(
    { __typename?: 'Category' }
    & CategoryCardFragment
  )> }
);

export type TourCategoriesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filters?: Maybe<CategoryFilters>;
}>;


export type TourCategoriesQuery = (
  { __typename?: 'Query' }
  & { tourCategories: (
    { __typename?: 'TourCategoryConnection' }
    & { edges: Array<(
      { __typename?: 'TourCategoryEdge' }
      & { node: (
        { __typename?: 'Category' }
        & CategoryCardFragment
      ) }
    )> }
  ) }
);

export type PostCategoriesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filters?: Maybe<CategoryFilters>;
}>;


export type PostCategoriesQuery = (
  { __typename?: 'Query' }
  & { postCategories: (
    { __typename?: 'PostCategoryConnection' }
    & { edges: Array<(
      { __typename?: 'PostCategoryEdge' }
      & { node: (
        { __typename?: 'Category' }
        & CategoryCardFragment
      ) }
    )> }
  ) }
);

export type TourCategoriesForTourCreationQueryVariables = Exact<{
  filters?: Maybe<CategoryFilters>;
}>;


export type TourCategoriesForTourCreationQuery = (
  { __typename?: 'Query' }
  & { tourCategoriesForTourCreation: Array<(
    { __typename?: 'Category' }
    & CategoryCardFragment
  )> }
);

export type MeCustomerAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type MeCustomerAccountQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'gender' | 'birthday' | 'isSocialLoginEnabled' | 'isPersonalInfoUpdated' | 'isPasswordUpdated' | 'phoneCountryCode' | 'biography' | 'isBiographyUpdated' | 'pictureId'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    )>, picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) | (
    { __typename: 'Supplier' }
    & Pick<Supplier, 'id'>
  ) }
);

export type FaqByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FaqByIdQuery = (
  { __typename?: 'Query' }
  & { faqById: (
    { __typename?: 'Faqs' }
    & Pick<Faqs, 'id' | 'categoryId' | 'title' | 'title_en' | 'position' | 'countViews' | 'content' | 'content_en' | 'url'>
    & { relatedFaqs: Array<(
      { __typename?: 'Faqs' }
      & Pick<Faqs, 'id' | 'title_en' | 'title' | 'url'>
    )>, category: (
      { __typename?: 'FaqCategory' }
      & Pick<FaqCategory, 'id' | 'title' | 'title_en' | 'url'>
      & { parentCategory?: Maybe<(
        { __typename?: 'FaqCategory' }
        & Pick<FaqCategory, 'id' | 'title' | 'title_en' | 'url'>
      )> }
    ) }
  ) }
);

export type FaqCategoryByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FaqCategoryByIdQuery = (
  { __typename?: 'Query' }
  & { faqCategoryById?: Maybe<(
    { __typename?: 'FaqCategory' }
    & Pick<FaqCategory, 'id' | 'parentId' | 'title' | 'title_en' | 'position' | 'faqsCount' | 'url'>
    & { faqs: Array<(
      { __typename?: 'Faqs' }
      & Pick<Faqs, 'id' | 'title' | 'title_en' | 'content' | 'content_en'>
    )>, parentCategory?: Maybe<(
      { __typename?: 'FaqCategory' }
      & Pick<FaqCategory, 'id' | 'title' | 'title_en' | 'url'>
    )>, subCategories?: Maybe<Array<(
      { __typename?: 'FaqCategory' }
      & Pick<FaqCategory, 'id' | 'parentId' | 'title' | 'title_en' | 'position' | 'faqsCount' | 'url'>
      & { faqs: Array<(
        { __typename?: 'Faqs' }
        & Pick<Faqs, 'id' | 'title' | 'title_en' | 'content' | 'content_en'>
      )> }
    )>> }
  )> }
);

export type GetFaqCategoriesQueryVariables = Exact<{
  sizePerPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  filters?: Maybe<FaqCategoryFilters>;
}>;


export type GetFaqCategoriesQuery = (
  { __typename?: 'Query' }
  & { faqCategories: (
    { __typename: 'FaqCategoriesConnection' }
    & Pick<FaqCategoriesConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor' | 'page' | 'totalPages'>
    ), edges: Array<(
      { __typename?: 'FaqCategoriesEdge' }
      & { node: (
        { __typename?: 'FaqCategory' }
        & Pick<FaqCategory, 'id' | 'parentId' | 'title' | 'title_en' | 'position' | 'faqsCount' | 'url'>
        & { subCategories?: Maybe<Array<(
          { __typename?: 'FaqCategory' }
          & Pick<FaqCategory, 'id' | 'parentId' | 'title' | 'title_en' | 'position' | 'faqsCount' | 'url'>
        )>> }
      ) }
    )> }
  ) }
);

export type GetFaqsQueryVariables = Exact<{
  sizePerPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  filters?: Maybe<FaqsFilters>;
}>;


export type GetFaqsQuery = (
  { __typename?: 'Query' }
  & { faqs: (
    { __typename: 'FaqsConnection' }
    & Pick<FaqsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor' | 'page' | 'totalPages'>
    ), edges: Array<(
      { __typename?: 'FaqsEdge' }
      & { node: (
        { __typename?: 'Faqs' }
        & Pick<Faqs, 'id' | 'categoryId' | 'title' | 'title_en' | 'position' | 'countViews' | 'content' | 'content_en'>
        & { category: (
          { __typename?: 'FaqCategory' }
          & Pick<FaqCategory, 'id' | 'title' | 'title_en' | 'parentId'>
        ) }
      ) }
    )> }
  ) }
);

export type HasSupportAccessQueryVariables = Exact<{ [key: string]: never; }>;


export type HasSupportAccessQuery = (
  { __typename?: 'Query' }
  & { hasSupportAccess: (
    { __typename?: 'HasSupportAccessResponse' }
    & Pick<HasSupportAccessResponse, 'success'>
  ) }
);

export type IncrementFaqViewCountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IncrementFaqViewCountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'IncrementFaqViewCount'>
);

export type SupplierContactSupportMutationVariables = Exact<{
  input: SupplierContactSupportInput;
}>;


export type SupplierContactSupportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SupplierContactSupport'>
);

export type LanguagesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filters?: Maybe<LanguageFilters>;
}>;


export type LanguagesQuery = (
  { __typename?: 'Query' }
  & { languages: (
    { __typename?: 'LanguageConnection' }
    & { edges: Array<(
      { __typename?: 'LanguageEdge' }
      & { node: (
        { __typename?: 'Language' }
        & Pick<Language, 'id' | 'name' | 'code'>
      ) }
    )> }
  ) }
);

export type CitiesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filters?: Maybe<CityFilters>;
}>;


export type CitiesQuery = (
  { __typename?: 'Query' }
  & { cities: (
    { __typename?: 'CityConnection' }
    & { edges: Array<(
      { __typename?: 'CityEdge' }
      & { node: (
        { __typename?: 'City' }
        & CityCardFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ) }
  ) }
);

export type CitiesWithSuppliersQueryVariables = Exact<{
  filters: CityFilters;
  suppliersFilters?: Maybe<SupplierFilters>;
  limit?: Maybe<Scalars['Int']>;
  suppliersLimit?: Maybe<Scalars['Int']>;
}>;


export type CitiesWithSuppliersQuery = (
  { __typename?: 'Query' }
  & { cities: (
    { __typename?: 'CityConnection' }
    & { edges: Array<(
      { __typename?: 'CityEdge' }
      & { node: (
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name' | 'slug'>
        & { suppliers: Array<(
          { __typename?: 'Supplier' }
          & SupplierCardFragment
        )> }
      ) }
    )> }
  ) }
);

export type CitiesWithToursQueryVariables = Exact<{
  filters: CityFilters;
  toursFilters?: Maybe<TourFilters>;
  limit?: Maybe<Scalars['Int']>;
  toursLimit?: Maybe<Scalars['Int']>;
}>;


export type CitiesWithToursQuery = (
  { __typename?: 'Query' }
  & { cities: (
    { __typename?: 'CityConnection' }
    & { edges: Array<(
      { __typename?: 'CityEdge' }
      & { node: (
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name' | 'slug'>
        & { tours: Array<(
          { __typename?: 'Tour' }
          & TourCardFragment
        )> }
      ) }
    )> }
  ) }
);

export type CityBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
  locationStatsFilters?: Maybe<LocationStatsFilters>;
}>;


export type CityBySlugQuery = (
  { __typename?: 'Query' }
  & { cityBySlug?: Maybe<(
    { __typename?: 'City' }
    & Pick<City, 'id' | 'name' | 'slug' | 'url' | 'hasSuppliers' | 'hasOnlineTours' | 'hasPrivateTours' | 'hasTransportationTours'>
    & { seoContent: (
      { __typename?: 'SeoContentPayload' }
      & { top: (
        { __typename?: 'SeoContent' }
        & Pick<SeoContent, 'title' | 'content'>
      ), tours: (
        { __typename?: 'SeoContent' }
        & Pick<SeoContent, 'title' | 'content'>
      ), guides: (
        { __typename?: 'SeoContent' }
        & Pick<SeoContent, 'title' | 'content'>
      ), online: (
        { __typename?: 'SeoContent' }
        & Pick<SeoContent, 'title' | 'content'>
      ), transportation: (
        { __typename?: 'SeoContent' }
        & Pick<SeoContent, 'title' | 'content'>
      ) }
    ), picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url' | 'urlSM' | 'serpImage'>
    )>, country: (
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name' | 'slug' | 'url' | 'isActive'>
    ), relatedCities?: Maybe<Array<(
      { __typename?: 'City' }
      & CityCardFragment
    )>>, faqs?: Maybe<Array<(
      { __typename?: 'FaqContent' }
      & Pick<FaqContent, 'question' | 'answer'>
    )>>, stats: (
      { __typename?: 'LocationStats' }
      & Pick<LocationStats, 'reviewsCount' | 'reviewsRating'>
    ) }
  )> }
);

export type CountriesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filters?: Maybe<CountryFilters>;
}>;


export type CountriesQuery = (
  { __typename?: 'Query' }
  & { countries: (
    { __typename?: 'CountryConnection' }
    & { edges: Array<(
      { __typename?: 'CountryEdge' }
      & { node: (
        { __typename?: 'Country' }
        & CountryCardFragment
      ) }
    )> }
  ) }
);

export type CountryBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
  locationStatsFilters?: Maybe<LocationStatsFilters>;
}>;


export type CountryBySlugQuery = (
  { __typename?: 'Query' }
  & { countryBySlug?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name' | 'slug' | 'url' | 'hasSuppliers' | 'hasOnlineTours' | 'hasPrivateTours' | 'hasTransportationTours' | 'isActive'>
    & { seoContent: (
      { __typename?: 'SeoContentPayload' }
      & { top: (
        { __typename?: 'SeoContent' }
        & Pick<SeoContent, 'title' | 'content'>
      ), tours: (
        { __typename?: 'SeoContent' }
        & Pick<SeoContent, 'title' | 'content'>
      ), guides: (
        { __typename?: 'SeoContent' }
        & Pick<SeoContent, 'title' | 'content'>
      ), online: (
        { __typename?: 'SeoContent' }
        & Pick<SeoContent, 'title' | 'content'>
      ), transportation: (
        { __typename?: 'SeoContent' }
        & Pick<SeoContent, 'title' | 'content'>
      ) }
    ), picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url' | 'urlSM' | 'serpImage'>
    )>, faqs?: Maybe<Array<(
      { __typename?: 'FaqContent' }
      & Pick<FaqContent, 'question' | 'answer'>
    )>>, stats: (
      { __typename?: 'LocationStats' }
      & Pick<LocationStats, 'reviewsCount' | 'reviewsRating'>
    ) }
  )> }
);

export type LocationsQueryVariables = Exact<{
  limit: Scalars['Int'];
  filters?: Maybe<LocationFilters>;
}>;


export type LocationsQuery = (
  { __typename?: 'Query' }
  & { locations: Array<(
    { __typename: 'City' }
    & Pick<City, 'name' | 'url' | 'slug'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Country, 'name'>
    ) }
  ) | (
    { __typename: 'Country' }
    & Pick<Country, 'name' | 'url' | 'slug'>
  )> }
);

export type LocationsWithDataQueryVariables = Exact<{
  limit: Scalars['Int'];
  filters?: Maybe<LocationFilters>;
}>;


export type LocationsWithDataQuery = (
  { __typename?: 'Query' }
  & { locations: Array<(
    { __typename: 'City' }
    & Pick<City, 'name' | 'url' | 'slug'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Country, 'name'>
    ), picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url' | 'urlSM' | 'serpImage'>
    )> }
  ) | (
    { __typename: 'Country' }
    & Pick<Country, 'name' | 'url' | 'slug'>
    & { picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url' | 'urlSM' | 'serpImage'>
    )> }
  )> }
);

export type CityBySlugWithCountryQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type CityBySlugWithCountryQuery = (
  { __typename?: 'Query' }
  & { cityBySlug?: Maybe<(
    { __typename?: 'City' }
    & Pick<City, 'id' | 'name' | 'slug' | 'url'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name' | 'slug' | 'url' | 'isActive'>
    ) }
  )> }
);

export type UserContextFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'displayName' | 'firstName' | 'isSupplier' | 'isSupplierApply' | 'guideApplyStep' | 'isAdmin' | 'isBlogger' | 'email' | 'phone' | 'phoneCountryCode'>
  & { country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id'>
  )>, picture?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )> }
);

export type SupplierContextFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'firstName' | 'isSupplier' | 'isSupplierApply' | 'guideApplyStep' | 'isAdmin' | 'isBlogger' | 'payoutMethodStatus' | 'payoutMethodType' | 'payoutMethod' | 'email' | 'phone' | 'phoneCountryCode' | 'gender' | 'birthday' | 'address' | 'postalCode'>
  & { country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id'>
  )>, stats: (
    { __typename?: 'SupplierStats' }
    & Pick<SupplierStats, 'bookingsCount'>
  ), picture?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )>, nationality?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>, city?: Maybe<(
    { __typename?: 'City' }
    & Pick<City, 'id' | 'name'>
  )> }
);

export type MeContextQueryVariables = Exact<{ [key: string]: never; }>;


export type MeContextQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & UserContextFragment
  ) | (
    { __typename: 'Supplier' }
    & SupplierContextFragment
  ) }
);

export type UserBasicContextFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'isSupplier' | 'isSupplierApply' | 'isAdmin' | 'isBlogger'>
);

export type SupplierBasicContextFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'isSupplier' | 'isSupplierApply' | 'isAdmin' | 'isBlogger'>
);

export type MeContextBasicQueryVariables = Exact<{ [key: string]: never; }>;


export type MeContextBasicQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & UserBasicContextFragment
  ) | (
    { __typename: 'Supplier' }
    & SupplierBasicContextFragment
  ) }
);

export type UserContextForBlogCreateFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'displayName' | 'isSupplier' | 'isBlogger'>
);

export type SupplierContextForBlogCreateFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'isSupplier' | 'isBlogger'>
  & { country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>, workingCountries?: Maybe<Array<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>>, cities: Array<(
    { __typename?: 'City' }
    & Pick<City, 'id' | 'name'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    ) }
  )>, languages: Array<(
    { __typename?: 'SupplierLanguage' }
    & Pick<SupplierLanguage, 'id' | 'level'>
    & { language: (
      { __typename?: 'Language' }
      & Pick<Language, 'id' | 'name' | 'code'>
    ) }
  )> }
);

export type MeContextForBlogCreationQueryVariables = Exact<{ [key: string]: never; }>;


export type MeContextForBlogCreationQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & UserContextForBlogCreateFragment
  ) | (
    { __typename: 'Supplier' }
    & SupplierContextForBlogCreateFragment
  ) }
);

export type MeContextForSupplierApplyQueryVariables = Exact<{ [key: string]: never; }>;


export type MeContextForSupplierApplyQuery = (
  { __typename?: 'Query' }
  & { meForSupplierApply: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'displayName' | 'firstName' | 'lastName' | 'isSupplier' | 'isSupplierApply' | 'guideApplyStep' | 'isAdmin' | 'isBlogger' | 'email' | 'phone' | 'phoneCountryCode' | 'biography' | 'guideProfileSteps' | 'gender' | 'birthday' | 'address' | 'postalCode' | 'important' | 'guidePhotoVerification' | 'guidePhotoWorkingPermit' | 'guidePhotoLicense' | 'youNeedGuideLicense' | 'haveGuideLicense' | 'pictureId' | 'notes'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    )>, stats: (
      { __typename?: 'SupplierStats' }
      & Pick<SupplierStats, 'bookingsCount'>
    ), picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )>, nationality?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    )>, city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name'>
    )>, cities: Array<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name'>
    )>, languages: Array<(
      { __typename?: 'SupplierLanguage' }
      & Pick<SupplierLanguage, 'id' | 'level'>
      & { language: (
        { __typename?: 'Language' }
        & Pick<Language, 'id' | 'name' | 'code'>
      ) }
    )>, workingDays?: Maybe<(
      { __typename?: 'SupplierWorkingDays' }
      & Pick<SupplierWorkingDays, 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'>
    )>, guidePhotoVerificationUrl?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'id' | 'url' | 'name'>
    )>, guidePhotoLicenseUrl?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'id' | 'url' | 'name'>
    )>, guidePhotoWorkingPermitUrl?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'id' | 'url' | 'name'>
    )> }
  ) }
);

export type CreateFileMutationVariables = Exact<{
  input: CreateFileInput;
}>;


export type CreateFileMutation = (
  { __typename?: 'Mutation' }
  & { CreateFile: (
    { __typename?: 'CreateFilePayload' }
    & { picture: (
      { __typename?: 'Picture' }
      & Pick<Picture, 'id' | 'name' | 'tableId' | 'tableName' | 'position'>
    ) }
  ) }
);

export type CreateFileSignedUrlMutationVariables = Exact<{
  input: CreateFileSignedUrlInput;
}>;


export type CreateFileSignedUrlMutation = (
  { __typename?: 'Mutation' }
  & { CreateFileSignedUrl: (
    { __typename?: 'CreateFileSignedUrlPayload' }
    & Pick<CreateFileSignedUrlPayload, 'fileName' | 'url'>
  ) }
);

export type GetHighQualityImageFromUrlQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type GetHighQualityImageFromUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getHighQualityImageFromUrl'>
);

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInput;
}>;


export type CreateMessageMutation = (
  { __typename?: 'Mutation' }
  & { CreateMessage: (
    { __typename?: 'CreateMessagePayload' }
    & Pick<CreateMessagePayload, 'isSent'>
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id'>
    ) }
  ) }
);

export type CreatePaymentRequestMutationVariables = Exact<{
  input: CreatePaymentRequestInput;
}>;


export type CreatePaymentRequestMutation = (
  { __typename?: 'Mutation' }
  & { CreatePaymentRequest: (
    { __typename?: 'CreatePaymentRequestPayload' }
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id'>
    ), booking: (
      { __typename?: 'Booking' }
      & Pick<Booking, 'id'>
    ) }
  ) }
);

export type CreateTourOfferMutationVariables = Exact<{
  input: CreateTourOfferInput;
}>;


export type CreateTourOfferMutation = (
  { __typename?: 'Mutation' }
  & { CreateTourOffer: (
    { __typename?: 'CreateTourOfferPayload' }
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id'>
    ), booking: (
      { __typename?: 'Booking' }
      & Pick<Booking, 'id'>
    ) }
  ) }
);

export type CustomerMessageBookingsByIdQueryVariables = Exact<{
  id: Scalars['Int'];
  pageNo: Scalars['Int'];
}>;


export type CustomerMessageBookingsByIdQuery = (
  { __typename?: 'Query' }
  & { GetBookingsByMessageId: (
    { __typename?: 'MessageBookingsPayload' }
    & Pick<MessageBookingsPayload, 'hasMoreBookings'>
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'message' | 'isRead' | 'archived' | 'thread' | 'isImportant' | 'hide' | 'createdAt'>
      & { from: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) | (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )>, stats: (
          { __typename?: 'SupplierStats' }
          & Pick<SupplierStats, 'reviewsCount' | 'rating'>
        ) }
      ), to: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) | (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, stats: (
          { __typename?: 'SupplierStats' }
          & Pick<SupplierStats, 'reviewsCount' | 'rating'>
        ), country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) }
    ), bookings: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'code' | 'title' | 'meetingDate' | 'meetingTime' | 'payoutStatus' | 'status' | 'duration' | 'supplierId' | 'adultsCount' | 'childrenCount' | 'infantCount'>
      & { city?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'name'>
      )>, invoice: (
        { __typename?: 'BookingInvoice' }
        & Pick<BookingInvoice, 'supplierFee' | 'cardFee' | 'total' | 'currencyId'>
      ), currency: (
        { __typename?: 'Currency' }
        & Pick<Currency, 'id' | 'symbol' | 'minPrice'>
        & { quotes: Array<(
          { __typename?: 'Quote' }
          & Pick<Quote, 'currency' | 'rate'>
        )> }
      ), user: (
        { __typename?: 'User' }
        & Pick<User, 'displayName'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ), supplier?: Maybe<(
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName' | 'firstName' | 'isSupplier' | 'isSupplierApply' | 'isAdmin' | 'isBlogger' | 'phone' | 'phoneCountryCode' | 'payoutMethodStatus' | 'payoutMethodType' | 'payoutMethod'>
        & { stats: (
          { __typename?: 'SupplierStats' }
          & Pick<SupplierStats, 'bookingsCount'>
        ), picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      )> }
    )> }
  ) }
);

export type CustomerMessageByIdQueryVariables = Exact<{
  id: Scalars['Int'];
  pageNo: Scalars['Int'];
}>;


export type CustomerMessageByIdQuery = (
  { __typename?: 'Query' }
  & { GetMessageById: (
    { __typename?: 'MessagePayload' }
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'message' | 'isRead' | 'archived' | 'thread' | 'isImportant' | 'hide' | 'createdAt'>
      & { from: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) | (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )>, stats: (
          { __typename?: 'SupplierStats' }
          & Pick<SupplierStats, 'reviewsCount' | 'rating'>
        ) }
      ), to: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) | (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, stats: (
          { __typename?: 'SupplierStats' }
          & Pick<SupplierStats, 'reviewsCount' | 'rating'>
        ), country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) }
    ), messageThread: (
      { __typename?: 'MessageThread' }
      & Pick<MessageThread, 'isBooked' | 'totalCount' | 'isImportant' | 'isArchived' | 'hasMoreMessage'>
      & { messages: Array<(
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'message' | 'hide' | 'isRead' | 'archived' | 'thread' | 'isImportant' | 'createdAt'>
        & { tour?: Maybe<(
          { __typename?: 'Tour' }
          & Pick<Tour, 'id' | 'title' | 'duration' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )>, price: (
            { __typename?: 'TourPrice' }
            & Pick<TourPrice, 'amount' | 'model'>
            & { currency: (
              { __typename?: 'Currency' }
              & Pick<Currency, 'symbol' | 'id'>
              & { quotes: Array<(
                { __typename?: 'Quote' }
                & Pick<Quote, 'currency' | 'rate'>
              )> }
            ) }
          ) }
        )>, tourRequest?: Maybe<(
          { __typename?: 'TourRequest' }
          & Pick<TourRequest, 'meetingDate' | 'meetingTime' | 'accomodationAddress' | 'adultsCount' | 'infantCount' | 'childrenCount'>
          & { city?: Maybe<(
            { __typename?: 'City' }
            & Pick<City, 'id' | 'name'>
            & { country: (
              { __typename?: 'Country' }
              & Pick<Country, 'id' | 'name'>
            ) }
          )>, tour?: Maybe<(
            { __typename?: 'Tour' }
            & Pick<Tour, 'id' | 'title' | 'duration' | 'url'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )>, price: (
              { __typename?: 'TourPrice' }
              & Pick<TourPrice, 'amount' | 'model'>
              & { currency: (
                { __typename?: 'Currency' }
                & Pick<Currency, 'symbol' | 'id'>
                & { quotes: Array<(
                  { __typename?: 'Quote' }
                  & Pick<Quote, 'currency' | 'rate'>
                )> }
              ) }
            ) }
          )> }
        )>, booking?: Maybe<(
          { __typename?: 'Booking' }
          & Pick<Booking, 'id' | 'code' | 'title' | 'bookingType' | 'meetingDate' | 'meetingTime' | 'payoutStatus' | 'status' | 'duration' | 'supplierId' | 'adultsCount' | 'childrenCount' | 'infantCount'>
          & { city?: Maybe<(
            { __typename?: 'City' }
            & Pick<City, 'name'>
          )>, invoice: (
            { __typename?: 'BookingInvoice' }
            & Pick<BookingInvoice, 'supplierFee' | 'cardFee' | 'total' | 'currencyId'>
          ), currency: (
            { __typename?: 'Currency' }
            & Pick<Currency, 'id' | 'symbol' | 'minPrice'>
            & { quotes: Array<(
              { __typename?: 'Quote' }
              & Pick<Quote, 'currency' | 'rate'>
            )> }
          ), user: (
            { __typename?: 'User' }
            & Pick<User, 'displayName'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          ), supplier?: Maybe<(
            { __typename?: 'Supplier' }
            & Pick<Supplier, 'id' | 'displayName' | 'firstName' | 'isSupplier' | 'isSupplierApply' | 'isAdmin' | 'isBlogger' | 'phone' | 'phoneCountryCode' | 'payoutMethodStatus' | 'payoutMethodType' | 'payoutMethod'>
            & { stats: (
              { __typename?: 'SupplierStats' }
              & Pick<SupplierStats, 'bookingsCount'>
            ), picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          )> }
        )>, from: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ) | (
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )>, stats: (
            { __typename?: 'SupplierStats' }
            & Pick<SupplierStats, 'reviewsCount' | 'rating'>
          ) }
        ), to: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ) | (
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )>, stats: (
            { __typename?: 'SupplierStats' }
            & Pick<SupplierStats, 'reviewsCount' | 'rating'>
          ) }
        ) }
      )>, latestMessage: (
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'message' | 'isRead' | 'archived' | 'thread' | 'isImportant' | 'createdAt'>
      ) }
    ) }
  ) }
);

export type CustomerMessagesFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'displayName' | 'phone' | 'url'>
  & { messages: (
    { __typename?: 'MessageConnection' }
    & Pick<MessageConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename?: 'MessageEdge' }
      & Pick<MessageEdge, 'cursor'>
      & { node: (
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'message' | 'isRead' | 'hide' | 'archived' | 'thread' | 'isImportant' | 'createdAt' | 'status'>
        & { from: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ) | (
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ), to: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ) | (
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ), messageThread: (
          { __typename?: 'MessageThread' }
          & Pick<MessageThread, 'isBooked' | 'totalCount' | 'isImportant' | 'isArchived'>
          & { latestMessage: (
            { __typename?: 'Message' }
            & Pick<Message, 'id' | 'message' | 'isRead' | 'archived' | 'thread' | 'isImportant' | 'createdAt'>
          ) }
        ) }
      ) }
    )> }
  ) }
);

export type CustomerMessagesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  inbox?: Maybe<MessageInbox>;
}>;


export type CustomerMessagesQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & CustomerMessagesFragment
  ) | { __typename: 'Supplier' } }
);

export type IsMessageThreadExistsMutationVariables = Exact<{
  userId1: Scalars['Int'];
  userId2: Scalars['Int'];
}>;


export type IsMessageThreadExistsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'IsMessageThreadExists'>
);

export type NewMessageWithBookingMutationVariables = Exact<{
  input: NewMessageWithBookingInput;
}>;


export type NewMessageWithBookingMutation = (
  { __typename?: 'Mutation' }
  & { NewMessageWithBooking: (
    { __typename?: 'NewMessageWithBookingPayload' }
    & Pick<NewMessageWithBookingPayload, 'isSent'>
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id'>
    ) }
  ) }
);

export type ReplyMessageMutationVariables = Exact<{
  input: ReplyMessageInput;
}>;


export type ReplyMessageMutation = (
  { __typename?: 'Mutation' }
  & { ReplyMessage: (
    { __typename?: 'ReplyMessagePayload' }
    & Pick<ReplyMessagePayload, 'isSent'>
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'message' | 'isRead' | 'archived' | 'thread' | 'isImportant' | 'hide' | 'createdAt'>
      & { tour?: Maybe<(
        { __typename?: 'Tour' }
        & Pick<Tour, 'id' | 'title' | 'duration' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, price: (
          { __typename?: 'TourPrice' }
          & Pick<TourPrice, 'amount' | 'model'>
          & { currency: (
            { __typename?: 'Currency' }
            & Pick<Currency, 'symbol' | 'id'>
            & { quotes: Array<(
              { __typename?: 'Quote' }
              & Pick<Quote, 'currency' | 'rate'>
            )> }
          ) }
        ) }
      )>, from: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) | (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )>, stats: (
          { __typename?: 'SupplierStats' }
          & Pick<SupplierStats, 'reviewsCount' | 'rating'>
        ) }
      ), to: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) | (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, stats: (
          { __typename?: 'SupplierStats' }
          & Pick<SupplierStats, 'reviewsCount' | 'rating'>
        ), country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) }
    ) }
  ) }
);

export type ReportMessageByIdMutationVariables = Exact<{
  input: ReportMessageByIdInput;
}>;


export type ReportMessageByIdMutation = (
  { __typename?: 'Mutation' }
  & { ReportMessageById: (
    { __typename?: 'ReportMessageByIdPayload' }
    & Pick<ReportMessageByIdPayload, 'success'>
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id'>
    ) }
  ) }
);

export type ShouldMessageSecuredMutationVariables = Exact<{
  id: Scalars['Int'];
  content: Scalars['String'];
}>;


export type ShouldMessageSecuredMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ShouldMessageSecured'>
);

export type SupplierMessageBookingsByIdQueryVariables = Exact<{
  id: Scalars['Int'];
  pageNo: Scalars['Int'];
}>;


export type SupplierMessageBookingsByIdQuery = (
  { __typename?: 'Query' }
  & { GetBookingsByMessageId: (
    { __typename?: 'MessageBookingsPayload' }
    & Pick<MessageBookingsPayload, 'hasMoreBookings'>
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'message' | 'isRead' | 'archived' | 'thread' | 'isImportant' | 'hide' | 'createdAt'>
      & { from: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) | (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )>, stats: (
          { __typename?: 'SupplierStats' }
          & Pick<SupplierStats, 'reviewsCount' | 'rating'>
        ) }
      ), to: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) | (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, stats: (
          { __typename?: 'SupplierStats' }
          & Pick<SupplierStats, 'reviewsCount' | 'rating'>
        ), country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) }
    ), bookings: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'code' | 'title' | 'meetingDate' | 'meetingTime' | 'payoutStatus' | 'status' | 'duration' | 'supplierId' | 'adultsCount' | 'childrenCount' | 'infantCount'>
      & { city?: Maybe<(
        { __typename?: 'City' }
        & Pick<City, 'name'>
      )>, invoice: (
        { __typename?: 'BookingInvoice' }
        & Pick<BookingInvoice, 'supplierFee' | 'cardFee' | 'total' | 'currencyId'>
      ), currency: (
        { __typename?: 'Currency' }
        & Pick<Currency, 'id' | 'symbol' | 'minPrice'>
        & { quotes: Array<(
          { __typename?: 'Quote' }
          & Pick<Quote, 'currency' | 'rate'>
        )> }
      ), user: (
        { __typename?: 'User' }
        & Pick<User, 'displayName'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ), supplier?: Maybe<(
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName' | 'firstName' | 'isSupplier' | 'isSupplierApply' | 'isAdmin' | 'isBlogger' | 'phone' | 'phoneCountryCode' | 'payoutMethodStatus' | 'payoutMethodType' | 'payoutMethod'>
        & { stats: (
          { __typename?: 'SupplierStats' }
          & Pick<SupplierStats, 'bookingsCount'>
        ), picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      )> }
    )> }
  ) }
);

export type SupplierMessageByIdQueryVariables = Exact<{
  id: Scalars['Int'];
  pageNo: Scalars['Int'];
}>;


export type SupplierMessageByIdQuery = (
  { __typename?: 'Query' }
  & { GetMessageById: (
    { __typename?: 'MessagePayload' }
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'message' | 'isRead' | 'archived' | 'thread' | 'isImportant' | 'hide' | 'createdAt'>
      & { from: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) | (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )>, stats: (
          { __typename?: 'SupplierStats' }
          & Pick<SupplierStats, 'reviewsCount' | 'rating'>
        ) }
      ), to: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) | (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, stats: (
          { __typename?: 'SupplierStats' }
          & Pick<SupplierStats, 'reviewsCount' | 'rating'>
        ), country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) }
    ), messageThread: (
      { __typename?: 'MessageThread' }
      & Pick<MessageThread, 'isBooked' | 'totalCount' | 'isImportant' | 'isArchived' | 'hasMoreMessage'>
      & { messages: Array<(
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'message' | 'hide' | 'isRead' | 'archived' | 'thread' | 'isImportant' | 'createdAt'>
        & { tour?: Maybe<(
          { __typename?: 'Tour' }
          & Pick<Tour, 'id' | 'title' | 'duration' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )>, price: (
            { __typename?: 'TourPrice' }
            & Pick<TourPrice, 'amount' | 'model'>
            & { currency: (
              { __typename?: 'Currency' }
              & Pick<Currency, 'symbol' | 'id'>
              & { quotes: Array<(
                { __typename?: 'Quote' }
                & Pick<Quote, 'currency' | 'rate'>
              )> }
            ) }
          ) }
        )>, tourRequest?: Maybe<(
          { __typename?: 'TourRequest' }
          & Pick<TourRequest, 'meetingDate' | 'meetingTime' | 'accomodationAddress' | 'adultsCount' | 'infantCount' | 'childrenCount'>
          & { city?: Maybe<(
            { __typename?: 'City' }
            & Pick<City, 'id' | 'name'>
            & { country: (
              { __typename?: 'Country' }
              & Pick<Country, 'id' | 'name'>
            ) }
          )>, tour?: Maybe<(
            { __typename?: 'Tour' }
            & Pick<Tour, 'id' | 'title' | 'duration' | 'url'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )>, price: (
              { __typename?: 'TourPrice' }
              & Pick<TourPrice, 'amount' | 'model'>
              & { currency: (
                { __typename?: 'Currency' }
                & Pick<Currency, 'symbol' | 'id'>
                & { quotes: Array<(
                  { __typename?: 'Quote' }
                  & Pick<Quote, 'currency' | 'rate'>
                )> }
              ) }
            ) }
          )> }
        )>, booking?: Maybe<(
          { __typename?: 'Booking' }
          & Pick<Booking, 'id' | 'code' | 'title' | 'bookingType' | 'meetingDate' | 'meetingTime' | 'payoutStatus' | 'status' | 'duration' | 'supplierId' | 'adultsCount' | 'childrenCount' | 'infantCount'>
          & { city?: Maybe<(
            { __typename?: 'City' }
            & Pick<City, 'name'>
          )>, invoice: (
            { __typename?: 'BookingInvoice' }
            & Pick<BookingInvoice, 'supplierFee' | 'cardFee' | 'total' | 'currencyId'>
          ), currency: (
            { __typename?: 'Currency' }
            & Pick<Currency, 'id' | 'symbol' | 'minPrice'>
            & { quotes: Array<(
              { __typename?: 'Quote' }
              & Pick<Quote, 'currency' | 'rate'>
            )> }
          ), user: (
            { __typename?: 'User' }
            & Pick<User, 'displayName'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          ), supplier?: Maybe<(
            { __typename?: 'Supplier' }
            & Pick<Supplier, 'id' | 'displayName' | 'firstName' | 'isSupplier' | 'isSupplierApply' | 'isAdmin' | 'isBlogger' | 'phone' | 'phoneCountryCode' | 'payoutMethodStatus' | 'payoutMethodType' | 'payoutMethod'>
            & { stats: (
              { __typename?: 'SupplierStats' }
              & Pick<SupplierStats, 'bookingsCount'>
            ), picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          )> }
        )>, from: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ) | (
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )>, stats: (
            { __typename?: 'SupplierStats' }
            & Pick<SupplierStats, 'reviewsCount' | 'rating'>
          ) }
        ), to: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ) | (
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )>, stats: (
            { __typename?: 'SupplierStats' }
            & Pick<SupplierStats, 'reviewsCount' | 'rating'>
          ) }
        ) }
      )>, latestMessage: (
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'message' | 'isRead' | 'archived' | 'thread' | 'isImportant' | 'createdAt'>
      ) }
    ) }
  ) }
);

export type SupplierMessagesFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'phone' | 'url'>
  & { messages: (
    { __typename?: 'MessageConnection' }
    & Pick<MessageConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename?: 'MessageEdge' }
      & Pick<MessageEdge, 'cursor'>
      & { node: (
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'message' | 'isRead' | 'hide' | 'archived' | 'thread' | 'isImportant' | 'createdAt' | 'status'>
        & { from: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ) | (
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ), to: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ) | (
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'id' | 'displayName' | 'url'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ), messageThread: (
          { __typename?: 'MessageThread' }
          & Pick<MessageThread, 'isBooked' | 'totalCount' | 'isImportant' | 'isArchived'>
          & { latestMessage: (
            { __typename?: 'Message' }
            & Pick<Message, 'id' | 'message' | 'isRead' | 'archived' | 'thread' | 'isImportant' | 'createdAt'>
          ) }
        ) }
      ) }
    )> }
  ) }
);

export type SupplierMessagesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  inbox?: Maybe<MessageInbox>;
}>;


export type SupplierMessagesQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierMessagesFragment
  ) }
);

export type UpdateMessageMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UpdateMessageInput;
}>;


export type UpdateMessageMutation = (
  { __typename?: 'Mutation' }
  & { UpdateMessage: (
    { __typename?: 'UpdateMessagePayload' }
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id'>
    ) }
  ) }
);

export type UpdateMessageAsReadMutationVariables = Exact<{
  id: Scalars['Int'];
  isRead: Scalars['Boolean'];
}>;


export type UpdateMessageAsReadMutation = (
  { __typename?: 'Mutation' }
  & { UpdateMessageAsRead: (
    { __typename?: 'UpdateMessageAsReadPayload' }
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id'>
    ) }
  ) }
);

export type UpdateMessageThreadMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UpdateMessageThreadInput;
}>;


export type UpdateMessageThreadMutation = (
  { __typename?: 'Mutation' }
  & { UpdateMessageThread: (
    { __typename?: 'UpdateMessageThreadPayload' }
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id'>
    ) }
  ) }
);

export type UserNotificationQueryVariables = Exact<{ [key: string]: never; }>;


export type UserNotificationQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { customerNotification: (
      { __typename?: 'CustomerNotification' }
      & Pick<CustomerNotification, 'unReadMessagesCount'>
    ) }
  ) | (
    { __typename: 'Supplier' }
    & Pick<Supplier, 'id'>
    & { supplierNotification: (
      { __typename?: 'SupplierNotification' }
      & Pick<SupplierNotification, 'unReadMessagesCount' | 'paidBookingsCount' | 'reviewNotRepliedCount'>
    ) }
  ) }
);

export type CityTopPageQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type CityTopPageQuery = (
  { __typename?: 'Query' }
  & { cityTopPage: (
    { __typename?: 'CityTopPagePayload' }
    & Pick<CityTopPagePayload, 'hasMoreTours' | 'hasMoreSuppliers' | 'hasMoreTransportationTours' | 'hasMoreNewTours' | 'hasMoreNewSuppliers'>
    & { city: (
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'slug' | 'url'>
      & { seoContent: (
        { __typename?: 'SeoContentPayload' }
        & { top: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ), tours: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ), guides: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ), online: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ), transportation: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ) }
      ), picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url' | 'urlSM' | 'serpImage'>
      )>, country: (
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name' | 'slug' | 'url' | 'isActive'>
      ), relatedCities?: Maybe<Array<(
        { __typename?: 'City' }
        & CityCardFragment
      )>>, faqs?: Maybe<Array<(
        { __typename?: 'FaqContent' }
        & Pick<FaqContent, 'question' | 'answer'>
      )>>, stats: (
        { __typename?: 'LocationStats' }
        & Pick<LocationStats, 'reviewsCount' | 'reviewsRating'>
      ) }
      & CityFooterFragment
    ), tours: Array<(
      { __typename?: 'Tour' }
      & TourCardFragment
    )>, suppliers: Array<(
      { __typename?: 'Supplier' }
      & SupplierCardFragment
    )>, newTours: Array<(
      { __typename?: 'Tour' }
      & TourCardFragment
    )>, newSuppliers: Array<(
      { __typename?: 'Supplier' }
      & SupplierCardFragment
    )>, onlineTours: Array<(
      { __typename?: 'Tour' }
      & TourCardFragment
    )>, transportationTours: Array<(
      { __typename?: 'Tour' }
      & TourCardFragment
    )> }
  ) }
);

export type CountryTopPageQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type CountryTopPageQuery = (
  { __typename?: 'Query' }
  & { countryTopPage: (
    { __typename?: 'CountryTopPagePayload' }
    & Pick<CountryTopPagePayload, 'hasMoreTours' | 'hasMoreSuppliers' | 'hasMoreTransportationTours' | 'hasMoreNewTours' | 'hasMoreNewSuppliers' | 'hasMoreCities'>
    & { country: (
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name' | 'slug' | 'url' | 'isActive'>
      & { seoContent: (
        { __typename?: 'SeoContentPayload' }
        & { top: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ), tours: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ), guides: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ), online: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ), transportation: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ) }
      ), picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url' | 'urlSM' | 'serpImage'>
      )>, faqs?: Maybe<Array<(
        { __typename?: 'FaqContent' }
        & Pick<FaqContent, 'question' | 'answer'>
      )>>, stats: (
        { __typename?: 'LocationStats' }
        & Pick<LocationStats, 'reviewsCount' | 'reviewsRating'>
      ) }
      & CountryFooterFragment
    ), tours: Array<(
      { __typename?: 'Tour' }
      & TourCardFragment
    )>, suppliers: Array<(
      { __typename?: 'Supplier' }
      & SupplierCardFragment
    )>, newTours: Array<(
      { __typename?: 'Tour' }
      & TourCardFragment
    )>, newSuppliers: Array<(
      { __typename?: 'Supplier' }
      & SupplierCardFragment
    )>, cities: Array<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'slug' | 'url'>
      & { seoContent: (
        { __typename?: 'SeoContentPayload' }
        & { top: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ), tours: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ), guides: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ), online: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ), transportation: (
          { __typename?: 'SeoContent' }
          & Pick<SeoContent, 'title' | 'content'>
        ) }
      ), picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )>, country: (
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name' | 'slug' | 'url' | 'isActive'>
      ), relatedCities?: Maybe<Array<(
        { __typename?: 'City' }
        & CityCardFragment
      )>>, faqs?: Maybe<Array<(
        { __typename?: 'FaqContent' }
        & Pick<FaqContent, 'question' | 'answer'>
      )>>, stats: (
        { __typename?: 'LocationStats' }
        & Pick<LocationStats, 'reviewsCount' | 'reviewsRating'>
      ) }
    )>, onlineTours: Array<(
      { __typename?: 'Tour' }
      & TourCardFragment
    )>, transportationTours: Array<(
      { __typename?: 'Tour' }
      & TourCardFragment
    )> }
  ) }
);

export type TopPageQueryVariables = Exact<{ [key: string]: never; }>;


export type TopPageQuery = (
  { __typename?: 'Query' }
  & { topPage: (
    { __typename?: 'TopPagePayload' }
    & { tours: Array<(
      { __typename?: 'Tour' }
      & TourTopPageCardFragment
    )>, suppliers: Array<(
      { __typename?: 'Supplier' }
      & SupplierCardFragment
    )>, newTours: Array<(
      { __typename?: 'Tour' }
      & TourTopPageCardFragment
    )>, newSuppliers: Array<(
      { __typename?: 'Supplier' }
      & SupplierCardFragment
    )>, cars: Array<(
      { __typename?: 'Tour' }
      & TourTopPageCardFragment
    )>, countries: Array<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name' | 'slug' | 'url'>
      & { picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
    )>, citiesJapan: Array<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'slug' | 'url'>
      & { picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
      & CityFooterFragment
    )>, citiesOthers: Array<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'slug' | 'url'>
      & { picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
      & CityFooterFragment
    )>, stats: (
      { __typename?: 'StatsPayload' }
      & Pick<StatsPayload, 'reviewsCount'>
    ) }
  ) }
);

export type CompleteBlogCreationMutationVariables = Exact<{
  input: CompleteBlogCreationInput;
}>;


export type CompleteBlogCreationMutation = (
  { __typename?: 'Mutation' }
  & { CompleteBlogCreation: (
    { __typename?: 'CompleteBlogCreationPayload' }
    & { post: (
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'title'>
    ) }
  ) }
);

export type CreateBlogMutationVariables = Exact<{
  input: CreateBlogInput;
}>;


export type CreateBlogMutation = (
  { __typename?: 'Mutation' }
  & { CreateBlog: (
    { __typename?: 'CreateBlogPayload' }
    & { post: (
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'title'>
    ) }
  ) }
);

export type DeleteBlogMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteBlogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteBlog'>
);

export type IncrementPostViewsMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IncrementPostViewsMutation = (
  { __typename?: 'Mutation' }
  & { incrementPostViews: (
    { __typename?: 'IncrementPostViewsPayload' }
    & Pick<IncrementPostViewsPayload, 'isUpdated'>
  ) }
);

export type UserBlogContextFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'displayName'>
  & { blogs: (
    { __typename?: 'BlogsConnection' }
    & Pick<BlogsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor' | 'page' | 'totalPages'>
    ), edges: Array<(
      { __typename?: 'BlogsEdge' }
      & { node: (
        { __typename?: 'Post' }
        & Pick<Post, 'id' | 'slug' | 'isActive' | 'title' | 'userId' | 'countViews' | 'url' | 'overview' | 'content' | 'createdAt' | 'updatedAt'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, language?: Maybe<(
          { __typename?: 'Language' }
          & Pick<Language, 'id' | 'name'>
        )>, category?: Maybe<(
          { __typename?: 'Category' }
          & Pick<Category, 'id' | 'name'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name'>
        )>, city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'id' | 'name'>
        )> }
      ) }
    )> }
  ) }
);

export type SupplierBlogContextFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName'>
  & { blogs: (
    { __typename?: 'BlogsConnection' }
    & Pick<BlogsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor' | 'page' | 'totalPages'>
    ), edges: Array<(
      { __typename?: 'BlogsEdge' }
      & { node: (
        { __typename?: 'Post' }
        & Pick<Post, 'id' | 'slug' | 'isActive' | 'title' | 'userId' | 'countViews' | 'url' | 'overview' | 'content' | 'createdAt' | 'updatedAt'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, language?: Maybe<(
          { __typename?: 'Language' }
          & Pick<Language, 'id' | 'name'>
        )>, category?: Maybe<(
          { __typename?: 'Category' }
          & Pick<Category, 'id' | 'name'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name'>
        )>, city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'id' | 'name'>
        )> }
      ) }
    )> }
  ) }
);

export type MyBlogsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  filters?: Maybe<MyPostFilters>;
}>;


export type MyBlogsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & UserBlogContextFragment
  ) | (
    { __typename: 'Supplier' }
    & SupplierBlogContextFragment
  ) }
);

export type PostsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  random?: Maybe<Scalars['Int']>;
  filters?: Maybe<PostFilters>;
}>;


export type PostsQuery = (
  { __typename?: 'Query' }
  & { posts: (
    { __typename?: 'PostConnection' }
    & { edges: Array<(
      { __typename?: 'PostEdge' }
      & { node: (
        { __typename?: 'Post' }
        & PostCardFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ) }
  ) }
);

export type RelatedPostsQueryVariables = Exact<{
  postId: Scalars['Int'];
}>;


export type RelatedPostsQuery = (
  { __typename?: 'Query' }
  & { RelatedPosts: (
    { __typename?: 'RelatedPostsPayload' }
    & { post: Array<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'title' | 'url' | 'overview' | 'content' | 'isActive' | 'contentUpdatedAt'>
      & { picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'id' | 'url'>
      )>, category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name' | 'slug'>
      )>, language?: Maybe<(
        { __typename?: 'Language' }
        & Pick<Language, 'id' | 'name' | 'code'>
      )>, user: (
        { __typename?: 'User' }
        & UserCardFragment
      ) | (
        { __typename?: 'Supplier' }
        & SupplierCardFragment
      ), city?: Maybe<(
        { __typename?: 'City' }
        & CityCardFragment
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name' | 'slug'>
      )> }
    )> }
  ) }
);

export type UpdateBlogMutationVariables = Exact<{
  input: UpdateBlogInput;
  id: Scalars['Int'];
}>;


export type UpdateBlogMutation = (
  { __typename?: 'Mutation' }
  & { UpdateBlog: (
    { __typename?: 'UpdateBlogPayload' }
    & { post: (
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'title'>
    ) }
  ) }
);

export type PostByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PostByIdQuery = (
  { __typename?: 'Query' }
  & { postById?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'title' | 'url' | 'overview' | 'content' | 'isActive' | 'contentUpdatedAt'>
    & { picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'id' | 'url'>
    )>, category?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'slug'>
    )>, language?: Maybe<(
      { __typename?: 'Language' }
      & Pick<Language, 'id' | 'name' | 'code'>
    )>, user: (
      { __typename?: 'User' }
      & UserCardFragment
    ) | (
      { __typename?: 'Supplier' }
      & SupplierCardFragment
    ), city?: Maybe<(
      { __typename?: 'City' }
      & CityCardFragment
      & CityFooterFragment
    )>, country?: Maybe<(
      { __typename?: 'Country' }
      & CountryFooterFragment
    )> }
  )> }
);

export type GetDataToAboutUsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDataToAboutUsQuery = (
  { __typename?: 'Query' }
  & { GetDataToAboutUs: (
    { __typename?: 'AboutUsReport' }
    & Pick<AboutUsReport, 'totalReviews' | 'countriesWithGuides' | 'registeredGuides'>
  ) }
);

export type CreateReviewMutationVariables = Exact<{
  input: CreateReviewInput;
}>;


export type CreateReviewMutation = (
  { __typename?: 'Mutation' }
  & { CreateReview: (
    { __typename?: 'CreateReviewPayload' }
    & { review: (
      { __typename?: 'Review' }
      & Pick<Review, 'id' | 'title' | 'rating' | 'message' | 'response' | 'createdAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ), supplier: (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ) }
    ) }
  ) }
);

export type ReplyReviewMutationVariables = Exact<{
  input: ReplyReviewInput;
}>;


export type ReplyReviewMutation = (
  { __typename?: 'Mutation' }
  & { ReplyReview: (
    { __typename?: 'ReplyReviewPayload' }
    & { review: (
      { __typename?: 'Review' }
      & Pick<Review, 'id' | 'title' | 'rating' | 'message' | 'response' | 'createdAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ), supplier: (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ) }
    ) }
  ) }
);

export type ReviewsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filters?: Maybe<ReviewFilters>;
}>;


export type ReviewsQuery = (
  { __typename?: 'Query' }
  & { reviews: (
    { __typename?: 'ReviewConnection' }
    & { edges: Array<(
      { __typename?: 'ReviewEdge' }
      & { node: (
        { __typename?: 'Review' }
        & ReviewCardFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ) }
  ) }
);

export type ReviewsInTopPageQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filters?: Maybe<ReviewFilters>;
}>;


export type ReviewsInTopPageQuery = (
  { __typename?: 'Query' }
  & { reviews: (
    { __typename?: 'ReviewConnection' }
    & { edges: Array<(
      { __typename?: 'ReviewEdge' }
      & { node: (
        { __typename?: 'Review' }
        & ReviewCardFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoTopPageFragment
    ) }
  ) }
);

export type UpdateReviewPhotoFlagMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UpdateReviewPhotoFlagMutation = (
  { __typename?: 'Mutation' }
  & { updateReviewPhotoFlag: (
    { __typename?: 'Review' }
    & Pick<Review, 'id'>
  ) }
);

export type SearchPageTabsDataQueryVariables = Exact<{
  filters: SearchFilters;
}>;


export type SearchPageTabsDataQuery = (
  { __typename?: 'Query' }
  & { searchPageTabsData: (
    { __typename?: 'Search' }
    & Pick<Search, 'hasSupplier' | 'hasTour' | 'hasVirtualTour' | 'hasCar'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name' | 'slug' | 'url'>
    )>, city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'slug' | 'url'>
    )> }
  ) }
);

export type GetEnviromentInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEnviromentInfoQuery = (
  { __typename?: 'Query' }
  & { getEnviromentInfo: (
    { __typename?: 'EnviromentType' }
    & { database: (
      { __typename?: 'DatabaseInfoType' }
      & Pick<DatabaseInfoType, 'name' | 'host'>
    ), api: (
      { __typename?: 'ApiInfoType' }
      & Pick<ApiInfoType, 'enviroment'>
    ) }
  ) }
);

export type SitemapBlogWritersQueryVariables = Exact<{ [key: string]: never; }>;


export type SitemapBlogWritersQuery = (
  { __typename?: 'Query' }
  & { sitemap: (
    { __typename?: 'Sitemap' }
    & { authors: Array<(
      { __typename?: 'SitemapItem' }
      & Pick<SitemapItem, 'url' | 'lastMod'>
    )> }
  ) }
);

export type SitemapDestinationsQueryVariables = Exact<{ [key: string]: never; }>;


export type SitemapDestinationsQuery = (
  { __typename?: 'Query' }
  & { sitemap: (
    { __typename?: 'Sitemap' }
    & { destinations: Array<(
      { __typename?: 'SitemapItem' }
      & Pick<SitemapItem, 'url' | 'lastMod'>
    )> }
  ) }
);

export type SitemapPostsQueryVariables = Exact<{ [key: string]: never; }>;


export type SitemapPostsQuery = (
  { __typename?: 'Query' }
  & { sitemap: (
    { __typename?: 'Sitemap' }
    & { blogs: Array<(
      { __typename?: 'SitemapItem' }
      & Pick<SitemapItem, 'url' | 'lastMod'>
    )> }
  ) }
);

export type SitemapSuppliersQueryVariables = Exact<{ [key: string]: never; }>;


export type SitemapSuppliersQuery = (
  { __typename?: 'Query' }
  & { sitemap: (
    { __typename?: 'Sitemap' }
    & { suppliers: Array<(
      { __typename?: 'SitemapItem' }
      & Pick<SitemapItem, 'url' | 'lastMod'>
    )> }
  ) }
);

export type SitemapToursQueryVariables = Exact<{ [key: string]: never; }>;


export type SitemapToursQuery = (
  { __typename?: 'Query' }
  & { sitemap: (
    { __typename?: 'Sitemap' }
    & { tours: Array<(
      { __typename?: 'SitemapItem' }
      & Pick<SitemapItem, 'url' | 'lastMod'>
    )> }
  ) }
);

export type StatsQueryVariables = Exact<{
  filters?: Maybe<StatsFilters>;
}>;


export type StatsQuery = (
  { __typename?: 'Query' }
  & { stats: (
    { __typename?: 'StatsPayload' }
    & Pick<StatsPayload, 'reviewsCount'>
  ) }
);

export type MeSupplierAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type MeSupplierAccountQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id'>
  ) | (
    { __typename: 'Supplier' }
    & Pick<Supplier, 'id' | 'firstName' | 'lastName' | 'email' | 'isPersonalInfoUpdated' | 'isPasswordUpdated' | 'isBiographyUpdated' | 'isProfileVideoUpdated' | 'isWorkingAreasUpdated' | 'isLanguagesUpdated' | 'isGlobalPreferenceUpdated' | 'isBookingConditionsUpdated' | 'isPaymentsUpdated' | 'pictureId'>
    & { gallery?: Maybe<Array<(
      { __typename?: 'Media' }
      & Pick<Media, 'id' | 'url' | 'caption' | 'position'>
    )>> }
  ) }
);

export type MeSupplierAccountBiographyInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type MeSupplierAccountBiographyInfoQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id' | 'biography'>
  ) | (
    { __typename: 'Supplier' }
    & Pick<Supplier, 'id' | 'biography'>
  ) }
);

export type MeSupplierAccountBookingConditionsQueryVariables = Exact<{ [key: string]: never; }>;


export type MeSupplierAccountBookingConditionsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id'>
  ) | (
    { __typename: 'Supplier' }
    & Pick<Supplier, 'id' | 'maxAllowedPeople' | 'important' | 'guideBookingNotice' | 'guideBookingWindow'>
    & { workingDays?: Maybe<(
      { __typename?: 'SupplierWorkingDays' }
      & Pick<SupplierWorkingDays, 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'>
    )>, analytics: (
      { __typename?: 'SupplierAnalytics' }
      & Pick<SupplierAnalytics, 'conversionRate'>
    ) }
  ) }
);

export type MeSupplierAccountLanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type MeSupplierAccountLanguagesQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id'>
  ) | (
    { __typename: 'Supplier' }
    & Pick<Supplier, 'id'>
    & { languages: Array<(
      { __typename?: 'SupplierLanguage' }
      & Pick<SupplierLanguage, 'level'>
      & { language: (
        { __typename?: 'Language' }
        & Pick<Language, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type MeSupplierAccountPersonalInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type MeSupplierAccountPersonalInfoQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id'>
  ) | (
    { __typename: 'Supplier' }
    & Pick<Supplier, 'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'gender' | 'birthday' | 'phoneCountryCode' | 'isSupplier' | 'address' | 'postalCode' | 'isSocialLoginEnabled' | 'biography'>
    & { nationality?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    )>, country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    )>, city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name'>
    )> }
  ) }
);

export type MeSupplierAccountVideoQueryVariables = Exact<{ [key: string]: never; }>;


export type MeSupplierAccountVideoQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id'>
  ) | (
    { __typename: 'Supplier' }
    & Pick<Supplier, 'id' | 'video'>
  ) }
);

export type MeSupplierAccountWorkingAreasQueryVariables = Exact<{ [key: string]: never; }>;


export type MeSupplierAccountWorkingAreasQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id'>
  ) | (
    { __typename: 'Supplier' }
    & Pick<Supplier, 'id'>
    & { workingCountries?: Maybe<Array<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    )>>, cities: Array<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'url' | 'countryId'>
    )> }
  ) }
);

export type MeUserAccountProfilePictureInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type MeUserAccountProfilePictureInfoQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & Pick<User, 'id' | 'isSupplier'>
    & { picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) | (
    { __typename: 'Supplier' }
    & Pick<Supplier, 'isSupplier'>
    & SupplierCardFragment
  ) }
);

export type SupplierRequestChangeMutationVariables = Exact<{
  input: SupplierRequestChangeInput;
}>;


export type SupplierRequestChangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SupplierRequestChange'>
);

export type TagBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type TagBySlugQuery = (
  { __typename?: 'Query' }
  & { tagBySlug?: Maybe<(
    { __typename?: 'Tags' }
    & Pick<Tags, 'id' | 'name' | 'slug'>
  )> }
);

export type CreateMessageTemplateMutationVariables = Exact<{
  input: CreateMessageTemplateInput;
}>;


export type CreateMessageTemplateMutation = (
  { __typename?: 'Mutation' }
  & { CreateMessageTemplate: (
    { __typename?: 'CreateMessageTemplatePayload' }
    & { messageTemplate: (
      { __typename?: 'MessageTemplate' }
      & Pick<MessageTemplate, 'id'>
    ) }
  ) }
);

export type DeleteMessageTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteMessageTemplateMutation = (
  { __typename?: 'Mutation' }
  & { DeleteMessageTemplate: (
    { __typename?: 'DeleteMessageTemplatePayload' }
    & Pick<DeleteMessageTemplatePayload, 'success'>
  ) }
);

export type SupplierMessageTemplateByIdFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'phone' | 'url'>
  & { messageTemplateById: (
    { __typename?: 'MessageTemplate' }
    & Pick<MessageTemplate, 'id' | 'content' | 'title' | 'status' | 'userId' | 'order'>
  ) }
);

export type SupplierMessageTemplateByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SupplierMessageTemplateByIdQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierMessageTemplateByIdFragment
  ) }
);

export type SupplierMessageTemplatesFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'phone' | 'url'>
  & { messageTemplates: Array<(
    { __typename?: 'MessageTemplate' }
    & Pick<MessageTemplate, 'id' | 'content' | 'title' | 'status' | 'userId'>
  )> }
);

export type SupplierMessageTemplatesQueryVariables = Exact<{
  isActive?: Maybe<Scalars['Boolean']>;
}>;


export type SupplierMessageTemplatesQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierMessageTemplatesFragment
  ) }
);

export type UpdateMessageTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UpdateMessageTemplateInput;
}>;


export type UpdateMessageTemplateMutation = (
  { __typename?: 'Mutation' }
  & { UpdateMessageTemplate: (
    { __typename?: 'UpdateMessageTemplatePayload' }
    & { messageTemplate: (
      { __typename?: 'MessageTemplate' }
      & Pick<MessageTemplate, 'id'>
    ) }
  ) }
);

export type CreateTourMutationVariables = Exact<{
  input: CreateTourInput;
}>;


export type CreateTourMutation = (
  { __typename?: 'Mutation' }
  & { CreateTour: (
    { __typename?: 'CreateTourPayload' }
    & Pick<CreateTourPayload, 'success'>
    & { tour: (
      { __typename?: 'Tour' }
      & Pick<Tour, 'id'>
    ) }
  ) }
);

export type DeleteTourMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTourMutation = (
  { __typename?: 'Mutation' }
  & { DeleteTour: (
    { __typename?: 'DeleteTourPayload' }
    & Pick<DeleteTourPayload, 'success'>
  ) }
);

export type DuplicateTourMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DuplicateTourMutation = (
  { __typename?: 'Mutation' }
  & { DuplicateTour: (
    { __typename?: 'DuplicateTourPayload' }
    & Pick<DuplicateTourPayload, 'success'>
    & { tour: (
      { __typename?: 'Tour' }
      & Pick<Tour, 'id'>
    ) }
  ) }
);

export type InactiveTourMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type InactiveTourMutation = (
  { __typename?: 'Mutation' }
  & { InactiveTour: (
    { __typename?: 'InactiveTourPayload' }
    & Pick<InactiveTourPayload, 'success'>
    & { tour: (
      { __typename?: 'Tour' }
      & Pick<Tour, 'id' | 'status'>
    ) }
  ) }
);

export type IncrementTourViewsMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IncrementTourViewsMutation = (
  { __typename?: 'Mutation' }
  & { incrementTourViews: (
    { __typename?: 'IncrementTourViewsPayload' }
    & Pick<IncrementTourViewsPayload, 'isUpdated'>
  ) }
);

export type RecommendedDataByBookingIdQueryVariables = Exact<{
  bookingId: Scalars['Int'];
}>;


export type RecommendedDataByBookingIdQuery = (
  { __typename?: 'Query' }
  & { recommendedDataByBookingId: (
    { __typename?: 'RecommendedDataByBookingIdPayload' }
    & Pick<RecommendedDataByBookingIdPayload, 'hasMoreCities'>
    & { tours: Array<(
      { __typename?: 'Tour' }
      & TourCardFragment
    )>, countries: Array<(
      { __typename?: 'Country' }
      & CountryCardFragment
    )>, cities: Array<(
      { __typename?: 'City' }
      & CityCardFragment
    )>, country: (
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name' | 'url'>
    ) }
  ) }
);

export type RelatedToursByPostQueryVariables = Exact<{
  postId: Scalars['Int'];
}>;


export type RelatedToursByPostQuery = (
  { __typename?: 'Query' }
  & { RelatedToursByPost: (
    { __typename?: 'RelatedToursByPostPayload' }
    & { tours: Array<(
      { __typename?: 'Tour' }
      & Pick<Tour, 'id' | 'title' | 'productType' | 'url' | 'status' | 'meetingTime' | 'meetingPoint' | 'endingPoint' | 'duration' | 'video' | 'included' | 'notIncluded' | 'important' | 'conditions' | 'overview' | 'content' | 'hasPriceRules'>
      & { supplier: (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'isBan' | 'important'>
        & { languages: Array<(
          { __typename?: 'SupplierLanguage' }
          & Pick<SupplierLanguage, 'level'>
          & { language: (
            { __typename?: 'Language' }
            & Pick<Language, 'id' | 'name'>
          ) }
        )> }
        & SupplierCardFragment
      ), category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name' | 'slug'>
      )>, country: (
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name' | 'slug' | 'url'>
      ), city: (
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name' | 'slug' | 'url'>
      ), people: (
        { __typename?: 'TourPeople' }
        & Pick<TourPeople, 'min' | 'max'>
      ), language?: Maybe<(
        { __typename?: 'Language' }
        & Pick<Language, 'id' | 'name'>
      )>, picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url' | 'caption'>
        & { cover: Media['url'] }
      )>, gallery?: Maybe<Array<(
        { __typename?: 'Media' }
        & Pick<Media, 'caption'>
        & { src: Media['url'] }
      )>>, cancelPolicyRules: Array<(
        { __typename?: 'CancelPolicyRule' }
        & Pick<CancelPolicyRule, 'from' | 'to' | 'charge'>
      )>, itinerary: Array<(
        { __typename?: 'TourItinerary' }
        & Pick<TourItinerary, 'type' | 'time' | 'title' | 'description'>
      )>, calendar: (
        { __typename?: 'Calendar' }
        & Pick<Calendar, 'minDate' | 'maxDate' | 'workingDates' | 'notWorkingDates' | 'daysOfWeekAvailable' | 'daysOfWeekBlocked' | 'bookingsDates'>
      ), price: (
        { __typename?: 'TourPrice' }
        & Pick<TourPrice, 'amount' | 'model'>
        & { currency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'id'>
          & { quotes: Array<(
            { __typename?: 'Quote' }
            & Pick<Quote, 'currency' | 'rate'>
          )> }
        ) }
      ), faqs?: Maybe<Array<(
        { __typename?: 'TourFaq' }
        & Pick<TourFaq, 'title' | 'content'>
      )>> }
    )> }
  ) }
);

export type SubmitTourMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SubmitTourMutation = (
  { __typename?: 'Mutation' }
  & { SubmitTour: (
    { __typename?: 'SubmitTourPayload' }
    & Pick<SubmitTourPayload, 'success'>
    & { tour: (
      { __typename?: 'Tour' }
      & Pick<Tour, 'id'>
    ) }
  ) }
);

export type SupplierTourByIdFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'payoutMethod' | 'phone' | 'url'>
  & { tourById: (
    { __typename?: 'Tour' }
    & Pick<Tour, 'id' | 'title' | 'productType' | 'supplierId' | 'duration' | 'status' | 'priceModel' | 'hasPriceRules' | 'slug' | 'overview' | 'countViews' | 'countBookings' | 'childrenPriceRule' | 'infantPriceRule'>
    & { language?: Maybe<(
      { __typename?: 'Language' }
      & Pick<Language, 'id' | 'name' | 'code'>
    )>, country: (
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name' | 'slug'>
    ), city: (
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'slug'>
    ), picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )>, price: (
      { __typename?: 'TourPrice' }
      & Pick<TourPrice, 'amount' | 'model'>
      & { currency: (
        { __typename?: 'Currency' }
        & Pick<Currency, 'id' | 'symbol'>
        & { quotes: Array<(
          { __typename?: 'Quote' }
          & Pick<Quote, 'currency' | 'rate'>
        )> }
      ) }
    ), supplier: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'displayName'>
      & { picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )>, stats: (
        { __typename?: 'SupplierStats' }
        & Pick<SupplierStats, 'reviewsCount' | 'rating'>
      ) }
    ), priceRules: Array<(
      { __typename?: 'PriceRules' }
      & Pick<PriceRules, 'people' | 'price'>
    )> }
  ) }
);

export type SupplierTourrByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SupplierTourrByIdQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierTourByIdFragment
  ) }
);

export type SupplierToursFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'payoutMethod' | 'phone' | 'url'>
  & { supplierTourBadges: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'hex_color_code'>
    & { picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )>, stats: (
      { __typename?: 'CategoryStatsPayload' }
      & Pick<CategoryStatsPayload, 'tourCount'>
    ) }
  )>, supplierTours: (
    { __typename?: 'ToursConnection' }
    & Pick<ToursConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor' | 'page'>
    ), edges: Array<(
      { __typename?: 'ToursEdge' }
      & Pick<ToursEdge, 'cursor'>
      & { node: (
        { __typename?: 'Tour' }
        & Pick<Tour, 'id' | 'title' | 'productType' | 'supplierId' | 'duration' | 'status' | 'priceModel' | 'hasPriceRules' | 'slug' | 'overview' | 'countViews' | 'countBookings' | 'url'>
        & { category?: Maybe<(
          { __typename?: 'Category' }
          & Pick<Category, 'id' | 'name' | 'hex_color_code'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        )>, language?: Maybe<(
          { __typename?: 'Language' }
          & Pick<Language, 'id' | 'name' | 'code'>
        )>, country: (
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name' | 'slug'>
        ), city: (
          { __typename?: 'City' }
          & Pick<City, 'id' | 'name' | 'slug'>
        ), picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, price: (
          { __typename?: 'TourPrice' }
          & Pick<TourPrice, 'amount' | 'model'>
          & { currency: (
            { __typename?: 'Currency' }
            & Pick<Currency, 'id' | 'symbol'>
            & { quotes: Array<(
              { __typename?: 'Quote' }
              & Pick<Quote, 'currency' | 'rate'>
            )> }
          ) }
        ) }
      ) }
    )> }
  ) }
);

export type SupplierToursQueryVariables = Exact<{
  sizePerPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  filters?: Maybe<SupplierTourFilters>;
  badgeFilters?: Maybe<SupplierTourBadgesFilters>;
  categoryStatsFilter?: Maybe<CategoryStatsFilters>;
}>;


export type SupplierToursQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierToursFragment
  ) }
);

export type TourByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TourByIdQuery = (
  { __typename?: 'Query' }
  & { tourById?: Maybe<(
    { __typename?: 'Tour' }
    & Pick<Tour, 'id' | 'title' | 'productType' | 'url' | 'status' | 'meetingTime' | 'meetingPoint' | 'meetingPointDescription' | 'endingPoint' | 'timeFrom' | 'availability' | 'isAvailabilityUpdated' | 'isActive' | 'duration' | 'video' | 'included' | 'notIncluded' | 'important' | 'conditions' | 'overview' | 'content' | 'hasPriceRules' | 'childrenPriceRule' | 'infantPriceRule'>
    & { supplier: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'isBan' | 'important' | 'isCar' | 'createdAt'>
      & { languages: Array<(
        { __typename?: 'SupplierLanguage' }
        & Pick<SupplierLanguage, 'level'>
        & { language: (
          { __typename?: 'Language' }
          & Pick<Language, 'id' | 'name'>
        ) }
      )> }
      & SupplierCardFragment
    ), category?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'slug' | 'hex_color_code'>
      & { picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
    )>, country: (
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name' | 'slug' | 'url' | 'isActive'>
    ), city: (
      { __typename?: 'City' }
      & CityFooterFragment
    ), people: (
      { __typename?: 'TourPeople' }
      & Pick<TourPeople, 'min' | 'max'>
    ), language?: Maybe<(
      { __typename?: 'Language' }
      & Pick<Language, 'id' | 'name'>
    )>, picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url' | 'caption' | 'serpImage'>
      & { cover: Media['url'], mobileUrl: Media['url'] }
    )>, galleryMD?: Maybe<Array<(
      { __typename?: 'Media' }
      & Pick<Media, 'serpImage' | 'caption' | 'id' | 'position'>
      & { src: Media['url'] }
    )>>, galleryOriginal?: Maybe<Array<(
      { __typename?: 'Media' }
      & Pick<Media, 'serpImage' | 'caption' | 'id' | 'position'>
      & { src: Media['url'] }
    )>>, cancelPolicyRules: Array<(
      { __typename?: 'CancelPolicyRule' }
      & Pick<CancelPolicyRule, 'from' | 'to' | 'charge'>
    )>, itinerary: Array<(
      { __typename?: 'TourItinerary' }
      & Pick<TourItinerary, 'type' | 'time' | 'title' | 'description'>
    )>, calendar: (
      { __typename?: 'Calendar' }
      & Pick<Calendar, 'minDate' | 'maxDate' | 'workingDates' | 'notWorkingDates' | 'daysOfWeekAvailable' | 'daysOfWeekBlocked' | 'bookingsDates'>
    ), tourSpecificCalendar: (
      { __typename?: 'Calendar' }
      & Pick<Calendar, 'minDate' | 'maxDate' | 'workingDates' | 'notWorkingDates' | 'daysOfWeekAvailable' | 'daysOfWeekBlocked' | 'bookingsDates'>
    ), price: (
      { __typename?: 'TourPrice' }
      & Pick<TourPrice, 'amount' | 'model'>
      & { currency: (
        { __typename?: 'Currency' }
        & Pick<Currency, 'id'>
        & { quotes: Array<(
          { __typename?: 'Quote' }
          & Pick<Quote, 'currency' | 'rate'>
        )> }
      ) }
    ), faqs?: Maybe<Array<(
      { __typename?: 'TourFaq' }
      & Pick<TourFaq, 'title' | 'content'>
    )>>, priceRules: Array<(
      { __typename?: 'PriceRules' }
      & Pick<PriceRules, 'people' | 'price'>
    )> }
  )> }
);

export type TourMessagePageFragment = (
  { __typename?: 'Tour' }
  & { people: (
    { __typename?: 'TourPeople' }
    & Pick<TourPeople, 'min' | 'max'>
  ), calendar: (
    { __typename?: 'Calendar' }
    & Pick<Calendar, 'minDate' | 'maxDate' | 'workingDates' | 'notWorkingDates' | 'daysOfWeekAvailable' | 'daysOfWeekBlocked' | 'bookingsDates'>
  ) }
  & TourCardFragment
);

export type TourByIdTourPreviewQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TourByIdTourPreviewQuery = (
  { __typename?: 'Query' }
  & { tourById?: Maybe<(
    { __typename?: 'Tour' }
    & TourMessagePageFragment
  )> }
);

export type TourCalendarQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TourCalendarQuery = (
  { __typename?: 'Query' }
  & { tourCalendar?: Maybe<(
    { __typename?: 'TourCalendarPayload' }
    & { calendar: (
      { __typename?: 'Calendar' }
      & Pick<Calendar, 'minDate' | 'maxDate' | 'workingDates' | 'notWorkingDates' | 'daysOfWeekAvailable' | 'daysOfWeekBlocked' | 'bookingsDates'>
    ) }
  )> }
);

export type TourPriceQueryVariables = Exact<{
  id: Scalars['Int'];
  input: TourPriceInput;
}>;


export type TourPriceQuery = (
  { __typename?: 'Query' }
  & { tourPrice?: Maybe<(
    { __typename?: 'TourPrice' }
    & Pick<TourPrice, 'amount' | 'model'>
    & { currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'id'>
      & { quotes: Array<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'currency' | 'rate'>
      )> }
    ) }
  )> }
);

export type TourScarcityQueryVariables = Exact<{
  id: Scalars['Int'];
  input: TourScarCityInput;
}>;


export type TourScarcityQuery = (
  { __typename?: 'Query' }
  & { tourScarcity?: Maybe<(
    { __typename?: 'TourScarCityPayload' }
    & Pick<TourScarCityPayload, 'slot' | 'month'>
  )> }
);

export type ToursQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  filters?: Maybe<TourFilters>;
}>;


export type ToursQuery = (
  { __typename?: 'Query' }
  & { tours: (
    { __typename?: 'TourConnection' }
    & Pick<TourConnection, 'totalCount'>
    & { edges: Array<(
      { __typename?: 'TourEdge' }
      & { node: (
        { __typename?: 'Tour' }
        & TourCardFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ) }
  ) }
);

export type UpdateTourMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UpdateTourInput;
}>;


export type UpdateTourMutation = (
  { __typename?: 'Mutation' }
  & { UpdateTour: (
    { __typename?: 'UpdateTourPayload' }
    & Pick<UpdateTourPayload, 'success'>
    & { tour: (
      { __typename?: 'Tour' }
      & Pick<Tour, 'id'>
    ) }
  ) }
);

export type AuthenticateMutationVariables = Exact<{
  input: AuthenticateInput;
}>;


export type AuthenticateMutation = (
  { __typename?: 'Mutation' }
  & { Authenticate: (
    { __typename?: 'AuthenticatePayload' }
    & Pick<AuthenticatePayload, 'token'>
  ) }
);

export type AuthenticateWithFacebookMutationVariables = Exact<{
  input: AuthenticateWithFacebookInput;
}>;


export type AuthenticateWithFacebookMutation = (
  { __typename?: 'Mutation' }
  & { AuthenticateWithFacebook: (
    { __typename?: 'AuthenticateWithFacebookPayload' }
    & Pick<AuthenticateWithFacebookPayload, 'token'>
  ) }
);

export type AuthenticateWithGoogleMutationVariables = Exact<{
  input: AuthenticateWithGoogleInput;
}>;


export type AuthenticateWithGoogleMutation = (
  { __typename?: 'Mutation' }
  & { AuthenticateWithGoogle: (
    { __typename?: 'AuthenticateWithGooglePayload' }
    & Pick<AuthenticateWithGooglePayload, 'token'>
  ) }
);

export type BlogWriterByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type BlogWriterByIdQuery = (
  { __typename?: 'Query' }
  & { blogWriterById?: Maybe<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'displayName' | 'firstName' | 'lastName' | 'isBan' | 'biography' | 'isBlogger' | 'isSupplier'>
    & { picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url' | 'serpImage'>
      & { cover: Media['url'] }
    )> }
  )> }
);

export type ChangeUserPasswordByTokenMutationVariables = Exact<{
  input: ChangeUserPasswordByTokenInput;
}>;


export type ChangeUserPasswordByTokenMutation = (
  { __typename?: 'Mutation' }
  & { ChangeUserPasswordByToken: (
    { __typename?: 'ChangeUserPasswordByTokenPayload' }
    & Pick<ChangeUserPasswordByTokenPayload, 'token'>
  ) }
);

export type CloseCalendarDateMutationVariables = Exact<{
  date: Scalars['String'];
}>;


export type CloseCalendarDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CloseCalendarDate'>
);

export type ContactMutationVariables = Exact<{
  input: ContactInput;
}>;


export type ContactMutation = (
  { __typename?: 'Mutation' }
  & { Contact: (
    { __typename?: 'ContactPayload' }
    & Pick<ContactPayload, 'isSent'>
  ) }
);

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { CreateUser: (
    { __typename?: 'CreateUserPayload' }
    & Pick<CreateUserPayload, 'token'>
  ) }
);

export type OpenCalendarDateMutationVariables = Exact<{
  date: Scalars['String'];
  forceToOpen?: Maybe<Scalars['Boolean']>;
}>;


export type OpenCalendarDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OpenCalendarDate'>
);

export type RecoverUserPasswordMutationVariables = Exact<{
  input: RecoverUserPasswordInput;
}>;


export type RecoverUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { RecoverUserPassword: (
    { __typename?: 'RecoverUserPasswordPayload' }
    & Pick<RecoverUserPasswordPayload, 'isSent'>
  ) }
);

export type RemoveSupplierPayPalMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveSupplierPayPalMutation = (
  { __typename?: 'Mutation' }
  & { RemoveSupplierPayPal: (
    { __typename?: 'RemoveSupplierPayPalPayload' }
    & { supplier: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'displayName' | 'payoutMethod'>
      & { paypal?: Maybe<(
        { __typename?: 'SupplierPaypal' }
        & Pick<SupplierPaypal, 'id' | 'name' | 'email'>
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name' | 'url'>
      )> }
    ) }
  ) }
);

export type SupplierBookingFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName'>
  & { country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id'>
  )>, payments?: Maybe<(
    { __typename?: 'PaymentsPayload' }
    & { payments: Array<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'bookingsCount' | 'period' | 'supplierFee' | 'currencyId'>
    )> }
  )>, bookingsAsSupplier: (
    { __typename?: 'BookingsConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'endCursor' | 'startCursor'>
    ), edges: Array<(
      { __typename?: 'BookingsEdge' }
      & { node: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'code' | 'title' | 'meetingDate' | 'meetingTime' | 'payoutStatus' | 'status' | 'duration' | 'supplierId' | 'adultsCount' | 'childrenCount' | 'infantCount'>
        & { city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name'>
        )>, invoice: (
          { __typename?: 'BookingInvoice' }
          & Pick<BookingInvoice, 'supplierFee' | 'currencyId'>
        ), user: (
          { __typename?: 'User' }
          & Pick<User, 'displayName'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ) }
      ) }
    )> }
  ) }
);

export type SupplierBookingsForPeriodQueryVariables = Exact<{
  bookingFilter?: Maybe<BookingFilters>;
  paymentFilter?: Maybe<PaymentFilters>;
}>;


export type SupplierBookingsForPeriodQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierBookingFragment
  ) }
);

export type SupplierAllToursToRefreshCacheQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SupplierAllToursToRefreshCacheQuery = (
  { __typename?: 'Query' }
  & { supplierById?: Maybe<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'displayName'>
    & { allTours: Array<(
      { __typename?: 'Tour' }
      & Pick<Tour, 'id' | 'url'>
    )> }
  )> }
);

export type SupplierAnalyticsQueryVariables = Exact<{ [key: string]: never; }>;


export type SupplierAnalyticsQuery = (
  { __typename?: 'Query' }
  & { me: { __typename?: 'User' } | (
    { __typename?: 'Supplier' }
    & { analytics: (
      { __typename?: 'SupplierAnalytics' }
      & Pick<SupplierAnalytics, 'conversionRate' | 'calendarUpdate' | 'responseTime' | 'profileVideo'>
    ) }
  ) }
);

export type SupplierAnalyticsByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SupplierAnalyticsByIdQuery = (
  { __typename?: 'Query' }
  & { supplierById?: Maybe<(
    { __typename?: 'Supplier' }
    & { analytics: (
      { __typename?: 'SupplierAnalytics' }
      & Pick<SupplierAnalytics, 'conversionRate' | 'calendarUpdate' | 'responseTime' | 'profileVideo'>
    ) }
  )> }
);

export type SupplierApplyMutationVariables = Exact<{
  input: SupplierApplyInput;
}>;


export type SupplierApplyMutation = (
  { __typename?: 'Mutation' }
  & { SupplierApply: (
    { __typename?: 'SupplierApplyPayload' }
    & { supplier: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'firstName' | 'lastName' | 'email' | 'birthday' | 'gender'>
      & { nationality?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name'>
      )> }
    ) }
  ) }
);

export type SupplierBookingIdFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'payoutMethod'>
  & { bookingById: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'payoutMethod' | 'code' | 'id' | 'title' | 'meetingDate' | 'meetingTime' | 'status' | 'supplierId' | 'duration' | 'adultsCount' | 'childrenCount' | 'infantCount' | 'refundPercent'>
    & { city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'name'>
    )>, invoice: (
      { __typename?: 'BookingInvoice' }
      & Pick<BookingInvoice, 'supplierFee' | 'cardFee' | 'total' | 'serviceFee' | 'currencyId'>
    ), user: (
      { __typename?: 'User' }
      & Pick<User, 'displayName'>
      & { picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
    ), payout: (
      { __typename?: 'BookingPayout' }
      & Pick<BookingPayout, 'method' | 'amount' | 'payoutAt' | 'status' | 'transactionId' | 'error'>
    ), review?: Maybe<(
      { __typename?: 'Review' }
      & Pick<Review, 'title' | 'id' | 'rating' | 'message'>
      & { photos: Array<(
        { __typename?: 'Media' }
        & Pick<Media, 'id' | 'urlXS' | 'urlLG'>
      )> }
    )>, items: Array<(
      { __typename?: 'BookingItem' }
      & Pick<BookingItem, 'id' | 'description' | 'quantity' | 'unitPrice' | 'totalPrice' | 'type'>
    )> }
  ) }
);

export type SupplierBookingByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SupplierBookingByIdQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierBookingIdFragment
  ) }
);

export type SimpleTourFragment = (
  { __typename?: 'Tour' }
  & Pick<Tour, 'id' | 'title' | 'duration'>
);

export type SupplierByIdQueryVariables = Exact<{
  id: Scalars['Int'];
  badgeFilters?: Maybe<SupplierTourBadgesFilters>;
}>;


export type SupplierByIdQuery = (
  { __typename?: 'Query' }
  & { supplierById?: Maybe<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'isBan' | 'displayName' | 'biography' | 'responseTime' | 'responseTimeMessage' | 'calendarUpdatedAt' | 'maxAllowedPeople' | 'url' | 'isCar' | 'important' | 'isOnline' | 'createdAt' | 'video' | 'guideBookingNotice' | 'guideBookingWindow'>
    & { calendar: (
      { __typename?: 'Calendar' }
      & Pick<Calendar, 'minDate' | 'maxDate' | 'workingDates' | 'notWorkingDates' | 'daysOfWeekAvailable' | 'daysOfWeekBlocked' | 'bookingsDates'>
    ), supplierTourBadges: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'hex_color_code'>
      & { picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
    )>, tours: Array<(
      { __typename?: 'Tour' }
      & SimpleTourFragment
    )>, picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url' | 'serpImage'>
      & { cover: Media['url'] }
    )>, galleryMD?: Maybe<Array<(
      { __typename?: 'Media' }
      & Pick<Media, 'serpImage' | 'caption'>
      & { src: Media['url'] }
    )>>, galleryOriginal?: Maybe<Array<(
      { __typename?: 'Media' }
      & Pick<Media, 'serpImage' | 'caption'>
      & { src: Media['url'] }
    )>>, country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name' | 'url' | 'slug'>
    )>, city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'url' | 'slug'>
    )>, workingCountries?: Maybe<Array<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    )>>, cities: Array<(
      { __typename?: 'City' }
      & CityFooterFragment
    )>, languages: Array<(
      { __typename?: 'SupplierLanguage' }
      & Pick<SupplierLanguage, 'level'>
      & { language: (
        { __typename?: 'Language' }
        & Pick<Language, 'id' | 'name'>
      ) }
    )>, stats: (
      { __typename?: 'SupplierStats' }
      & Pick<SupplierStats, 'reviewsCount' | 'rating'>
    ), analytics: (
      { __typename?: 'SupplierAnalytics' }
      & Pick<SupplierAnalytics, 'conversionRate'>
    ) }
  )> }
);

export type SupplierForCreatePaymentRequestQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SupplierForCreatePaymentRequestQuery = (
  { __typename?: 'Query' }
  & { supplierById?: Maybe<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'isBan' | 'supplierBookingDates' | 'commission'>
    & { calendar: (
      { __typename?: 'Calendar' }
      & Pick<Calendar, 'minDate' | 'maxDate' | 'workingDates' | 'notWorkingDates' | 'daysOfWeekAvailable' | 'daysOfWeekBlocked' | 'bookingsDates'>
    ), country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name' | 'url'>
      & { currency?: Maybe<(
        { __typename?: 'Currency' }
        & Pick<Currency, 'id' | 'symbol' | 'minPrice'>
        & { quotes: Array<(
          { __typename?: 'Quote' }
          & Pick<Quote, 'currency' | 'rate'>
        )> }
      )> }
    )>, workingCountries?: Maybe<Array<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    )>>, cities: Array<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name' | 'url'>
      & { country: (
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type SupplierCalendarByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SupplierCalendarByIdQuery = (
  { __typename?: 'Query' }
  & { supplierById?: Maybe<(
    { __typename?: 'Supplier' }
    & { calendar: (
      { __typename?: 'Calendar' }
      & Pick<Calendar, 'minDate' | 'maxDate' | 'workingDates' | 'notWorkingDates' | 'daysOfWeekAvailable' | 'daysOfWeekBlocked' | 'bookingsDates'>
    ) }
  )> }
);

export type SupplierBookingPaymentFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName'>
  & { country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id'>
  )>, bank?: Maybe<(
    { __typename?: 'SupplierBank' }
    & Pick<SupplierBank, 'firstName' | 'lastName'>
  )>, payments?: Maybe<(
    { __typename?: 'PaymentsPayload' }
    & { payments: Array<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'bookingsCount' | 'period' | 'supplierFee' | 'gwgBilledAmount' | 'currencyId'>
    )> }
  )>, bookingsAsSupplier: (
    { __typename?: 'BookingsConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'endCursor' | 'startCursor'>
    ), edges: Array<(
      { __typename?: 'BookingsEdge' }
      & { node: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'code' | 'title' | 'meetingDate' | 'meetingTime' | 'payoutStatus' | 'status' | 'duration' | 'supplierId' | 'adultsCount' | 'childrenCount' | 'infantCount'>
        & { city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name'>
        )>, invoice: (
          { __typename?: 'BookingInvoice' }
          & Pick<BookingInvoice, 'supplierFee' | 'currencyId' | 'gwgBilledAmount' | 'total'>
        ), user: (
          { __typename?: 'User' }
          & Pick<User, 'displayName'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ) }
      ) }
    )> }
  ) }
);

export type SupplierPaymentsForMonthlyInvoiceQueryVariables = Exact<{
  bookingFilter?: Maybe<BookingFilters>;
  paymentFilter?: Maybe<PaymentFilters>;
}>;


export type SupplierPaymentsForMonthlyInvoiceQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierBookingPaymentFragment
  ) }
);

export type SupplierScarcityQueryVariables = Exact<{
  supplierId: Scalars['Int'];
  date?: Maybe<Scalars['String']>;
}>;


export type SupplierScarcityQuery = (
  { __typename?: 'Query' }
  & { supplierScarcity?: Maybe<(
    { __typename?: 'SupplierScarCityPayload' }
    & Pick<SupplierScarCityPayload, 'slot' | 'month'>
  )> }
);

export type SupplierWithPaymentFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'payoutMethod'>
  & { bank?: Maybe<(
    { __typename?: 'SupplierBank' }
    & Pick<SupplierBank, 'bankName' | 'bankCode' | 'branchName' | 'branchCode' | 'accountNumber' | 'accountName' | 'accountType' | 'firstName' | 'lastName'>
  )>, paypal?: Maybe<(
    { __typename?: 'SupplierPaypal' }
    & Pick<SupplierPaypal, 'id' | 'name' | 'email'>
  )>, country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name' | 'url'>
  )> }
);

export type SupplierWithPaymentByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SupplierWithPaymentByIdQuery = (
  { __typename?: 'Query' }
  & { supplierById?: Maybe<(
    { __typename?: 'Supplier' }
    & SupplierWithPaymentFragment
  )> }
);

export type SupplierWithPaymentsFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'lastName' | 'firstName' | 'payoutMethod' | 'address'>
  & { payments?: Maybe<(
    { __typename?: 'PaymentsPayload' }
    & Pick<PaymentsPayload, 'hasNextPage' | 'hasPreviousPage'>
    & { payments: Array<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'bookingsCount' | 'status' | 'period' | 'supplierFee' | 'currencyId'>
    )> }
  )>, bank?: Maybe<(
    { __typename?: 'SupplierBank' }
    & Pick<SupplierBank, 'lastName' | 'firstName'>
  )> }
);

export type SupplierWithPaymentsQueryVariables = Exact<{
  filters?: Maybe<PaymentFilters>;
}>;


export type SupplierWithPaymentsQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierWithPaymentsFragment
  ) }
);

export type SuppliersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  sizePerPage?: Maybe<Scalars['Int']>;
  filters?: Maybe<SupplierFilters>;
}>;


export type SuppliersQuery = (
  { __typename?: 'Query' }
  & { suppliers: (
    { __typename?: 'SupplierConnection' }
    & Pick<SupplierConnection, 'totalCount'>
    & { edges: Array<(
      { __typename?: 'SupplierEdge' }
      & { node: (
        { __typename?: 'Supplier' }
        & SupplierCardFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ) }
  ) }
);

export type UpdateCustomerAccountMutationVariables = Exact<{
  input: UpdateCustomerAccountInput;
}>;


export type UpdateCustomerAccountMutation = (
  { __typename?: 'Mutation' }
  & { UpdateCustomerAccount: (
    { __typename?: 'UpdateCustomerAccountPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ) }
  ) }
);

export type UpdateSupplierAccountMutationVariables = Exact<{
  input: UpdateSupplierAccountInput;
}>;


export type UpdateSupplierAccountMutation = (
  { __typename?: 'Mutation' }
  & { UpdateSupplierAccount: (
    { __typename?: 'UpdateSupplierAccountPayload' }
    & { supplier: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'guideBookingNotice' | 'guideBookingWindow'>
      & { calendar: (
        { __typename?: 'Calendar' }
        & Pick<Calendar, 'daysOfWeekAvailable' | 'daysOfWeekBlocked' | 'bookingsDates'>
      ) }
    ) }
  ) }
);

export type UpdateSupplierBankMutationVariables = Exact<{
  input: UpdateSupplierBankInput;
}>;


export type UpdateSupplierBankMutation = (
  { __typename?: 'Mutation' }
  & { UpdateSupplierBank: (
    { __typename?: 'UpdateSupplierBankPayload' }
    & { supplier: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'displayName' | 'payoutMethod'>
      & { bank?: Maybe<(
        { __typename?: 'SupplierBank' }
        & Pick<SupplierBank, 'bankName' | 'bankCode' | 'branchName' | 'branchCode' | 'accountNumber' | 'accountName' | 'accountType' | 'firstName' | 'lastName'>
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name' | 'url'>
      )> }
    ) }
  ) }
);

export type UpdateSupplierGalleryMutationVariables = Exact<{
  input: UpdateSupplierGalleryInput;
}>;


export type UpdateSupplierGalleryMutation = (
  { __typename?: 'Mutation' }
  & { UpdateSupplierGallery: (
    { __typename?: 'UpdateSupplierGalleryPayload' }
    & { supplier: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id'>
      & { gallery?: Maybe<Array<(
        { __typename?: 'Media' }
        & Pick<Media, 'id' | 'caption' | 'position'>
        & { src: Media['url'] }
      )>> }
    ) }
  ) }
);

export type UpdateSupplierPaypalMutationVariables = Exact<{
  input: UpdateSupplierPaypalInput;
}>;


export type UpdateSupplierPaypalMutation = (
  { __typename?: 'Mutation' }
  & { UpdateSupplierPaypal: (
    { __typename?: 'UpdateSupplierPaypalPayload' }
    & { supplier: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'displayName' | 'payoutMethod'>
      & { paypal?: Maybe<(
        { __typename?: 'SupplierPaypal' }
        & Pick<SupplierPaypal, 'id' | 'name' | 'email'>
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name' | 'url'>
      )> }
    ) }
  ) }
);

export type ToursOfSupplierByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ToursOfSupplierByIdQuery = (
  { __typename?: 'Query' }
  & { supplierById?: Maybe<(
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'displayName'>
    & { tours: Array<(
      { __typename?: 'Tour' }
      & Pick<Tour, 'id' | 'title' | 'duration' | 'url'>
      & { city: (
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name' | 'slug' | 'url'>
      ), picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url' | 'caption'>
        & { cover: Media['url'] }
      )>, price: (
        { __typename?: 'TourPrice' }
        & Pick<TourPrice, 'amount' | 'model'>
        & { currency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'id'>
          & { quotes: Array<(
            { __typename?: 'Quote' }
            & Pick<Quote, 'currency' | 'rate'>
          )> }
        ) }
      ) }
    )> }
  )> }
);

export type AcceptBookingMutationVariables = Exact<{
  bookingId: Scalars['Int'];
}>;


export type AcceptBookingMutation = (
  { __typename?: 'Mutation' }
  & { AcceptBooking: (
    { __typename?: 'CaptureBookingPayload' }
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
    ) }
  ) }
);

export type AddCouponToBookingMutationVariables = Exact<{
  input: AddCouponToBookingInput;
}>;


export type AddCouponToBookingMutation = (
  { __typename?: 'Mutation' }
  & { AddCouponToBooking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'userId' | 'title' | 'duration' | 'supplierId'>
  ) }
);

export type AuthorizePayPalOrderMutationVariables = Exact<{
  input: AuthorizePayPalOrderInput;
}>;


export type AuthorizePayPalOrderMutation = (
  { __typename?: 'Mutation' }
  & { AuthorizePayPalOrder: (
    { __typename?: 'AuthorizePayPalOrderPayload' }
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'userId' | 'title' | 'duration' | 'supplierId'>
    ) }
  ) }
);

export type BookingPaymentErrorMutationVariables = Exact<{
  input: BookingPaymentErrorInput;
}>;


export type BookingPaymentErrorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingPaymentError'>
);

export type CalculateBookingCancellationPolicyRuleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CalculateBookingCancellationPolicyRuleMutation = (
  { __typename?: 'Mutation' }
  & { CalculateBookingCancellationPolicyRule: (
    { __typename?: 'CalculateBookingCancellationPolicyRulePayLoad' }
    & Pick<CalculateBookingCancellationPolicyRulePayLoad, 'refundPercent' | 'daysDiff' | 'meetingDateAt' | 'cancelAt'>
  ) }
);

export type CancelBookingMutationVariables = Exact<{
  input: CancelBookingInput;
}>;


export type CancelBookingMutation = (
  { __typename?: 'Mutation' }
  & { CancelBooking: (
    { __typename?: 'CancelBookingPayload' }
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
    ) }
  ) }
);

export type CompleteBookingMutationVariables = Exact<{
  id: Scalars['Int'];
  input: CompleteBookingInput;
}>;


export type CompleteBookingMutation = (
  { __typename?: 'Mutation' }
  & { CompleteBooking: (
    { __typename?: 'CompleteBookingPayload' }
    & Pick<CompleteBookingPayload, 'messageId' | 'message'>
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status' | 'hotel'>
    )> }
  ) }
);

export type CreateBookingQuotationMutationVariables = Exact<{
  input: CreateBookingQuotationInput;
}>;


export type CreateBookingQuotationMutation = (
  { __typename?: 'Mutation' }
  & { CreateBookingQuotation: (
    { __typename?: 'CreateBookingQuotationPayload' }
    & Pick<CreateBookingQuotationPayload, 'onlinePrice' | 'cashPrice' | 'payoutMethod'>
    & { tour?: Maybe<(
      { __typename?: 'Tour' }
      & Pick<Tour, 'id' | 'title' | 'duration' | 'url'>
      & { country: (
        { __typename?: 'Country' }
        & Pick<Country, 'id'>
      ), picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )>, price: (
        { __typename?: 'TourPrice' }
        & Pick<TourPrice, 'amount' | 'model'>
      ), supplier: (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName' | 'payoutMethodType' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, stats: (
          { __typename?: 'SupplierStats' }
          & Pick<SupplierStats, 'reviewsCount' | 'rating'>
        ) }
      ) }
    )>, items: Array<(
      { __typename?: 'BookingQuotationItem' }
      & Pick<BookingQuotationItem, 'type' | 'description' | 'price'>
    )>, currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'id' | 'symbol' | 'minPrice'>
      & { quotes: Array<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'currency' | 'rate'>
      )> }
    ) }
  ) }
);

export type CreatePayPalOrderMutationVariables = Exact<{
  input: CreatePayPalOrderInput;
}>;


export type CreatePayPalOrderMutation = (
  { __typename?: 'Mutation' }
  & { CreatePayPalOrder: (
    { __typename?: 'CreatePayPalOrderPayload' }
    & Pick<CreatePayPalOrderPayload, 'orderId'>
  ) }
);

export type CreateTourBookingMutationVariables = Exact<{
  input: CreateTourBookingInput;
}>;


export type CreateTourBookingMutation = (
  { __typename?: 'Mutation' }
  & { CreateTourBooking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'userId' | 'title' | 'duration' | 'supplierId'>
  ) }
);

export type CreateTourQuotationMutationVariables = Exact<{
  input: CreateTourQuotationInput;
}>;


export type CreateTourQuotationMutation = (
  { __typename?: 'Mutation' }
  & { CreateTourQuotation: (
    { __typename?: 'CreateTourQuotationPayload' }
    & Pick<CreateTourQuotationPayload, 'onlinePrice' | 'cashPrice' | 'payoutMethod'>
    & { tour: (
      { __typename?: 'Tour' }
      & Pick<Tour, 'id' | 'title' | 'duration' | 'url'>
      & { country: (
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'isActive'>
      ), city: (
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name'>
      ), cancelPolicyRules: Array<(
        { __typename?: 'CancelPolicyRule' }
        & Pick<CancelPolicyRule, 'from' | 'to' | 'charge'>
      )>, picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )>, price: (
        { __typename?: 'TourPrice' }
        & Pick<TourPrice, 'amount' | 'model'>
      ), supplier: (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'displayName' | 'payoutMethodType' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )>, stats: (
          { __typename?: 'SupplierStats' }
          & Pick<SupplierStats, 'reviewsCount' | 'rating'>
        ) }
      ) }
    ), items: Array<(
      { __typename?: 'TourQuotationItem' }
      & Pick<TourQuotationItem, 'type' | 'description' | 'price'>
    )>, currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'id' | 'symbol' | 'minPrice'>
      & { quotes: Array<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'currency' | 'rate'>
      )> }
    ) }
  ) }
);

export type CreateVeritransPaymentMutationVariables = Exact<{
  input: CreateVeritransPaymentInput;
}>;


export type CreateVeritransPaymentMutation = (
  { __typename?: 'Mutation' }
  & { CreateVeritransPayment: (
    { __typename?: 'CreateVeritransPaymentPayload' }
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'userId' | 'title' | 'duration' | 'supplierId'>
    ) }
  ) }
);

export type CustomerBookingDetailFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'displayName'>
  & { bookingById: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'payoutMethod' | 'latestMessageId' | 'code' | 'content' | 'overview' | 'bookingType' | 'hotel' | 'paymentMethod' | 'paymentDate' | 'createdAt' | 'canReview' | 'canCancel' | 'showNamePhoneInUi' | 'id' | 'title' | 'meetingDate' | 'meetingTime' | 'meetinPoint' | 'endingTime' | 'endingPoint' | 'included' | 'excluded' | 'important' | 'status' | 'supplierId' | 'duration' | 'adultsCount' | 'childrenCount' | 'infantCount'>
    & { city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name'>
      & { country: (
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name' | 'url' | 'slug'>
      ) }
    )>, itinerary?: Maybe<Array<(
      { __typename?: 'TourItinerary' }
      & Pick<TourItinerary, 'type' | 'time' | 'title' | 'description'>
    )>>, currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'id' | 'symbol' | 'minPrice'>
      & { quotes: Array<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'currency' | 'rate'>
      )> }
    ), items: Array<(
      { __typename?: 'BookingItem' }
      & Pick<BookingItem, 'id' | 'description' | 'quantity' | 'unitPrice' | 'totalPrice' | 'type'>
    )>, tour?: Maybe<(
      { __typename?: 'Tour' }
      & Pick<Tour, 'id' | 'title' | 'slug' | 'overview' | 'duration'>
      & { picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )>, country: (
        { __typename?: 'Country' }
        & Pick<Country, 'slug'>
      ), cancelPolicyRules: Array<(
        { __typename?: 'CancelPolicyRule' }
        & Pick<CancelPolicyRule, 'from' | 'to' | 'charge'>
      )>, price: (
        { __typename?: 'TourPrice' }
        & Pick<TourPrice, 'amount' | 'model'>
        & { currency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol' | 'id'>
        ) }
      ) }
    )>, payout: (
      { __typename?: 'BookingPayout' }
      & Pick<BookingPayout, 'method' | 'amount' | 'payoutAt' | 'status' | 'transactionId' | 'error'>
    ), review?: Maybe<(
      { __typename?: 'Review' }
      & Pick<Review, 'id' | 'title' | 'rating' | 'response' | 'message' | 'createdAt'>
      & { photos: Array<(
        { __typename?: 'Media' }
        & Pick<Media, 'id' | 'urlXS' | 'urlLG'>
      )>, user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ), supplier: (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'firstName' | 'lastName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ) }
    )>, invoice: (
      { __typename?: 'BookingInvoice' }
      & Pick<BookingInvoice, 'supplierFee' | 'cardFee' | 'total' | 'serviceFee' | 'currencyId' | 'cash' | 'online'>
    ), user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'firstName' | 'lastName' | 'phone' | 'phoneCountryCode'>
      & { picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'name' | 'dialCode'>
      )> }
    ), supplier?: Maybe<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'displayName' | 'firstName' | 'lastName' | 'url' | 'phone' | 'phoneCountryCode' | 'payoutMethodType'>
      & { picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )>, stats: (
        { __typename?: 'SupplierStats' }
        & Pick<SupplierStats, 'rating' | 'reviewsCount'>
      ), languages: Array<(
        { __typename?: 'SupplierLanguage' }
        & { language: (
          { __typename?: 'Language' }
          & Pick<Language, 'name'>
        ) }
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'name' | 'dialCode'>
      )> }
    )> }
  ) }
);

export type CustomerBookingDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CustomerBookingDetailQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & CustomerBookingDetailFragment
  ) | { __typename: 'Supplier' } }
);

export type CustomerBookingsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'displayName' | 'phone' | 'url'>
  & { bookings: (
    { __typename?: 'BookingsConnection' }
    & Pick<BookingsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename?: 'BookingsEdge' }
      & Pick<BookingsEdge, 'cursor'>
      & { node: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'bookingType' | 'latestMessageId' | 'code' | 'title' | 'content' | 'payoutStatus' | 'status' | 'supplierId' | 'duration' | 'canReview' | 'canCancel' | 'adultsCount' | 'childrenCount' | 'infantCount' | 'meetingDate' | 'meetingTime' | 'meetinPoint' | 'hotel' | 'endingTime' | 'endingPoint' | 'included' | 'excluded' | 'important'>
        & { itinerary?: Maybe<Array<(
          { __typename?: 'TourItinerary' }
          & Pick<TourItinerary, 'type' | 'time' | 'title' | 'description'>
        )>>, review?: Maybe<(
          { __typename?: 'Review' }
          & Pick<Review, 'id' | 'title' | 'rating' | 'response' | 'message' | 'createdAt'>
          & { user: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName' | 'url'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          ), supplier: (
            { __typename?: 'Supplier' }
            & Pick<Supplier, 'id' | 'firstName' | 'lastName' | 'url'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          ) }
        )>, tour?: Maybe<(
          { __typename?: 'Tour' }
          & Pick<Tour, 'id' | 'title' | 'duration'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )>, cancelPolicyRules: Array<(
            { __typename?: 'CancelPolicyRule' }
            & Pick<CancelPolicyRule, 'from' | 'to' | 'charge'>
          )>, price: (
            { __typename?: 'TourPrice' }
            & Pick<TourPrice, 'amount' | 'model'>
            & { currency: (
              { __typename?: 'Currency' }
              & Pick<Currency, 'symbol' | 'id'>
            ) }
          ) }
        )>, city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name'>
        )>, invoice: (
          { __typename?: 'BookingInvoice' }
          & Pick<BookingInvoice, 'supplierFee' | 'cardFee' | 'total' | 'currencyId'>
        ), user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'phone' | 'phoneCountryCode'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )>, country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<Country, 'name'>
          )> }
        ), supplier?: Maybe<(
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'id' | 'displayName' | 'firstName' | 'isSupplier' | 'isSupplierApply' | 'isAdmin' | 'isBlogger' | 'phone' | 'phoneCountryCode' | 'payoutMethodStatus' | 'payoutMethodType' | 'payoutMethod'>
          & { stats: (
            { __typename?: 'SupplierStats' }
            & Pick<SupplierStats, 'bookingsCount'>
          ), picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type CustomerBookingsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  bookingStatus?: Maybe<Array<BookingStatus> | BookingStatus>;
}>;


export type CustomerBookingsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & CustomerBookingsFragment
  ) | { __typename: 'Supplier' } }
);

export type CustomerReviewsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'displayName'>
  & { bookings: (
    { __typename?: 'BookingsConnection' }
    & Pick<BookingsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename?: 'BookingsEdge' }
      & Pick<BookingsEdge, 'cursor'>
      & { node: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'title' | 'meetingDate' | 'meetingTime' | 'canReview' | 'duration'>
        & { city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name'>
        )>, supplier?: Maybe<(
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'id' | 'displayName'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        )>, user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ), review?: Maybe<(
          { __typename?: 'Review' }
          & Pick<Review, 'id' | 'title' | 'rating' | 'response' | 'message' | 'createdAt'>
          & { user: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'displayName'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          ), supplier: (
            { __typename?: 'Supplier' }
            & Pick<Supplier, 'id' | 'displayName'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type CustomerReviewsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  withReview?: Maybe<Scalars['Boolean']>;
  bookingStatus?: Maybe<Array<BookingStatus> | BookingStatus>;
}>;


export type CustomerReviewsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename: 'User' }
    & CustomerReviewsFragment
  ) | { __typename: 'Supplier' } }
);

export type DeletePendingBookingMutationVariables = Exact<{
  bookingId: Scalars['Int'];
}>;


export type DeletePendingBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeletePendingBooking'>
);

export type SupplierAsCustomerBookingsFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'payoutMethod' | 'phone' | 'url'>
  & { bookings: (
    { __typename?: 'BookingsConnection' }
    & Pick<BookingsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename?: 'BookingsEdge' }
      & Pick<BookingsEdge, 'cursor'>
      & { node: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'latestMessageId' | 'bookingType' | 'code' | 'title' | 'content' | 'payoutStatus' | 'status' | 'supplierId' | 'duration' | 'adultsCount' | 'childrenCount' | 'infantCount' | 'meetingDate' | 'canReview' | 'canCancel' | 'meetingTime' | 'meetinPoint' | 'hotel' | 'endingTime' | 'endingPoint' | 'included' | 'excluded' | 'important'>
        & { itinerary?: Maybe<Array<(
          { __typename?: 'TourItinerary' }
          & Pick<TourItinerary, 'type' | 'time' | 'title' | 'description'>
        )>>, review?: Maybe<(
          { __typename?: 'Review' }
          & Pick<Review, 'id' | 'title' | 'rating' | 'response' | 'message' | 'createdAt'>
          & { user: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName' | 'url'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          ), supplier: (
            { __typename?: 'Supplier' }
            & Pick<Supplier, 'id' | 'firstName' | 'lastName' | 'url'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          ) }
        )>, tour?: Maybe<(
          { __typename?: 'Tour' }
          & Pick<Tour, 'id' | 'title' | 'duration'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )>, cancelPolicyRules: Array<(
            { __typename?: 'CancelPolicyRule' }
            & Pick<CancelPolicyRule, 'from' | 'to' | 'charge'>
          )>, price: (
            { __typename?: 'TourPrice' }
            & Pick<TourPrice, 'amount' | 'model'>
            & { currency: (
              { __typename?: 'Currency' }
              & Pick<Currency, 'symbol' | 'id'>
            ) }
          ) }
        )>, city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name'>
        )>, invoice: (
          { __typename?: 'BookingInvoice' }
          & Pick<BookingInvoice, 'supplierFee' | 'cardFee' | 'total' | 'currencyId'>
        ), user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'phone' | 'phoneCountryCode'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )>, country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<Country, 'name'>
          )> }
        ), supplier?: Maybe<(
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'id' | 'displayName' | 'firstName' | 'isSupplier' | 'isSupplierApply' | 'isAdmin' | 'isBlogger' | 'payoutMethodStatus' | 'payoutMethodType' | 'payoutMethod' | 'phone' | 'phoneCountryCode'>
          & { stats: (
            { __typename?: 'SupplierStats' }
            & Pick<SupplierStats, 'bookingsCount'>
          ), picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type SupplierAsCustomerBookingsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  bookingStatus?: Maybe<Array<BookingStatus> | BookingStatus>;
}>;


export type SupplierAsCustomerBookingsQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierAsCustomerBookingsFragment
  ) }
);

export type SupplierAsCustomerReviewsFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'payoutMethod' | 'phone' | 'url'>
  & { bookings: (
    { __typename?: 'BookingsConnection' }
    & Pick<BookingsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename?: 'BookingsEdge' }
      & Pick<BookingsEdge, 'cursor'>
      & { node: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'title' | 'meetingDate' | 'meetingTime' | 'canReview' | 'duration'>
        & { city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name'>
        )>, supplier?: Maybe<(
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'id' | 'displayName'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        )>, user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ), review?: Maybe<(
          { __typename?: 'Review' }
          & Pick<Review, 'id' | 'title' | 'rating' | 'response' | 'message' | 'createdAt'>
          & { user: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'displayName'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          ), supplier: (
            { __typename?: 'Supplier' }
            & Pick<Supplier, 'id' | 'displayName'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type SupplierAsCustomerReviewsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  withReview?: Maybe<Scalars['Boolean']>;
  bookingStatus?: Maybe<Array<BookingStatus> | BookingStatus>;
}>;


export type SupplierAsCustomerReviewsQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierAsCustomerReviewsFragment
  ) }
);

export type SupplierBookingDetailFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName'>
  & { bookingById: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'title' | 'meetingDate' | 'meetingTime' | 'meetinPoint' | 'meetingPointDescription' | 'endingTime' | 'endingPoint' | 'included' | 'excluded' | 'important' | 'status' | 'supplierId' | 'duration' | 'adultsCount' | 'childrenCount' | 'infantCount' | 'payoutMethod' | 'code' | 'canReview' | 'canCancel' | 'latestMessageId' | 'overview' | 'content' | 'bookingType' | 'paymentMethod' | 'paymentDate' | 'createdAt' | 'hotel' | 'showNamePhoneInUi'>
    & { city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name'>
      & { country: (
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name' | 'url' | 'slug'>
      ) }
    )>, itinerary?: Maybe<Array<(
      { __typename?: 'TourItinerary' }
      & Pick<TourItinerary, 'type' | 'time' | 'title' | 'description'>
    )>>, currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'id' | 'symbol' | 'minPrice'>
      & { quotes: Array<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'currency' | 'rate'>
      )> }
    ), items: Array<(
      { __typename?: 'BookingItem' }
      & Pick<BookingItem, 'id' | 'description' | 'quantity' | 'unitPrice' | 'totalPrice' | 'type'>
    )>, tour?: Maybe<(
      { __typename?: 'Tour' }
      & Pick<Tour, 'id' | 'title' | 'slug' | 'content' | 'overview' | 'duration'>
      & { calendar: (
        { __typename?: 'Calendar' }
        & Pick<Calendar, 'minDate' | 'maxDate' | 'workingDates' | 'notWorkingDates' | 'daysOfWeekAvailable' | 'daysOfWeekBlocked' | 'bookingsDates'>
      ), picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )>, cancelPolicyRules: Array<(
        { __typename?: 'CancelPolicyRule' }
        & Pick<CancelPolicyRule, 'from' | 'to' | 'charge'>
      )>, price: (
        { __typename?: 'TourPrice' }
        & Pick<TourPrice, 'amount' | 'model'>
        & { currency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol' | 'id'>
        ) }
      ) }
    )>, payout: (
      { __typename?: 'BookingPayout' }
      & Pick<BookingPayout, 'method' | 'amount' | 'payoutAt' | 'status' | 'transactionId' | 'error'>
    ), review?: Maybe<(
      { __typename?: 'Review' }
      & Pick<Review, 'id' | 'title' | 'rating' | 'response' | 'message' | 'createdAt'>
      & { photos: Array<(
        { __typename?: 'Media' }
        & Pick<Media, 'id' | 'urlXS' | 'urlLG'>
      )>, user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ), supplier: (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'firstName' | 'lastName' | 'url'>
        & { picture?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ) }
    )>, invoice: (
      { __typename?: 'BookingInvoice' }
      & Pick<BookingInvoice, 'supplierFee' | 'cardFee' | 'total' | 'cash' | 'online' | 'serviceFee' | 'currencyId'>
    ), user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'firstName' | 'lastName' | 'phone' | 'phoneCountryCode'>
      & { picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'name' | 'dialCode'>
      )> }
    ), supplier?: Maybe<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'displayName' | 'firstName' | 'lastName' | 'url' | 'phone' | 'commission' | 'phoneCountryCode' | 'payoutMethodType'>
      & { languages: Array<(
        { __typename?: 'SupplierLanguage' }
        & { language: (
          { __typename?: 'Language' }
          & Pick<Language, 'name'>
        ) }
      )>, country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name' | 'dialCode'>
      )>, workingCountries?: Maybe<Array<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name'>
      )>>, cities: Array<(
        { __typename?: 'City' }
        & Pick<City, 'id' | 'name'>
        & { country: (
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name'>
        ) }
      )>, picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )>, stats: (
        { __typename?: 'SupplierStats' }
        & Pick<SupplierStats, 'rating' | 'reviewsCount'>
      ), calendar: (
        { __typename?: 'Calendar' }
        & Pick<Calendar, 'minDate' | 'maxDate' | 'workingDates' | 'notWorkingDates' | 'daysOfWeekAvailable' | 'daysOfWeekBlocked' | 'bookingsDates'>
      ) }
    )> }
  ) }
);

export type SupplierBookingDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SupplierBookingDetailQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierBookingDetailFragment
  ) }
);

export type SupplierBookingsFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'payoutMethod' | 'phone' | 'url'>
  & { bookingsAsSupplier: (
    { __typename?: 'BookingsConnection' }
    & Pick<BookingsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename?: 'BookingsEdge' }
      & Pick<BookingsEdge, 'cursor'>
      & { node: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'latestMessageId' | 'bookingType' | 'code' | 'title' | 'content' | 'payoutStatus' | 'status' | 'supplierId' | 'duration' | 'canReview' | 'canCancel' | 'adultsCount' | 'childrenCount' | 'infantCount' | 'meetingDate' | 'meetingTime' | 'meetinPoint' | 'hotel' | 'endingTime' | 'endingPoint' | 'included' | 'excluded' | 'important'>
        & { itinerary?: Maybe<Array<(
          { __typename?: 'TourItinerary' }
          & Pick<TourItinerary, 'type' | 'time' | 'title' | 'description'>
        )>>, review?: Maybe<(
          { __typename?: 'Review' }
          & Pick<Review, 'id' | 'title' | 'rating' | 'response' | 'message' | 'createdAt'>
          & { user: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName' | 'url'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          ), supplier: (
            { __typename?: 'Supplier' }
            & Pick<Supplier, 'id' | 'firstName' | 'lastName' | 'url'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          ) }
        )>, tour?: Maybe<(
          { __typename?: 'Tour' }
          & Pick<Tour, 'id' | 'title' | 'duration'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )>, cancelPolicyRules: Array<(
            { __typename?: 'CancelPolicyRule' }
            & Pick<CancelPolicyRule, 'from' | 'to' | 'charge'>
          )>, price: (
            { __typename?: 'TourPrice' }
            & Pick<TourPrice, 'amount' | 'model'>
            & { currency: (
              { __typename?: 'Currency' }
              & Pick<Currency, 'symbol' | 'id'>
            ) }
          ) }
        )>, city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name'>
        )>, invoice: (
          { __typename?: 'BookingInvoice' }
          & Pick<BookingInvoice, 'supplierFee' | 'cardFee' | 'total' | 'currencyId'>
        ), user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName' | 'phone' | 'phoneCountryCode'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )>, country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<Country, 'name'>
          )> }
        ), supplier?: Maybe<(
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'id' | 'displayName' | 'firstName' | 'isSupplier' | 'isSupplierApply' | 'isAdmin' | 'isBlogger' | 'payoutMethodStatus' | 'payoutMethodType' | 'payoutMethod' | 'phone' | 'phoneCountryCode'>
          & { stats: (
            { __typename?: 'SupplierStats' }
            & Pick<SupplierStats, 'bookingsCount'>
          ), picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type SupplierBookingsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  bookingStatus?: Maybe<Array<BookingStatus> | BookingStatus>;
}>;


export type SupplierBookingsQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierBookingsFragment
  ) }
);

export type SupplierBookingsCalendarFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'payoutMethod' | 'phone' | 'url'>
  & { bookingsAsSupplier: (
    { __typename?: 'BookingsConnection' }
    & Pick<BookingsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename?: 'BookingsEdge' }
      & Pick<BookingsEdge, 'cursor'>
      & { node: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'title' | 'payoutStatus' | 'status' | 'duration' | 'meetingDate'>
        & { city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name'>
        )>, user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
        ) }
      ) }
    )> }
  ) }
);

export type SupplierBookingsForCalendarQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  bookingStatus?: Maybe<Array<BookingStatus> | BookingStatus>;
  orderBy?: Maybe<BookingOrderBy>;
  meetingDateFrom?: Maybe<Scalars['String']>;
  meetingDateTo?: Maybe<Scalars['String']>;
}>;


export type SupplierBookingsForCalendarQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierBookingsCalendarFragment
  ) }
);

export type SupplierReviewsFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'payoutMethod' | 'phone' | 'url'>
  & { bookingsAsSupplier: (
    { __typename?: 'BookingsConnection' }
    & Pick<BookingsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename?: 'BookingsEdge' }
      & Pick<BookingsEdge, 'cursor'>
      & { node: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'title' | 'meetingDate' | 'meetingTime' | 'duration'>
        & { city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name'>
        )>, supplier?: Maybe<(
          { __typename?: 'Supplier' }
          & Pick<Supplier, 'id' | 'displayName'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        )>, user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
          & { picture?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'url'>
          )> }
        ), review?: Maybe<(
          { __typename?: 'Review' }
          & Pick<Review, 'id' | 'title' | 'rating' | 'response' | 'message' | 'createdAt'>
          & { user: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'displayName'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          ), supplier: (
            { __typename?: 'Supplier' }
            & Pick<Supplier, 'id' | 'displayName'>
            & { picture?: Maybe<(
              { __typename?: 'Media' }
              & Pick<Media, 'url'>
            )> }
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type SupplierReviewsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  withReview?: Maybe<Scalars['Boolean']>;
  bookingStatus?: Maybe<Array<BookingStatus> | BookingStatus>;
}>;


export type SupplierReviewsQuery = (
  { __typename?: 'Query' }
  & { me: { __typename: 'User' } | (
    { __typename: 'Supplier' }
    & SupplierReviewsFragment
  ) }
);

export type UpdateBookingMutationVariables = Exact<{
  input: UpdateBookingInput;
  id: Scalars['Int'];
}>;


export type UpdateBookingMutation = (
  { __typename?: 'Mutation' }
  & { UpdateBooking: (
    { __typename?: 'UpdateBookingPayload' }
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status'>
    ) }
  ) }
);

export type CategoryCardFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'slug' | 'isActive' | 'countPosts'>
  & { seoContent?: Maybe<(
    { __typename?: 'SeoContent' }
    & Pick<SeoContent, 'title' | 'content'>
  )> }
);

export type CityCardFragment = (
  { __typename?: 'City' }
  & Pick<City, 'id' | 'name' | 'slug' | 'url'>
  & { picture?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )>, country: (
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name' | 'url'>
  ) }
);

export type CityFooterFragment = (
  { __typename?: 'City' }
  & Pick<City, 'id' | 'name' | 'slug' | 'url' | 'hasSuppliers' | 'hasOnlineTours' | 'hasPrivateTours' | 'hasTransportationTours' | 'timezone'>
  & { country: (
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name' | 'slug' | 'url' | 'timezone'>
  ) }
);

export type CountryCardFragment = (
  { __typename?: 'Country' }
  & Pick<Country, 'id' | 'name' | 'slug' | 'url'>
  & { picture?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )> }
);

export type CountryFooterFragment = (
  { __typename?: 'Country' }
  & Pick<Country, 'id' | 'name' | 'slug' | 'url' | 'hasSuppliers' | 'hasOnlineTours' | 'hasPrivateTours' | 'hasTransportationTours'>
);

export type PageInfoFieldsFragment = (
  { __typename?: 'PageInfo' }
  & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor' | 'page' | 'totalPages'>
);

export type PageInfoTopPageFragment = (
  { __typename?: 'PageInfo' }
  & Pick<PageInfo, 'hasNextPage' | 'startCursor' | 'endCursor'>
);

export type PostCardFragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'id' | 'title' | 'overview' | 'url' | 'contentUpdatedAt'>
  & { picture?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )>, city?: Maybe<(
    { __typename?: 'City' }
    & CityCardFragment
  )>, country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name' | 'slug'>
  )>, category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'slug'>
  )>, user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'isSupplier' | 'isAdmin' | 'isBlogger'>
    & { picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) | (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'displayName' | 'isSupplier' | 'isAdmin' | 'isBlogger'>
    & { picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

export type ReviewCardFragment = (
  { __typename?: 'Review' }
  & Pick<Review, 'id' | 'title' | 'message' | 'response' | 'rating' | 'createdAt'>
  & { user: (
    { __typename?: 'User' }
    & UserCardFragment
  ), supplier: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'displayName' | 'url'>
    & { stats: (
      { __typename?: 'SupplierStats' }
      & Pick<SupplierStats, 'reviewsCount'>
    ), picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ), photos: Array<(
    { __typename?: 'Media' }
    & Pick<Media, 'id' | 'urlXS' | 'urlLG'>
  )>, booking?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'title' | 'duration'>
    & { city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'name'>
    )>, tour?: Maybe<(
      { __typename?: 'Tour' }
      & Pick<Tour, 'id' | 'title' | 'url'>
      & { picture?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
    )> }
  )> }
);

export type SupplierCardFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'displayName' | 'biography' | 'isSupplier' | 'isAdmin' | 'isBlogger' | 'url'>
  & { country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>, city?: Maybe<(
    { __typename?: 'City' }
    & Pick<City, 'id' | 'name'>
  )>, picture?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url' | 'urlXS'>
  )>, stats: (
    { __typename?: 'SupplierStats' }
    & Pick<SupplierStats, 'reviewsCount' | 'rating'>
  ) }
);

export type TourCardFragment = (
  { __typename?: 'Tour' }
  & Pick<Tour, 'id' | 'title' | 'overview' | 'content' | 'duration' | 'url' | 'productType'>
  & { country: (
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  ), city: (
    { __typename?: 'City' }
    & Pick<City, 'id' | 'name'>
  ), category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>, picture?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )>, gallery?: Maybe<Array<(
    { __typename?: 'Media' }
    & { src: Media['url'] }
  )>>, price: (
    { __typename?: 'TourPrice' }
    & Pick<TourPrice, 'amount' | 'model'>
    & { currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'id'>
      & { quotes: Array<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'currency' | 'rate'>
      )> }
    ) }
  ), supplier: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'displayName'>
    & { picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )>, stats: (
      { __typename?: 'SupplierStats' }
      & Pick<SupplierStats, 'reviewsCount' | 'rating'>
    ) }
  ) }
);

export type TourTopPageCardFragment = (
  { __typename?: 'Tour' }
  & Pick<Tour, 'id' | 'title' | 'overview' | 'content' | 'duration' | 'url' | 'productType'>
  & { country: (
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  ), city: (
    { __typename?: 'City' }
    & Pick<City, 'id' | 'name'>
  ), category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>, picture?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )>, price: (
    { __typename?: 'TourPrice' }
    & Pick<TourPrice, 'amount' | 'model'>
    & { currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'id'>
      & { quotes: Array<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'currency' | 'rate'>
      )> }
    ) }
  ), supplier: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'displayName'>
    & { picture?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )>, stats: (
      { __typename?: 'SupplierStats' }
      & Pick<SupplierStats, 'reviewsCount' | 'rating'>
    ) }
  ) }
);

export type UserCardFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'displayName' | 'isSupplier' | 'isAdmin' | 'isBlogger' | 'url' | 'biography'>
  & { picture?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )>, gallery?: Maybe<Array<(
    { __typename?: 'Media' }
    & { src: Media['url'] }
  )>> }
);

export type OpenAiDataRequestMutationVariables = Exact<{
  input: OpenAiDataRequestInput;
}>;


export type OpenAiDataRequestMutation = (
  { __typename?: 'Mutation' }
  & { OpenAiDataRequest: (
    { __typename?: 'OpenAiDataRequestPayload' }
    & { resultPayload: (
      { __typename?: 'OpenAiDataPayload' }
      & { tours?: Maybe<(
        { __typename?: 'OpenAiDataResultsPerType' }
        & Pick<OpenAiDataResultsPerType, 'viewMore' | 'comments'>
        & { results: Array<(
          { __typename?: 'OpenAiDataResult' }
          & Pick<OpenAiDataResult, 'id' | 'title' | 'abstract' | 'image' | 'url' | 'comments'>
        )> }
      )>, guides?: Maybe<(
        { __typename?: 'OpenAiDataResultsPerType' }
        & Pick<OpenAiDataResultsPerType, 'viewMore' | 'comments'>
        & { results: Array<(
          { __typename?: 'OpenAiDataResult' }
          & Pick<OpenAiDataResult, 'id' | 'title' | 'abstract' | 'image' | 'url' | 'comments'>
        )> }
      )>, virtual_tours?: Maybe<(
        { __typename?: 'OpenAiDataResultsPerType' }
        & Pick<OpenAiDataResultsPerType, 'viewMore' | 'comments'>
        & { results: Array<(
          { __typename?: 'OpenAiDataResult' }
          & Pick<OpenAiDataResult, 'id' | 'title' | 'abstract' | 'image' | 'url' | 'comments'>
        )> }
      )>, private_cars?: Maybe<(
        { __typename?: 'OpenAiDataResultsPerType' }
        & Pick<OpenAiDataResultsPerType, 'viewMore' | 'comments'>
        & { results: Array<(
          { __typename?: 'OpenAiDataResult' }
          & Pick<OpenAiDataResult, 'id' | 'title' | 'abstract' | 'image' | 'url' | 'comments'>
        )> }
      )> }
    ) }
  ) }
);

export const UserContextFragmentDoc = gql`
    fragment UserContext on User {
  id
  displayName
  firstName
  isSupplier
  isSupplierApply
  guideApplyStep
  isAdmin
  isBlogger
  email
  phone
  phoneCountryCode
  country {
    id
  }
  picture {
    url(size: XS)
  }
}
    `;
export const SupplierContextFragmentDoc = gql`
    fragment SupplierContext on Supplier {
  id
  displayName
  firstName
  isSupplier
  isSupplierApply
  guideApplyStep
  isAdmin
  isBlogger
  payoutMethodStatus
  payoutMethodType
  payoutMethod
  email
  phone
  guideApplyStep
  phoneCountryCode
  country {
    id
  }
  stats {
    bookingsCount
  }
  picture {
    url(size: XS)
  }
  gender
  birthday
  nationality {
    id
    name
  }
  address
  postalCode
  city {
    id
    name
  }
}
    `;
export const UserBasicContextFragmentDoc = gql`
    fragment UserBasicContext on User {
  id
  isSupplier
  isSupplierApply
  isAdmin
  isBlogger
}
    `;
export const SupplierBasicContextFragmentDoc = gql`
    fragment SupplierBasicContext on Supplier {
  id
  isSupplier
  isSupplierApply
  isAdmin
  isBlogger
}
    `;
export const UserContextForBlogCreateFragmentDoc = gql`
    fragment UserContextForBlogCreate on User {
  id
  displayName
  isSupplier
  isBlogger
}
    `;
export const SupplierContextForBlogCreateFragmentDoc = gql`
    fragment SupplierContextForBlogCreate on Supplier {
  id
  displayName
  isSupplier
  isBlogger
  country {
    id
    name
  }
  workingCountries {
    id
    name
  }
  cities {
    id
    name
    country {
      id
      name
    }
  }
  languages {
    id
    level
    language {
      id
      name
      code
    }
  }
}
    `;
export const CustomerMessagesFragmentDoc = gql`
    fragment CustomerMessages on User {
  id
  displayName
  phone
  url
  messages(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: {SEARCH: $search, INBOX: $inbox}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        message
        isRead
        hide
        archived
        thread
        isImportant
        createdAt
        status
        from {
          ... on User {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
          }
          ... on Supplier {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
          }
        }
        to {
          ... on User {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
          }
          ... on Supplier {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
          }
        }
        messageThread {
          isBooked
          totalCount
          isImportant
          isArchived
          latestMessage {
            id
            message
            isRead
            archived
            thread
            isImportant
            createdAt
          }
        }
      }
    }
  }
}
    `;
export const SupplierMessagesFragmentDoc = gql`
    fragment SupplierMessages on Supplier {
  id
  displayName
  phone
  url
  messages(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: {SEARCH: $search, INBOX: $inbox}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        message
        isRead
        hide
        archived
        thread
        isImportant
        createdAt
        status
        from {
          ... on User {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
          }
          ... on Supplier {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
          }
        }
        to {
          ... on User {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
          }
          ... on Supplier {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
          }
        }
        messageThread {
          isBooked
          totalCount
          isImportant
          isArchived
          latestMessage {
            id
            message
            isRead
            archived
            thread
            isImportant
            createdAt
          }
        }
      }
    }
  }
}
    `;
export const UserBlogContextFragmentDoc = gql`
    fragment UserBlogContext on User {
  id
  displayName
  blogs(
    first: $first
    before: $before
    last: $last
    after: $after
    page: $page
    sizePerPage: $sizePerPage
    filters: $filters
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      page
      totalPages
    }
    edges {
      node {
        id
        slug
        isActive
        title
        slug
        isActive
        userId
        countViews
        url
        overview
        content
        createdAt
        updatedAt
        picture {
          url
        }
        language {
          id
          name
        }
        category {
          id
          name
        }
        country {
          id
          name
        }
        city {
          id
          name
        }
      }
    }
  }
}
    `;
export const SupplierBlogContextFragmentDoc = gql`
    fragment SupplierBlogContext on Supplier {
  id
  displayName
  blogs(
    first: $first
    before: $before
    last: $last
    after: $after
    page: $page
    sizePerPage: $sizePerPage
    filters: $filters
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      page
      totalPages
    }
    edges {
      node {
        id
        slug
        isActive
        title
        slug
        isActive
        userId
        countViews
        url
        overview
        content
        createdAt
        updatedAt
        picture {
          url
        }
        language {
          id
          name
        }
        category {
          id
          name
        }
        country {
          id
          name
        }
        city {
          id
          name
        }
      }
    }
  }
}
    `;
export const SupplierMessageTemplateByIdFragmentDoc = gql`
    fragment SupplierMessageTemplateById on Supplier {
  id
  displayName
  phone
  url
  messageTemplateById(id: $id) {
    id
    content
    title
    status
    userId
    order
  }
}
    `;
export const SupplierMessageTemplatesFragmentDoc = gql`
    fragment SupplierMessageTemplates on Supplier {
  id
  displayName
  phone
  url
  messageTemplates(filters: {IsActive: $isActive}) {
    id
    content
    title
    status
    userId
  }
}
    `;
export const SupplierTourByIdFragmentDoc = gql`
    fragment SupplierTourById on Supplier {
  id
  displayName
  payoutMethod
  phone
  url
  tourById(id: $id) {
    id
    title
    productType
    supplierId
    duration
    status
    priceModel
    hasPriceRules
    slug
    overview
    countViews
    countBookings
    language {
      id
      name
      code
    }
    country {
      id
      name
      slug
    }
    city {
      id
      name
      slug
    }
    picture {
      url
    }
    price {
      currency {
        id
        symbol
        quotes {
          currency
          rate
        }
      }
      amount
      model
    }
    supplier {
      id
      displayName
      picture {
        url(size: XS)
      }
      stats {
        reviewsCount
        rating
      }
    }
    priceRules {
      people
      price
    }
    childrenPriceRule
    infantPriceRule
  }
}
    `;
export const SupplierToursFragmentDoc = gql`
    fragment SupplierTours on Supplier {
  id
  displayName
  payoutMethod
  phone
  url
  supplierTourBadges(filters: $badgeFilters) {
    id
    name
    hex_color_code
    picture {
      url
    }
    stats(filters: $categoryStatsFilter) {
      tourCount
    }
  }
  supplierTours(page: $page, sizePerPage: $sizePerPage, filters: $filters) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      page
    }
    edges {
      cursor
      node {
        id
        title
        productType
        supplierId
        duration
        status
        priceModel
        hasPriceRules
        slug
        overview
        countViews
        countBookings
        url
        category {
          id
          name
          hex_color_code
          picture {
            url(size: SM)
          }
        }
        language {
          id
          name
          code
        }
        country {
          id
          name
          slug
        }
        city {
          id
          name
          slug
        }
        picture {
          url(size: SM)
        }
        price {
          currency {
            id
            symbol
            quotes {
              currency
              rate
            }
          }
          amount
          model
        }
      }
    }
  }
}
    `;
export const TourCardFragmentDoc = gql`
    fragment TourCard on Tour {
  id
  title
  overview
  content
  duration
  url
  productType
  country {
    id
    name
  }
  city {
    id
    name
  }
  category {
    id
    name
  }
  picture {
    url(size: SM)
  }
  gallery {
    src: url(size: MD)
  }
  price {
    currency {
      id
      quotes {
        currency
        rate
      }
    }
    amount
    model
  }
  supplier {
    id
    displayName
    picture {
      url(size: XS)
    }
    stats {
      reviewsCount
      rating
    }
  }
}
    `;
export const TourMessagePageFragmentDoc = gql`
    fragment TourMessagePage on Tour {
  ...TourCard
  people {
    min
    max
  }
  calendar {
    minDate
    maxDate
    workingDates
    notWorkingDates
    daysOfWeekAvailable
    daysOfWeekBlocked
    bookingsDates
  }
}
    ${TourCardFragmentDoc}`;
export const SupplierBookingFragmentDoc = gql`
    fragment SupplierBooking on Supplier {
  id
  displayName
  country {
    id
  }
  payments(filters: $paymentFilter) {
    payments {
      bookingsCount
      period
      supplierFee
      currencyId
    }
  }
  bookingsAsSupplier(filters: $bookingFilter, first: 10000) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      node {
        id
        code
        title
        meetingDate
        meetingTime
        payoutStatus
        status
        duration
        supplierId
        adultsCount
        childrenCount
        infantCount
        city {
          name
        }
        duration
        invoice {
          supplierFee
          currencyId
        }
        user {
          displayName
          picture {
            url(size: XS)
          }
        }
      }
    }
  }
}
    `;
export const SupplierBookingIdFragmentDoc = gql`
    fragment SupplierBookingId on Supplier {
  id
  displayName
  payoutMethod
  bookingById(id: $id) {
    payoutMethod
    code
    id
    title
    meetingDate
    meetingTime
    status
    supplierId
    duration
    adultsCount
    childrenCount
    infantCount
    refundPercent
    city {
      name
    }
    invoice {
      supplierFee
      cardFee
      total
      serviceFee
      currencyId
    }
    user {
      displayName
      picture {
        url(size: XS)
      }
    }
    payout {
      method
      amount
      payoutAt
      status
      transactionId
      error
    }
    review {
      title
      id
      rating
      message
      photos {
        id
        urlXS
        urlLG
      }
    }
    items {
      id
      description
      quantity
      unitPrice
      totalPrice
      type
    }
  }
}
    `;
export const SimpleTourFragmentDoc = gql`
    fragment SimpleTour on Tour {
  id
  title
  duration
}
    `;
export const SupplierBookingPaymentFragmentDoc = gql`
    fragment SupplierBookingPayment on Supplier {
  id
  displayName
  country {
    id
  }
  bank {
    firstName
    lastName
  }
  payments(filters: $paymentFilter) {
    payments {
      bookingsCount
      period
      supplierFee
      gwgBilledAmount
      currencyId
    }
  }
  bookingsAsSupplier(filters: $bookingFilter, first: 10000) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      node {
        id
        code
        title
        meetingDate
        meetingTime
        payoutStatus
        status
        duration
        supplierId
        adultsCount
        childrenCount
        infantCount
        city {
          name
        }
        duration
        invoice {
          supplierFee
          currencyId
          gwgBilledAmount
          total
        }
        user {
          displayName
          picture {
            url(size: XS)
          }
        }
      }
    }
  }
}
    `;
export const SupplierWithPaymentFragmentDoc = gql`
    fragment SupplierWithPayment on Supplier {
  id
  displayName
  payoutMethod
  bank {
    bankName
    bankCode
    branchName
    branchCode
    accountNumber
    accountName
    accountType
    firstName
    lastName
  }
  paypal {
    id
    name
    email
  }
  country {
    id
    name
    url
  }
}
    `;
export const SupplierWithPaymentsFragmentDoc = gql`
    fragment SupplierWithPayments on Supplier {
  id
  displayName
  lastName
  firstName
  payoutMethod
  address
  payments(filters: $filters) {
    payments {
      bookingsCount
      status
      period
      supplierFee
      currencyId
    }
    hasNextPage
    hasPreviousPage
  }
  bank {
    lastName
    firstName
  }
}
    `;
export const CustomerBookingDetailFragmentDoc = gql`
    fragment CustomerBookingDetail on User {
  id
  displayName
  bookingById(id: $id) {
    payoutMethod
    latestMessageId
    code
    content
    overview
    bookingType
    hotel
    paymentMethod
    paymentDate
    createdAt
    canReview
    canCancel
    showNamePhoneInUi
    city {
      id
      name
      country {
        id
        name
        url
        slug
      }
    }
    itinerary {
      type
      time
      title
      description
    }
    currency {
      id
      symbol
      minPrice
      quotes {
        currency
        rate
      }
    }
    items {
      id
      description
      quantity
      unitPrice
      totalPrice
      type
    }
    tour {
      id
      title
      slug
      overview
      picture {
        url
      }
      country {
        slug
      }
      cancelPolicyRules {
        from
        to
        charge
      }
      price {
        currency {
          symbol
          id
        }
        amount
        model
      }
      duration
    }
    payout {
      method
      amount
      payoutAt
      status
      transactionId
      error
    }
    review {
      id
      title
      rating
      response
      message
      createdAt
      photos {
        id
        urlXS
        urlLG
      }
      user {
        id
        firstName
        lastName
        url
        picture {
          url(size: XS)
        }
      }
      supplier {
        id
        firstName
        lastName
        url
        picture {
          url(size: XS)
        }
      }
    }
    id
    title
    meetingDate
    meetingTime
    meetinPoint
    endingTime
    endingPoint
    included
    excluded
    important
    status
    supplierId
    duration
    adultsCount
    childrenCount
    infantCount
    city {
      name
    }
    invoice {
      supplierFee
      cardFee
      total
      serviceFee
      currencyId
      cash
      online
    }
    user {
      id
      displayName
      firstName
      lastName
      picture {
        url(size: XS)
      }
      country {
        name
        dialCode
      }
      phone
      phoneCountryCode
    }
    supplier {
      id
      displayName
      firstName
      lastName
      url
      phone
      phoneCountryCode
      payoutMethodType
      picture {
        url(size: XS)
      }
      stats {
        rating
        reviewsCount
      }
      languages {
        language {
          name
        }
      }
      country {
        name
        dialCode
      }
    }
  }
}
    `;
export const CustomerBookingsFragmentDoc = gql`
    fragment CustomerBookings on User {
  id
  displayName
  phone
  url
  bookings(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: {SEARCH: $search, BOOKINGS_STATUS: $bookingStatus}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        bookingType
        latestMessageId
        code
        title
        content
        payoutStatus
        status
        supplierId
        duration
        canReview
        canCancel
        adultsCount
        childrenCount
        infantCount
        meetingDate
        meetingTime
        meetinPoint
        hotel
        endingTime
        endingPoint
        included
        excluded
        important
        itinerary {
          type
          time
          title
          description
        }
        review {
          id
          title
          rating
          response
          message
          createdAt
          user {
            id
            firstName
            lastName
            url
            picture {
              url(size: XS)
            }
          }
          supplier {
            id
            firstName
            lastName
            url
            picture {
              url(size: XS)
            }
          }
        }
        tour {
          picture {
            url
          }
          cancelPolicyRules {
            from
            to
            charge
          }
          id
          title
          price {
            currency {
              symbol
              id
            }
            amount
            model
          }
          duration
        }
        city {
          name
        }
        invoice {
          supplierFee
          cardFee
          total
          currencyId
        }
        user {
          id
          displayName
          picture {
            url(size: XS)
          }
          country {
            name
          }
          phone
          phoneCountryCode
        }
        supplier {
          id
          displayName
          firstName
          isSupplier
          isSupplierApply
          isAdmin
          isBlogger
          phone
          phoneCountryCode
          payoutMethodStatus
          payoutMethodType
          payoutMethod
          stats {
            bookingsCount
          }
          picture {
            url(size: XS)
          }
        }
      }
    }
  }
}
    `;
export const CustomerReviewsFragmentDoc = gql`
    fragment CustomerReviews on User {
  id
  displayName
  bookings(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: {WITH_REVIEW: $withReview, BOOKINGS_STATUS: $bookingStatus}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        title
        meetingDate
        meetingTime
        canReview
        duration
        city {
          name
        }
        supplier {
          id
          displayName
          picture {
            url(size: XS)
          }
        }
        user {
          id
          displayName
          picture {
            url(size: XS)
          }
        }
        review {
          id
          title
          rating
          response
          message
          createdAt
          user {
            id
            displayName
            picture {
              url(size: XS)
            }
          }
          supplier {
            id
            displayName
            picture {
              url(size: XS)
            }
          }
        }
      }
    }
  }
}
    `;
export const SupplierAsCustomerBookingsFragmentDoc = gql`
    fragment SupplierAsCustomerBookings on Supplier {
  id
  displayName
  payoutMethod
  phone
  url
  bookings(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: {SEARCH: $search, BOOKINGS_STATUS: $bookingStatus}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        latestMessageId
        bookingType
        code
        title
        content
        payoutStatus
        status
        supplierId
        duration
        adultsCount
        childrenCount
        infantCount
        meetingDate
        canReview
        canCancel
        meetingTime
        meetinPoint
        hotel
        endingTime
        endingPoint
        included
        excluded
        important
        itinerary {
          type
          time
          title
          description
        }
        review {
          id
          title
          rating
          response
          message
          createdAt
          user {
            id
            firstName
            lastName
            url
            picture {
              url(size: XS)
            }
          }
          supplier {
            id
            firstName
            lastName
            url
            picture {
              url(size: XS)
            }
          }
        }
        tour {
          picture {
            url
          }
          cancelPolicyRules {
            from
            to
            charge
          }
          id
          title
          price {
            currency {
              symbol
              id
            }
            amount
            model
          }
          duration
        }
        city {
          name
        }
        invoice {
          supplierFee
          cardFee
          total
          currencyId
        }
        user {
          id
          displayName
          picture {
            url(size: XS)
          }
          country {
            name
          }
          phone
          phoneCountryCode
        }
        supplier {
          id
          displayName
          firstName
          isSupplier
          isSupplierApply
          isAdmin
          isBlogger
          payoutMethodStatus
          payoutMethodType
          payoutMethod
          phone
          phoneCountryCode
          stats {
            bookingsCount
          }
          picture {
            url(size: XS)
          }
        }
      }
    }
  }
}
    `;
export const SupplierAsCustomerReviewsFragmentDoc = gql`
    fragment SupplierAsCustomerReviews on Supplier {
  id
  displayName
  payoutMethod
  phone
  url
  bookings(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: {WITH_REVIEW: $withReview, BOOKINGS_STATUS: $bookingStatus}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        title
        meetingDate
        meetingTime
        canReview
        duration
        city {
          name
        }
        supplier {
          id
          displayName
          picture {
            url(size: XS)
          }
        }
        user {
          id
          displayName
          picture {
            url(size: XS)
          }
        }
        review {
          id
          title
          rating
          response
          message
          createdAt
          user {
            id
            displayName
            picture {
              url(size: XS)
            }
          }
          supplier {
            id
            displayName
            picture {
              url(size: XS)
            }
          }
        }
      }
    }
  }
}
    `;
export const SupplierBookingDetailFragmentDoc = gql`
    fragment SupplierBookingDetail on Supplier {
  id
  displayName
  bookingById(id: $id) {
    id
    title
    meetingDate
    meetingTime
    meetinPoint
    meetingPointDescription
    endingTime
    endingPoint
    included
    excluded
    important
    status
    supplierId
    duration
    adultsCount
    childrenCount
    infantCount
    payoutMethod
    code
    canReview
    canCancel
    latestMessageId
    overview
    content
    bookingType
    paymentMethod
    paymentDate
    createdAt
    hotel
    showNamePhoneInUi
    city {
      id
      name
      country {
        id
        name
        url
        slug
      }
    }
    itinerary {
      type
      time
      title
      description
    }
    currency {
      id
      symbol
      minPrice
      quotes {
        currency
        rate
      }
    }
    items {
      id
      description
      quantity
      unitPrice
      totalPrice
      type
    }
    tour {
      id
      title
      slug
      content
      overview
      calendar {
        minDate
        maxDate
        workingDates
        notWorkingDates
        daysOfWeekAvailable
        daysOfWeekBlocked
        bookingsDates
      }
      picture {
        url
      }
      cancelPolicyRules {
        from
        to
        charge
      }
      id
      title
      price {
        currency {
          symbol
          id
        }
        amount
        model
      }
      duration
    }
    payout {
      method
      amount
      payoutAt
      status
      transactionId
      error
    }
    review {
      id
      title
      rating
      response
      message
      createdAt
      photos {
        id
        urlXS
        urlLG
      }
      user {
        id
        firstName
        lastName
        url
        picture {
          url(size: XS)
        }
      }
      supplier {
        id
        firstName
        lastName
        url
        picture {
          url(size: XS)
        }
      }
    }
    city {
      id
      name
      country {
        id
        name
      }
    }
    invoice {
      supplierFee
      cardFee
      total
      cash
      online
      serviceFee
      currencyId
    }
    user {
      id
      displayName
      firstName
      lastName
      picture {
        url(size: XS)
      }
      country {
        name
        dialCode
      }
      phone
      phoneCountryCode
    }
    supplier {
      id
      displayName
      firstName
      lastName
      url
      phone
      commission
      phoneCountryCode
      payoutMethodType
      languages {
        language {
          name
        }
      }
      country {
        id
        name
        dialCode
      }
      workingCountries {
        id
        name
      }
      cities {
        id
        name
        country {
          id
          name
        }
      }
      picture {
        url(size: XS)
      }
      country {
        id
        name
        dialCode
      }
      stats {
        rating
        reviewsCount
      }
      calendar {
        minDate
        maxDate
        workingDates
        notWorkingDates
        daysOfWeekAvailable
        daysOfWeekBlocked
        bookingsDates
      }
    }
  }
}
    `;
export const SupplierBookingsFragmentDoc = gql`
    fragment SupplierBookings on Supplier {
  id
  displayName
  payoutMethod
  phone
  url
  bookingsAsSupplier(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: {SEARCH: $search, BOOKINGS_STATUS: $bookingStatus}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        latestMessageId
        bookingType
        code
        title
        content
        payoutStatus
        status
        supplierId
        duration
        canReview
        canCancel
        adultsCount
        childrenCount
        infantCount
        meetingDate
        meetingTime
        meetinPoint
        hotel
        endingTime
        endingPoint
        included
        excluded
        important
        itinerary {
          type
          time
          title
          description
        }
        review {
          id
          title
          rating
          response
          message
          createdAt
          user {
            id
            firstName
            lastName
            url
            picture {
              url(size: XS)
            }
          }
          supplier {
            id
            firstName
            lastName
            url
            picture {
              url(size: XS)
            }
          }
        }
        tour {
          picture {
            url
          }
          cancelPolicyRules {
            from
            to
            charge
          }
          id
          title
          price {
            currency {
              symbol
              id
            }
            amount
            model
          }
          duration
        }
        city {
          name
        }
        invoice {
          supplierFee
          cardFee
          total
          currencyId
        }
        user {
          id
          displayName
          picture {
            url(size: XS)
          }
          country {
            name
          }
          phone
          phoneCountryCode
        }
        supplier {
          id
          displayName
          firstName
          isSupplier
          isSupplierApply
          isAdmin
          isBlogger
          payoutMethodStatus
          payoutMethodType
          payoutMethod
          phone
          phoneCountryCode
          stats {
            bookingsCount
          }
          picture {
            url(size: XS)
          }
        }
      }
    }
  }
}
    `;
export const SupplierBookingsCalendarFragmentDoc = gql`
    fragment SupplierBookingsCalendar on Supplier {
  id
  displayName
  payoutMethod
  phone
  url
  bookingsAsSupplier(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: {SEARCH: $search, BOOKINGS_STATUS: $bookingStatus, ORDER_BY: $orderBy, MEETING_DATE_FROM: $meetingDateFrom, MEETING_DATE_TO: $meetingDateTo}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        title
        payoutStatus
        status
        duration
        meetingDate
        city {
          name
        }
        user {
          id
          displayName
        }
      }
    }
  }
}
    `;
export const SupplierReviewsFragmentDoc = gql`
    fragment SupplierReviews on Supplier {
  id
  displayName
  payoutMethod
  phone
  url
  bookingsAsSupplier(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: {WITH_REVIEW: $withReview, BOOKINGS_STATUS: $bookingStatus}
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        title
        meetingDate
        meetingTime
        duration
        city {
          name
        }
        supplier {
          id
          displayName
          picture {
            url(size: XS)
          }
        }
        user {
          id
          displayName
          picture {
            url(size: XS)
          }
        }
        review {
          id
          title
          rating
          response
          message
          createdAt
          user {
            id
            displayName
            picture {
              url(size: XS)
            }
          }
          supplier {
            id
            displayName
            picture {
              url(size: XS)
            }
          }
        }
      }
    }
  }
}
    `;
export const CategoryCardFragmentDoc = gql`
    fragment CategoryCard on Category {
  id
  name
  slug
  isActive
  seoContent {
    title
    content
  }
  countPosts
}
    `;
export const CityFooterFragmentDoc = gql`
    fragment CityFooter on City {
  id
  name
  slug
  url
  hasSuppliers
  hasOnlineTours
  hasPrivateTours
  hasTransportationTours
  country {
    id
    name
    slug
    url
    timezone
  }
  timezone
}
    `;
export const CountryCardFragmentDoc = gql`
    fragment CountryCard on Country {
  id
  name
  slug
  url
  picture {
    url(size: SM)
  }
}
    `;
export const CountryFooterFragmentDoc = gql`
    fragment CountryFooter on Country {
  id
  name
  slug
  url
  hasSuppliers
  hasOnlineTours
  hasPrivateTours
  hasTransportationTours
}
    `;
export const PageInfoFieldsFragmentDoc = gql`
    fragment PageInfoFields on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
  page
  totalPages
}
    `;
export const PageInfoTopPageFragmentDoc = gql`
    fragment PageInfoTopPage on PageInfo {
  hasNextPage
  startCursor
  endCursor
}
    `;
export const CityCardFragmentDoc = gql`
    fragment CityCard on City {
  id
  name
  slug
  url
  picture {
    url(size: SM)
  }
  country {
    id
    name
    url
  }
}
    `;
export const PostCardFragmentDoc = gql`
    fragment PostCard on Post {
  id
  title
  overview
  url
  contentUpdatedAt
  picture {
    url(size: SM)
  }
  city {
    ...CityCard
  }
  country {
    id
    name
    slug
  }
  category {
    id
    name
    slug
  }
  user {
    ... on User {
      id
      displayName
      isSupplier
      isAdmin
      isBlogger
      picture {
        url(size: XS)
      }
    }
    ... on Supplier {
      id
      displayName
      isSupplier
      isAdmin
      isBlogger
      picture {
        url(size: XS)
      }
    }
  }
}
    ${CityCardFragmentDoc}`;
export const UserCardFragmentDoc = gql`
    fragment UserCard on User {
  id
  displayName
  isSupplier
  isAdmin
  isBlogger
  url
  biography
  picture {
    url(size: SM)
  }
  gallery {
    src: url(size: MD)
  }
}
    `;
export const ReviewCardFragmentDoc = gql`
    fragment ReviewCard on Review {
  id
  title
  message
  response
  rating
  createdAt
  user {
    ...UserCard
  }
  supplier {
    id
    displayName
    url
    stats {
      reviewsCount
    }
    picture {
      url(size: XS)
    }
  }
  photos {
    id
    urlXS
    urlLG
  }
  booking {
    title
    duration
    city {
      name
    }
    tour {
      id
      title
      url
      picture {
        url
      }
    }
    city {
      name
    }
  }
}
    ${UserCardFragmentDoc}`;
export const SupplierCardFragmentDoc = gql`
    fragment SupplierCard on Supplier {
  id
  displayName
  biography
  isSupplier
  isAdmin
  isBlogger
  url
  country {
    id
    name
  }
  city {
    id
    name
  }
  picture {
    url(size: SM)
    urlXS
  }
  stats {
    reviewsCount
    rating
  }
}
    `;
export const TourTopPageCardFragmentDoc = gql`
    fragment TourTopPageCard on Tour {
  id
  title
  overview
  content
  duration
  url
  productType
  country {
    id
    name
  }
  city {
    id
    name
  }
  category {
    id
    name
  }
  picture {
    url(size: SM)
  }
  price {
    currency {
      id
      quotes {
        currency
        rate
      }
    }
    amount
    model
  }
  supplier {
    id
    displayName
    picture {
      url(size: XS)
    }
    stats {
      reviewsCount
      rating
    }
  }
}
    `;
export const GetToursFiltersAvailableDocument = gql`
    query getToursFiltersAvailable($filters: TourFilters) {
  getToursFiltersAvailable(filters: $filters) {
    categories {
      id
      name
      slug
    }
    durations
    languages {
      id
      name
      code
    }
  }
}
    `;
export const GetSuppliersFiltersAvailableDocument = gql`
    query getSuppliersFiltersAvailable($filters: SupplierFilters) {
  getSuppliersFiltersAvailable(filters: $filters) {
    categories {
      id
      name
      slug
    }
    durations
    languages {
      id
      name
      code
    }
  }
}
    `;
export const ActiveAnnouncementDocument = gql`
    query activeAnnouncement {
  activeAnnouncement {
    id
    announcement
    expiresAt
    status
    priority
  }
}
    `;
export const BlogCategoryBySlugDocument = gql`
    query blogCategoryBySlug($slug: String!) {
  blogCategoryBySlug(slug: $slug) {
    ...CategoryCard
  }
}
    ${CategoryCardFragmentDoc}`;
export const CategoryBySlugDocument = gql`
    query categoryBySlug($slug: String!) {
  categoryBySlug(slug: $slug) {
    ...CategoryCard
  }
}
    ${CategoryCardFragmentDoc}`;
export const TourCategoriesDocument = gql`
    query tourCategories($first: Int, $before: String, $last: Int, $after: String, $filters: CategoryFilters) {
  tourCategories(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: $filters
  ) {
    edges {
      node {
        ...CategoryCard
      }
    }
  }
}
    ${CategoryCardFragmentDoc}`;
export const PostCategoriesDocument = gql`
    query postCategories($first: Int, $before: String, $last: Int, $after: String, $filters: CategoryFilters) {
  postCategories(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: $filters
  ) {
    edges {
      node {
        ...CategoryCard
      }
    }
  }
}
    ${CategoryCardFragmentDoc}`;
export const TourCategoriesForTourCreationDocument = gql`
    query tourCategoriesForTourCreation($filters: CategoryFilters) {
  tourCategoriesForTourCreation(filters: $filters) {
    ...CategoryCard
  }
}
    ${CategoryCardFragmentDoc}`;
export const MeCustomerAccountDocument = gql`
    query meCustomerAccount {
  me {
    __typename
    ... on User {
      id
      firstName
      lastName
      email
      phone
      gender
      birthday
      isSocialLoginEnabled
      isPersonalInfoUpdated
      isPasswordUpdated
      phoneCountryCode
      biography
      isBiographyUpdated
      country {
        id
        name
      }
      pictureId
      picture {
        url(size: XS)
      }
    }
    ... on Supplier {
      id
    }
  }
}
    `;
export const FaqByIdDocument = gql`
    query faqById($id: Int!) {
  faqById(id: $id) {
    id
    categoryId
    title
    title_en
    position
    countViews
    content
    content_en
    url
    relatedFaqs {
      id
      title_en
      title
      url
    }
    category {
      id
      title
      title_en
      url
      parentCategory {
        id
        title
        title_en
        url
      }
    }
  }
}
    `;
export const FaqCategoryByIdDocument = gql`
    query faqCategoryById($id: Int!) {
  faqCategoryById(id: $id) {
    id
    parentId
    title
    title_en
    position
    faqsCount
    url
    faqs {
      id
      title
      title_en
      content
      content_en
    }
    parentCategory {
      id
      title
      title_en
      url
    }
    subCategories {
      id
      parentId
      title
      title_en
      position
      faqsCount
      url
      faqs {
        id
        title
        title_en
        content
        content_en
      }
    }
  }
}
    `;
export const GetFaqCategoriesDocument = gql`
    query getFaqCategories($sizePerPage: Int, $page: Int, $filters: FaqCategoryFilters) {
  faqCategories(sizePerPage: $sizePerPage, page: $page, filters: $filters) {
    __typename
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      page
      totalPages
    }
    totalCount
    edges {
      node {
        id
        parentId
        title
        title_en
        position
        faqsCount
        url
        subCategories {
          id
          parentId
          title
          title_en
          position
          faqsCount
          url
        }
      }
    }
  }
}
    `;
export const GetFaqsDocument = gql`
    query getFaqs($sizePerPage: Int, $page: Int, $filters: FaqsFilters) {
  faqs(sizePerPage: $sizePerPage, page: $page, filters: $filters) {
    __typename
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      page
      totalPages
    }
    totalCount
    edges {
      node {
        id
        categoryId
        title
        title_en
        position
        countViews
        content
        content_en
        category {
          id
          title
          title_en
          parentId
        }
      }
    }
  }
}
    `;
export const HasSupportAccessDocument = gql`
    query hasSupportAccess {
  hasSupportAccess {
    success
  }
}
    `;
export const IncrementFaqViewCountDocument = gql`
    mutation IncrementFaqViewCount($id: Int!) {
  IncrementFaqViewCount(id: $id)
}
    `;
export const SupplierContactSupportDocument = gql`
    mutation SupplierContactSupport($input: SupplierContactSupportInput!) {
  SupplierContactSupport(input: $input)
}
    `;
export const LanguagesDocument = gql`
    query languages($first: Int, $before: String, $last: Int, $after: String, $filters: LanguageFilters) {
  languages(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: $filters
  ) {
    edges {
      node {
        id
        name
        code
      }
    }
  }
}
    `;
export const CitiesDocument = gql`
    query cities($first: Int, $before: String, $last: Int, $after: String, $filters: CityFilters) {
  cities(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: $filters
  ) {
    edges {
      node {
        ...CityCard
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${CityCardFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const CitiesWithSuppliersDocument = gql`
    query citiesWithSuppliers($filters: CityFilters!, $suppliersFilters: SupplierFilters, $limit: Int, $suppliersLimit: Int) {
  cities(filters: $filters, first: $limit) {
    edges {
      node {
        id
        name
        slug
        suppliers(filters: $suppliersFilters, limit: $suppliersLimit) {
          ...SupplierCard
        }
      }
    }
  }
}
    ${SupplierCardFragmentDoc}`;
export const CitiesWithToursDocument = gql`
    query citiesWithTours($filters: CityFilters!, $toursFilters: TourFilters, $limit: Int, $toursLimit: Int) {
  cities(filters: $filters, first: $limit) {
    edges {
      node {
        id
        name
        slug
        tours(filters: $toursFilters, limit: $toursLimit) {
          ...TourCard
        }
      }
    }
  }
}
    ${TourCardFragmentDoc}`;
export const CityBySlugDocument = gql`
    query cityBySlug($slug: String!, $locationStatsFilters: LocationStatsFilters) {
  cityBySlug(slug: $slug) {
    id
    name
    slug
    url
    hasSuppliers
    hasOnlineTours
    hasPrivateTours
    hasTransportationTours
    seoContent {
      top {
        title
        content
      }
      tours {
        title
        content
      }
      guides {
        title
        content
      }
      online {
        title
        content
      }
      transportation {
        title
        content
      }
    }
    picture {
      url(size: LG)
      urlSM
      serpImage
    }
    country {
      id
      name
      slug
      url
      isActive
    }
    relatedCities {
      ...CityCard
    }
    faqs {
      question
      answer
    }
    stats(filters: $locationStatsFilters) {
      reviewsCount
      reviewsRating
    }
  }
}
    ${CityCardFragmentDoc}`;
export const CountriesDocument = gql`
    query countries($first: Int, $before: String, $last: Int, $after: String, $filters: CountryFilters) {
  countries(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: $filters
  ) {
    edges {
      node {
        ...CountryCard
      }
    }
  }
}
    ${CountryCardFragmentDoc}`;
export const CountryBySlugDocument = gql`
    query countryBySlug($slug: String!, $locationStatsFilters: LocationStatsFilters) {
  countryBySlug(slug: $slug) {
    id
    name
    slug
    url
    hasSuppliers
    hasOnlineTours
    hasPrivateTours
    hasTransportationTours
    seoContent {
      top {
        title
        content
      }
      tours {
        title
        content
      }
      guides {
        title
        content
      }
      online {
        title
        content
      }
      transportation {
        title
        content
      }
    }
    picture {
      url(size: LG)
      urlSM
      serpImage
    }
    faqs {
      question
      answer
    }
    isActive
    stats(filters: $locationStatsFilters) {
      reviewsCount
      reviewsRating
    }
  }
}
    `;
export const LocationsDocument = gql`
    query locations($limit: Int!, $filters: LocationFilters) {
  locations(limit: $limit, filters: $filters) {
    __typename
    ... on City {
      name
      url
      slug
      country {
        name
      }
    }
    ... on Country {
      name
      url
      slug
    }
  }
}
    `;
export const LocationsWithDataDocument = gql`
    query locationsWithData($limit: Int!, $filters: LocationFilters) {
  locations(limit: $limit, filters: $filters) {
    __typename
    ... on City {
      name
      url
      slug
      country {
        name
      }
      picture {
        url(size: LG)
        urlSM
        serpImage
      }
    }
    ... on Country {
      name
      url
      slug
      picture {
        url(size: LG)
        urlSM
        serpImage
      }
    }
  }
}
    `;
export const CityBySlugWithCountryDocument = gql`
    query cityBySlugWithCountry($slug: String!) {
  cityBySlug(slug: $slug) {
    id
    name
    slug
    url
    country {
      id
      name
      slug
      url
      isActive
    }
  }
}
    `;
export const MeContextDocument = gql`
    query meContext {
  me {
    __typename
    ... on User {
      ...UserContext
    }
    ... on Supplier {
      ...SupplierContext
    }
  }
}
    ${UserContextFragmentDoc}
${SupplierContextFragmentDoc}`;
export const MeContextBasicDocument = gql`
    query meContextBasic {
  me {
    __typename
    ... on User {
      ...UserBasicContext
    }
    ... on Supplier {
      ...SupplierBasicContext
    }
  }
}
    ${UserBasicContextFragmentDoc}
${SupplierBasicContextFragmentDoc}`;
export const MeContextForBlogCreationDocument = gql`
    query meContextForBlogCreation {
  me {
    __typename
    ... on User {
      ...UserContextForBlogCreate
    }
    ... on Supplier {
      ...SupplierContextForBlogCreate
    }
  }
}
    ${UserContextForBlogCreateFragmentDoc}
${SupplierContextForBlogCreateFragmentDoc}`;
export const MeContextForSupplierApplyDocument = gql`
    query meContextForSupplierApply {
  meForSupplierApply {
    id
    displayName
    firstName
    lastName
    isSupplier
    isSupplierApply
    guideApplyStep
    isAdmin
    isBlogger
    email
    phone
    phoneCountryCode
    biography
    guideProfileSteps
    country {
      id
      name
    }
    stats {
      bookingsCount
    }
    picture {
      url(size: XS)
    }
    gender
    birthday
    nationality {
      id
      name
    }
    address
    postalCode
    city {
      id
      name
    }
    cities {
      id
      name
    }
    languages {
      id
      level
      language {
        id
        name
        code
      }
    }
    workingDays {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
    important
    guidePhotoVerification
    guidePhotoWorkingPermit
    guidePhotoLicense
    youNeedGuideLicense
    haveGuideLicense
    pictureId
    notes
    guidePhotoVerificationUrl {
      id
      url
      name
    }
    guidePhotoLicenseUrl {
      id
      url
      name
    }
    guidePhotoWorkingPermitUrl {
      id
      url
      name
    }
  }
}
    `;
export const CreateFileDocument = gql`
    mutation CreateFile($input: CreateFileInput!) {
  CreateFile(input: $input) {
    picture {
      id
      name
      tableId
      tableName
      position
    }
  }
}
    `;
export const CreateFileSignedUrlDocument = gql`
    mutation CreateFileSignedUrl($input: CreateFileSignedUrlInput!) {
  CreateFileSignedUrl(input: $input) {
    fileName
    url
  }
}
    `;
export const GetHighQualityImageFromUrlDocument = gql`
    query getHighQualityImageFromUrl($url: String!) {
  getHighQualityImageFromUrl(url: $url)
}
    `;
export const CreateMessageDocument = gql`
    mutation CreateMessage($input: CreateMessageInput!) {
  CreateMessage(input: $input) {
    isSent
    message {
      id
    }
  }
}
    `;
export const CreatePaymentRequestDocument = gql`
    mutation CreatePaymentRequest($input: CreatePaymentRequestInput!) {
  CreatePaymentRequest(input: $input) {
    message {
      id
    }
    booking {
      id
    }
  }
}
    `;
export const CreateTourOfferDocument = gql`
    mutation CreateTourOffer($input: CreateTourOfferInput!) {
  CreateTourOffer(input: $input) {
    message {
      id
    }
    booking {
      id
    }
  }
}
    `;
export const CustomerMessageBookingsByIdDocument = gql`
    query CustomerMessageBookingsById($id: Int!, $pageNo: Int!) {
  GetBookingsByMessageId(id: $id, pageNo: $pageNo) {
    hasMoreBookings
    message {
      id
      message
      isRead
      archived
      thread
      isImportant
      hide
      createdAt
      from {
        ... on User {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
        }
        ... on Supplier {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
          stats {
            reviewsCount
            rating
          }
        }
      }
      to {
        ... on User {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
        }
        ... on Supplier {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          stats {
            reviewsCount
            rating
          }
          country {
            name
          }
        }
      }
    }
    bookings {
      id
      code
      title
      meetingDate
      meetingTime
      payoutStatus
      status
      duration
      supplierId
      adultsCount
      childrenCount
      infantCount
      city {
        name
      }
      duration
      invoice {
        supplierFee
        cardFee
        total
        currencyId
      }
      currency {
        id
        symbol
        minPrice
        quotes {
          currency
          rate
        }
      }
      user {
        displayName
        picture {
          url(size: XS)
        }
      }
      supplier {
        id
        displayName
        firstName
        isSupplier
        isSupplierApply
        isAdmin
        isBlogger
        phone
        phoneCountryCode
        payoutMethodStatus
        payoutMethodType
        payoutMethod
        stats {
          bookingsCount
        }
        picture {
          url(size: XS)
        }
      }
    }
  }
}
    `;
export const CustomerMessageByIdDocument = gql`
    query CustomerMessageById($id: Int!, $pageNo: Int!) {
  GetMessageById(id: $id, pageNo: $pageNo) {
    message {
      id
      message
      isRead
      archived
      thread
      isImportant
      hide
      createdAt
      from {
        ... on User {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
        }
        ... on Supplier {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
          stats {
            reviewsCount
            rating
          }
        }
      }
      to {
        ... on User {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
        }
        ... on Supplier {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          stats {
            reviewsCount
            rating
          }
          country {
            name
          }
        }
      }
    }
    messageThread {
      isBooked
      totalCount
      isImportant
      isArchived
      hasMoreMessage
      messages {
        id
        message
        hide
        tour {
          id
          title
          duration
          url
          picture {
            url
          }
          price {
            currency {
              symbol
              id
              quotes {
                currency
                rate
              }
            }
            amount
            model
          }
        }
        tourRequest {
          city {
            id
            name
            country {
              id
              name
            }
          }
          tour {
            id
            title
            duration
            url
            picture {
              url
            }
            price {
              currency {
                symbol
                id
                quotes {
                  currency
                  rate
                }
              }
              amount
              model
            }
          }
          meetingDate
          meetingTime
          accomodationAddress
          adultsCount
          infantCount
          childrenCount
        }
        isRead
        archived
        thread
        isImportant
        booking {
          id
          code
          title
          bookingType
          meetingDate
          meetingTime
          payoutStatus
          status
          duration
          supplierId
          adultsCount
          childrenCount
          infantCount
          city {
            name
          }
          duration
          invoice {
            supplierFee
            cardFee
            total
            currencyId
          }
          currency {
            id
            symbol
            minPrice
            quotes {
              currency
              rate
            }
          }
          user {
            displayName
            picture {
              url
            }
          }
          supplier {
            id
            displayName
            firstName
            isSupplier
            isSupplierApply
            isAdmin
            isBlogger
            phone
            phoneCountryCode
            payoutMethodStatus
            payoutMethodType
            payoutMethod
            stats {
              bookingsCount
            }
            picture {
              url(size: XS)
            }
          }
        }
        createdAt
        from {
          ... on User {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
          }
          ... on Supplier {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
            stats {
              reviewsCount
              rating
            }
          }
        }
        to {
          ... on User {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
          }
          ... on Supplier {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
            stats {
              reviewsCount
              rating
            }
          }
        }
      }
      latestMessage {
        id
        message
        isRead
        archived
        thread
        isImportant
        createdAt
      }
    }
  }
}
    `;
export const CustomerMessagesDocument = gql`
    query CustomerMessages($first: Int, $before: String, $last: Int, $after: String, $search: String, $inbox: MessageInbox) {
  me {
    __typename
    ... on User {
      ...CustomerMessages
    }
  }
}
    ${CustomerMessagesFragmentDoc}`;
export const IsMessageThreadExistsDocument = gql`
    mutation IsMessageThreadExists($userId1: Int!, $userId2: Int!) {
  IsMessageThreadExists(userId1: $userId1, userId2: $userId2)
}
    `;
export const NewMessageWithBookingDocument = gql`
    mutation NewMessageWithBooking($input: NewMessageWithBookingInput!) {
  NewMessageWithBooking(input: $input) {
    isSent
    message {
      id
    }
  }
}
    `;
export const ReplyMessageDocument = gql`
    mutation ReplyMessage($input: ReplyMessageInput!) {
  ReplyMessage(input: $input) {
    isSent
    message {
      id
      message
      isRead
      archived
      thread
      isImportant
      hide
      tour {
        id
        title
        duration
        url
        picture {
          url
        }
        price {
          currency {
            symbol
            id
            quotes {
              currency
              rate
            }
          }
          amount
          model
        }
      }
      createdAt
      from {
        ... on User {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
        }
        ... on Supplier {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
          stats {
            reviewsCount
            rating
          }
        }
      }
      to {
        ... on User {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
        }
        ... on Supplier {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          stats {
            reviewsCount
            rating
          }
          country {
            name
          }
        }
      }
    }
  }
}
    `;
export const ReportMessageByIdDocument = gql`
    mutation ReportMessageById($input: ReportMessageByIdInput!) {
  ReportMessageById(input: $input) {
    message {
      id
    }
    success
  }
}
    `;
export const ShouldMessageSecuredDocument = gql`
    mutation ShouldMessageSecured($id: Int!, $content: String!) {
  ShouldMessageSecured(id: $id, content: $content)
}
    `;
export const SupplierMessageBookingsByIdDocument = gql`
    query SupplierMessageBookingsById($id: Int!, $pageNo: Int!) {
  GetBookingsByMessageId(id: $id, pageNo: $pageNo) {
    hasMoreBookings
    message {
      id
      message
      isRead
      archived
      thread
      isImportant
      hide
      createdAt
      from {
        ... on User {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
        }
        ... on Supplier {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
          stats {
            reviewsCount
            rating
          }
        }
      }
      to {
        ... on User {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
        }
        ... on Supplier {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          stats {
            reviewsCount
            rating
          }
          country {
            name
          }
        }
      }
    }
    bookings {
      id
      code
      title
      meetingDate
      meetingTime
      payoutStatus
      status
      duration
      supplierId
      adultsCount
      childrenCount
      infantCount
      city {
        name
      }
      duration
      invoice {
        supplierFee
        cardFee
        total
        currencyId
      }
      currency {
        id
        symbol
        minPrice
        quotes {
          currency
          rate
        }
      }
      user {
        displayName
        picture {
          url(size: XS)
        }
      }
      supplier {
        id
        displayName
        firstName
        isSupplier
        isSupplierApply
        isAdmin
        isBlogger
        phone
        phoneCountryCode
        payoutMethodStatus
        payoutMethodType
        payoutMethod
        stats {
          bookingsCount
        }
        picture {
          url(size: XS)
        }
      }
    }
  }
}
    `;
export const SupplierMessageByIdDocument = gql`
    query SupplierMessageById($id: Int!, $pageNo: Int!) {
  GetMessageById(id: $id, pageNo: $pageNo) {
    message {
      id
      message
      isRead
      archived
      thread
      isImportant
      hide
      createdAt
      from {
        ... on User {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
        }
        ... on Supplier {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
          stats {
            reviewsCount
            rating
          }
        }
      }
      to {
        ... on User {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          country {
            name
          }
        }
        ... on Supplier {
          id
          displayName
          url
          picture {
            url(size: XS)
          }
          stats {
            reviewsCount
            rating
          }
          country {
            name
          }
        }
      }
    }
    messageThread {
      isBooked
      totalCount
      isImportant
      isArchived
      hasMoreMessage
      messages {
        id
        message
        hide
        tour {
          id
          title
          duration
          url
          picture {
            url
          }
          price {
            currency {
              symbol
              id
              quotes {
                currency
                rate
              }
            }
            amount
            model
          }
        }
        tourRequest {
          city {
            id
            name
            country {
              id
              name
            }
          }
          tour {
            id
            title
            duration
            url
            picture {
              url
            }
            price {
              currency {
                symbol
                id
                quotes {
                  currency
                  rate
                }
              }
              amount
              model
            }
          }
          meetingDate
          meetingTime
          accomodationAddress
          adultsCount
          infantCount
          childrenCount
        }
        isRead
        archived
        thread
        isImportant
        booking {
          id
          code
          title
          bookingType
          meetingDate
          meetingTime
          payoutStatus
          status
          duration
          supplierId
          adultsCount
          childrenCount
          infantCount
          city {
            name
          }
          duration
          invoice {
            supplierFee
            cardFee
            total
            currencyId
          }
          currency {
            id
            symbol
            minPrice
            quotes {
              currency
              rate
            }
          }
          user {
            displayName
            picture {
              url(size: XS)
            }
          }
          supplier {
            id
            displayName
            firstName
            isSupplier
            isSupplierApply
            isAdmin
            isBlogger
            phone
            phoneCountryCode
            payoutMethodStatus
            payoutMethodType
            payoutMethod
            stats {
              bookingsCount
            }
            picture {
              url(size: XS)
            }
          }
        }
        createdAt
        from {
          ... on User {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
          }
          ... on Supplier {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
            stats {
              reviewsCount
              rating
            }
          }
        }
        to {
          ... on User {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
          }
          ... on Supplier {
            id
            displayName
            url
            picture {
              url(size: XS)
            }
            stats {
              reviewsCount
              rating
            }
          }
        }
      }
      latestMessage {
        id
        message
        isRead
        archived
        thread
        isImportant
        createdAt
      }
    }
  }
}
    `;
export const SupplierMessagesDocument = gql`
    query SupplierMessages($first: Int, $before: String, $last: Int, $after: String, $search: String, $inbox: MessageInbox) {
  me {
    __typename
    ... on Supplier {
      ...SupplierMessages
    }
  }
}
    ${SupplierMessagesFragmentDoc}`;
export const UpdateMessageDocument = gql`
    mutation UpdateMessage($id: Int!, $input: UpdateMessageInput!) {
  UpdateMessage(id: $id, input: $input) {
    message {
      id
    }
  }
}
    `;
export const UpdateMessageAsReadDocument = gql`
    mutation UpdateMessageAsRead($id: Int!, $isRead: Boolean!) {
  UpdateMessageAsRead(id: $id, isRead: $isRead) {
    message {
      id
    }
  }
}
    `;
export const UpdateMessageThreadDocument = gql`
    mutation UpdateMessageThread($id: Int!, $input: UpdateMessageThreadInput!) {
  UpdateMessageThread(id: $id, input: $input) {
    message {
      id
    }
  }
}
    `;
export const UserNotificationDocument = gql`
    query UserNotification {
  me {
    __typename
    ... on User {
      id
      customerNotification {
        unReadMessagesCount
      }
    }
    ... on Supplier {
      id
      supplierNotification {
        unReadMessagesCount
        paidBookingsCount
        reviewNotRepliedCount
      }
    }
  }
}
    `;
export const CityTopPageDocument = gql`
    query cityTopPage($slug: String!) {
  cityTopPage(slug: $slug) {
    hasMoreTours
    hasMoreSuppliers
    hasMoreTransportationTours
    hasMoreNewTours
    hasMoreNewSuppliers
    city {
      id
      name
      slug
      url
      ...CityFooter
      seoContent {
        top {
          title
          content
        }
        tours {
          title
          content
        }
        guides {
          title
          content
        }
        online {
          title
          content
        }
        transportation {
          title
          content
        }
      }
      picture {
        url(size: LG)
        urlSM
        serpImage
      }
      country {
        id
        name
        slug
        url
        isActive
      }
      relatedCities {
        ...CityCard
      }
      faqs {
        question
        answer
      }
      stats {
        reviewsCount
        reviewsRating
      }
    }
    tours {
      ...TourCard
    }
    suppliers {
      ...SupplierCard
    }
    newTours {
      ...TourCard
    }
    newSuppliers {
      ...SupplierCard
    }
    onlineTours {
      ...TourCard
    }
    transportationTours {
      ...TourCard
    }
  }
}
    ${CityFooterFragmentDoc}
${CityCardFragmentDoc}
${TourCardFragmentDoc}
${SupplierCardFragmentDoc}`;
export const CountryTopPageDocument = gql`
    query countryTopPage($slug: String!) {
  countryTopPage(slug: $slug) {
    hasMoreTours
    hasMoreSuppliers
    hasMoreTransportationTours
    hasMoreNewTours
    hasMoreNewSuppliers
    hasMoreCities
    country {
      id
      name
      slug
      url
      ...CountryFooter
      seoContent {
        top {
          title
          content
        }
        tours {
          title
          content
        }
        guides {
          title
          content
        }
        online {
          title
          content
        }
        transportation {
          title
          content
        }
      }
      picture {
        url(size: LG)
        urlSM
        serpImage
      }
      faqs {
        question
        answer
      }
      isActive
      stats {
        reviewsCount
        reviewsRating
      }
    }
    tours {
      ...TourCard
    }
    suppliers {
      ...SupplierCard
    }
    newTours {
      ...TourCard
    }
    newSuppliers {
      ...SupplierCard
    }
    cities {
      id
      name
      slug
      url
      seoContent {
        top {
          title
          content
        }
        tours {
          title
          content
        }
        guides {
          title
          content
        }
        online {
          title
          content
        }
        transportation {
          title
          content
        }
      }
      picture {
        url(size: SM)
      }
      country {
        id
        name
        slug
        url
        isActive
      }
      relatedCities {
        ...CityCard
      }
      faqs {
        question
        answer
      }
      stats {
        reviewsCount
        reviewsRating
      }
    }
    onlineTours {
      ...TourCard
    }
    transportationTours {
      ...TourCard
    }
  }
}
    ${CountryFooterFragmentDoc}
${TourCardFragmentDoc}
${SupplierCardFragmentDoc}
${CityCardFragmentDoc}`;
export const TopPageDocument = gql`
    query topPage {
  topPage {
    tours {
      ...TourTopPageCard
    }
    suppliers {
      ...SupplierCard
    }
    newTours {
      ...TourTopPageCard
    }
    newSuppliers {
      ...SupplierCard
    }
    cars {
      ...TourTopPageCard
    }
    countries {
      id
      name
      slug
      url
      picture {
        url(size: SM)
      }
    }
    citiesJapan {
      id
      name
      slug
      url
      picture {
        url(size: SM)
      }
      ...CityFooter
    }
    citiesOthers {
      id
      name
      slug
      url
      picture {
        url(size: SM)
      }
      ...CityFooter
    }
    stats {
      reviewsCount
    }
  }
}
    ${TourTopPageCardFragmentDoc}
${SupplierCardFragmentDoc}
${CityFooterFragmentDoc}`;
export const CompleteBlogCreationDocument = gql`
    mutation CompleteBlogCreation($input: CompleteBlogCreationInput!) {
  CompleteBlogCreation(input: $input) {
    post {
      id
      title
    }
  }
}
    `;
export const CreateBlogDocument = gql`
    mutation CreateBlog($input: CreateBlogInput!) {
  CreateBlog(input: $input) {
    post {
      id
      title
    }
  }
}
    `;
export const DeleteBlogDocument = gql`
    mutation DeleteBlog($id: Int!) {
  DeleteBlog(id: $id)
}
    `;
export const IncrementPostViewsDocument = gql`
    mutation incrementPostViews($id: Int!) {
  incrementPostViews(id: $id) {
    isUpdated
  }
}
    `;
export const MyBlogsDocument = gql`
    query MyBlogs($first: Int, $before: String, $last: Int, $after: String, $page: Int, $sizePerPage: Int, $filters: MyPostFilters) {
  me {
    __typename
    ... on User {
      ...UserBlogContext
    }
    ... on Supplier {
      ...SupplierBlogContext
    }
  }
}
    ${UserBlogContextFragmentDoc}
${SupplierBlogContextFragmentDoc}`;
export const PostsDocument = gql`
    query posts($first: Int, $before: String, $last: Int, $after: String, $page: Int, $sizePerPage: Int, $random: Int, $filters: PostFilters) {
  posts(
    first: $first
    before: $before
    last: $last
    after: $after
    page: $page
    sizePerPage: $sizePerPage
    random: $random
    filters: $filters
  ) {
    edges {
      node {
        ...PostCard
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${PostCardFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const RelatedPostsDocument = gql`
    query RelatedPosts($postId: Int!) {
  RelatedPosts(postId: $postId) {
    post {
      id
      title
      url
      overview
      content
      isActive
      contentUpdatedAt
      picture {
        id
        url(size: SM)
      }
      category {
        id
        name
        slug
      }
      language {
        id
        name
        code
      }
      user {
        ... on User {
          ...UserCard
        }
        ... on Supplier {
          ...SupplierCard
        }
      }
      city {
        ...CityCard
      }
      country {
        id
        name
        slug
      }
    }
  }
}
    ${UserCardFragmentDoc}
${SupplierCardFragmentDoc}
${CityCardFragmentDoc}`;
export const UpdateBlogDocument = gql`
    mutation UpdateBlog($input: UpdateBlogInput!, $id: Int!) {
  UpdateBlog(input: $input, id: $id) {
    post {
      id
      title
    }
  }
}
    `;
export const PostByIdDocument = gql`
    query postById($id: Int!) {
  postById(id: $id) {
    id
    title
    url
    overview
    content
    isActive
    contentUpdatedAt
    picture {
      id
      url
    }
    category {
      id
      name
      slug
    }
    language {
      id
      name
      code
    }
    user {
      ... on User {
        ...UserCard
      }
      ... on Supplier {
        ...SupplierCard
      }
    }
    city {
      ...CityCard
      ...CityFooter
    }
    country {
      ...CountryFooter
    }
  }
}
    ${UserCardFragmentDoc}
${SupplierCardFragmentDoc}
${CityCardFragmentDoc}
${CityFooterFragmentDoc}
${CountryFooterFragmentDoc}`;
export const GetDataToAboutUsDocument = gql`
    query GetDataToAboutUs {
  GetDataToAboutUs {
    totalReviews
    countriesWithGuides
    registeredGuides
  }
}
    `;
export const CreateReviewDocument = gql`
    mutation CreateReview($input: CreateReviewInput!) {
  CreateReview(input: $input) {
    review {
      id
      title
      rating
      message
      response
      createdAt
      user {
        id
        displayName
        picture {
          url(size: XS)
        }
      }
      supplier {
        id
        displayName
        picture {
          url(size: XS)
        }
      }
    }
  }
}
    `;
export const ReplyReviewDocument = gql`
    mutation ReplyReview($input: ReplyReviewInput!) {
  ReplyReview(input: $input) {
    review {
      id
      title
      rating
      message
      response
      createdAt
      user {
        id
        displayName
        picture {
          url(size: XS)
        }
      }
      supplier {
        id
        displayName
        picture {
          url(size: XS)
        }
      }
    }
  }
}
    `;
export const ReviewsDocument = gql`
    query reviews($first: Int, $before: String, $last: Int, $after: String, $filters: ReviewFilters) {
  reviews(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: $filters
  ) {
    edges {
      node {
        ...ReviewCard
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${ReviewCardFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const ReviewsInTopPageDocument = gql`
    query reviewsInTopPage($first: Int, $before: String, $last: Int, $after: String, $filters: ReviewFilters) {
  reviews(
    first: $first
    before: $before
    last: $last
    after: $after
    filters: $filters
  ) {
    edges {
      node {
        ...ReviewCard
      }
    }
    pageInfo {
      ...PageInfoTopPage
    }
  }
}
    ${ReviewCardFragmentDoc}
${PageInfoTopPageFragmentDoc}`;
export const UpdateReviewPhotoFlagDocument = gql`
    mutation UpdateReviewPhotoFlag($id: Int!) {
  updateReviewPhotoFlag(id: $id) {
    id
  }
}
    `;
export const SearchPageTabsDataDocument = gql`
    query SearchPageTabsData($filters: SearchFilters!) {
  searchPageTabsData(filters: $filters) {
    hasSupplier
    hasTour
    hasVirtualTour
    hasCar
    country {
      id
      name
      slug
      url
    }
    city {
      id
      name
      slug
      url
    }
  }
}
    `;
export const GetEnviromentInfoDocument = gql`
    query GetEnviromentInfo {
  getEnviromentInfo {
    database {
      name
      host
    }
    api {
      enviroment
    }
  }
}
    `;
export const SitemapBlogWritersDocument = gql`
    query SitemapBlogWriters {
  sitemap {
    authors {
      url
      lastMod
    }
  }
}
    `;
export const SitemapDestinationsDocument = gql`
    query SitemapDestinations {
  sitemap {
    destinations {
      url
      lastMod
    }
  }
}
    `;
export const SitemapPostsDocument = gql`
    query SitemapPosts {
  sitemap {
    blogs {
      url
      lastMod
    }
  }
}
    `;
export const SitemapSuppliersDocument = gql`
    query SitemapSuppliers {
  sitemap {
    suppliers {
      url
      lastMod
    }
  }
}
    `;
export const SitemapToursDocument = gql`
    query SitemapTours {
  sitemap {
    tours {
      url
      lastMod
    }
  }
}
    `;
export const StatsDocument = gql`
    query stats($filters: StatsFilters) {
  stats(filters: $filters) {
    reviewsCount
  }
}
    `;
export const MeSupplierAccountDocument = gql`
    query meSupplierAccount {
  me {
    __typename
    ... on User {
      id
    }
    ... on Supplier {
      id
      firstName
      lastName
      email
      isPersonalInfoUpdated
      isPasswordUpdated
      isBiographyUpdated
      isProfileVideoUpdated
      isWorkingAreasUpdated
      isLanguagesUpdated
      isGlobalPreferenceUpdated
      isBookingConditionsUpdated
      isPaymentsUpdated
      pictureId
      gallery {
        id
        url(size: MD)
        caption
        position
      }
    }
  }
}
    `;
export const MeSupplierAccountBiographyInfoDocument = gql`
    query MeSupplierAccountBiographyInfo {
  me {
    __typename
    ... on User {
      id
      biography
    }
    ... on Supplier {
      id
      biography
    }
  }
}
    `;
export const MeSupplierAccountBookingConditionsDocument = gql`
    query MeSupplierAccountBookingConditions {
  me {
    __typename
    ... on User {
      id
    }
    ... on Supplier {
      id
      maxAllowedPeople
      important
      guideBookingNotice
      guideBookingWindow
      workingDays {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      analytics {
        conversionRate
      }
    }
  }
}
    `;
export const MeSupplierAccountLanguagesDocument = gql`
    query MeSupplierAccountLanguages {
  me {
    __typename
    ... on User {
      id
    }
    ... on Supplier {
      id
      languages {
        level
        language {
          id
          name
        }
      }
    }
  }
}
    `;
export const MeSupplierAccountPersonalInfoDocument = gql`
    query meSupplierAccountPersonalInfo {
  me {
    __typename
    ... on User {
      id
    }
    ... on Supplier {
      id
      firstName
      lastName
      email
      phone
      gender
      birthday
      phoneCountryCode
      isSupplier
      nationality {
        id
        name
      }
      address
      postalCode
      country {
        id
        name
      }
      city {
        id
        name
      }
      isSocialLoginEnabled
      biography
    }
  }
}
    `;
export const MeSupplierAccountVideoDocument = gql`
    query meSupplierAccountVideo {
  me {
    __typename
    ... on User {
      id
    }
    ... on Supplier {
      id
      video
    }
  }
}
    `;
export const MeSupplierAccountWorkingAreasDocument = gql`
    query MeSupplierAccountWorkingAreas {
  me {
    __typename
    ... on User {
      id
    }
    ... on Supplier {
      id
      workingCountries {
        id
        name
      }
      cities {
        id
        name
        url
        countryId
      }
    }
  }
}
    `;
export const MeUserAccountProfilePictureInfoDocument = gql`
    query MeUserAccountProfilePictureInfo {
  me {
    __typename
    ... on User {
      id
      picture {
        url
      }
      isSupplier
    }
    ... on Supplier {
      ...SupplierCard
      isSupplier
    }
  }
}
    ${SupplierCardFragmentDoc}`;
export const SupplierRequestChangeDocument = gql`
    mutation SupplierRequestChange($input: SupplierRequestChangeInput!) {
  SupplierRequestChange(input: $input)
}
    `;
export const TagBySlugDocument = gql`
    query TagBySlug($slug: String!) {
  tagBySlug(slug: $slug) {
    id
    name
    slug
  }
}
    `;
export const CreateMessageTemplateDocument = gql`
    mutation CreateMessageTemplate($input: CreateMessageTemplateInput!) {
  CreateMessageTemplate(input: $input) {
    messageTemplate {
      id
    }
  }
}
    `;
export const DeleteMessageTemplateDocument = gql`
    mutation DeleteMessageTemplate($id: Int!) {
  DeleteMessageTemplate(id: $id) {
    success
  }
}
    `;
export const SupplierMessageTemplateByIdDocument = gql`
    query SupplierMessageTemplateById($id: Int!) {
  me {
    __typename
    ... on Supplier {
      ...SupplierMessageTemplateById
    }
  }
}
    ${SupplierMessageTemplateByIdFragmentDoc}`;
export const SupplierMessageTemplatesDocument = gql`
    query SupplierMessageTemplates($isActive: Boolean) {
  me {
    __typename
    ... on Supplier {
      ...SupplierMessageTemplates
    }
  }
}
    ${SupplierMessageTemplatesFragmentDoc}`;
export const UpdateMessageTemplateDocument = gql`
    mutation UpdateMessageTemplate($id: Int!, $input: UpdateMessageTemplateInput!) {
  UpdateMessageTemplate(id: $id, input: $input) {
    messageTemplate {
      id
    }
  }
}
    `;
export const CreateTourDocument = gql`
    mutation CreateTour($input: CreateTourInput!) {
  CreateTour(input: $input) {
    tour {
      id
    }
    success
  }
}
    `;
export const DeleteTourDocument = gql`
    mutation DeleteTour($id: Int!) {
  DeleteTour(id: $id) {
    success
  }
}
    `;
export const DuplicateTourDocument = gql`
    mutation DuplicateTour($id: Int!) {
  DuplicateTour(id: $id) {
    tour {
      id
    }
    success
  }
}
    `;
export const InactiveTourDocument = gql`
    mutation InactiveTour($id: Int!) {
  InactiveTour(id: $id) {
    tour {
      id
      status
    }
    success
  }
}
    `;
export const IncrementTourViewsDocument = gql`
    mutation incrementTourViews($id: Int!) {
  incrementTourViews(id: $id) {
    isUpdated
  }
}
    `;
export const RecommendedDataByBookingIdDocument = gql`
    query RecommendedDataByBookingId($bookingId: Int!) {
  recommendedDataByBookingId(bookingId: $bookingId) {
    tours {
      ...TourCard
    }
    countries {
      ...CountryCard
    }
    hasMoreCities
    cities {
      ...CityCard
    }
    country {
      id
      name
      url
    }
  }
}
    ${TourCardFragmentDoc}
${CountryCardFragmentDoc}
${CityCardFragmentDoc}`;
export const RelatedToursByPostDocument = gql`
    query RelatedToursByPost($postId: Int!) {
  RelatedToursByPost(postId: $postId) {
    tours {
      id
      title
      productType
      url
      status
      meetingTime
      meetingPoint
      endingPoint
      supplier {
        ...SupplierCard
        isBan
        important
        languages {
          level
          language {
            id
            name
          }
        }
      }
      category {
        id
        name
        slug
      }
      country {
        id
        name
        slug
        url
      }
      city {
        id
        name
        slug
        url
      }
      duration
      people {
        min
        max
      }
      language {
        id
        name
      }
      picture {
        url(size: SM)
        cover: url(size: MD)
        caption
      }
      video
      gallery {
        src: url(size: MD)
        caption
      }
      included
      notIncluded
      important
      conditions
      overview
      content
      cancelPolicyRules {
        from
        to
        charge
      }
      itinerary {
        type
        time
        title
        description
      }
      calendar {
        minDate
        maxDate
        workingDates
        notWorkingDates
        daysOfWeekAvailable
        daysOfWeekBlocked
        bookingsDates
      }
      hasPriceRules
      price {
        currency {
          id
          quotes {
            currency
            rate
          }
        }
        amount
        model
      }
      faqs {
        title
        content
      }
    }
  }
}
    ${SupplierCardFragmentDoc}`;
export const SubmitTourDocument = gql`
    mutation SubmitTour($id: Int!) {
  SubmitTour(id: $id) {
    tour {
      id
    }
    success
  }
}
    `;
export const SupplierTourrByIdDocument = gql`
    query SupplierTourrById($id: Int!) {
  me {
    __typename
    ... on Supplier {
      ...SupplierTourById
    }
  }
}
    ${SupplierTourByIdFragmentDoc}`;
export const SupplierToursDocument = gql`
    query SupplierTours($sizePerPage: Int, $page: Int, $filters: SupplierTourFilters, $badgeFilters: SupplierTourBadgesFilters, $categoryStatsFilter: CategoryStatsFilters) {
  me {
    __typename
    ... on Supplier {
      ...SupplierTours
    }
  }
}
    ${SupplierToursFragmentDoc}`;
export const TourByIdDocument = gql`
    query tourById($id: Int!) {
  tourById(id: $id) {
    id
    title
    productType
    url
    status
    meetingTime
    meetingPoint
    meetingPointDescription
    endingPoint
    timeFrom
    availability
    isAvailabilityUpdated
    isActive
    supplier {
      ...SupplierCard
      isBan
      important
      isCar
      createdAt
      languages {
        level
        language {
          id
          name
        }
      }
    }
    category {
      id
      name
      slug
      hex_color_code
      picture {
        url
      }
    }
    country {
      id
      name
      slug
      url
      isActive
    }
    city {
      ...CityFooter
    }
    duration
    people {
      min
      max
    }
    language {
      id
      name
    }
    picture {
      url(size: SM)
      cover: url(size: MD)
      mobileUrl: url(size: SM)
      caption
      serpImage
    }
    video
    galleryMD: gallery {
      serpImage
      src: url(size: MD)
      caption
      id
      position
    }
    galleryOriginal: gallery {
      serpImage
      src: url(size: ORIGINAL)
      caption
      id
      position
    }
    included
    notIncluded
    important
    conditions
    overview
    content
    cancelPolicyRules {
      from
      to
      charge
    }
    itinerary {
      type
      time
      title
      description
    }
    calendar {
      minDate
      maxDate
      workingDates
      notWorkingDates
      daysOfWeekAvailable
      daysOfWeekBlocked
      bookingsDates
    }
    tourSpecificCalendar {
      minDate
      maxDate
      workingDates
      notWorkingDates
      daysOfWeekAvailable
      daysOfWeekBlocked
      bookingsDates
    }
    hasPriceRules
    price {
      currency {
        id
        quotes {
          currency
          rate
        }
      }
      amount
      model
    }
    faqs {
      title
      content
    }
    priceRules {
      people
      price
    }
    childrenPriceRule
    infantPriceRule
  }
}
    ${SupplierCardFragmentDoc}
${CityFooterFragmentDoc}`;
export const TourByIdTourPreviewDocument = gql`
    query TourByIdTourPreview($id: Int!) {
  tourById(id: $id) {
    ...TourMessagePage
  }
}
    ${TourMessagePageFragmentDoc}`;
export const TourCalendarDocument = gql`
    query tourCalendar($id: Int!) {
  tourCalendar(id: $id) {
    calendar {
      minDate
      maxDate
      workingDates
      notWorkingDates
      daysOfWeekAvailable
      daysOfWeekBlocked
      bookingsDates
    }
  }
}
    `;
export const TourPriceDocument = gql`
    query tourPrice($id: Int!, $input: TourPriceInput!) {
  tourPrice(id: $id, input: $input) {
    currency {
      id
      quotes {
        currency
        rate
      }
    }
    amount
    model
  }
}
    `;
export const TourScarcityDocument = gql`
    query tourScarcity($id: Int!, $input: TourScarCityInput!) {
  tourScarcity(id: $id, input: $input) {
    slot
    month
  }
}
    `;
export const ToursDocument = gql`
    query tours($first: Int, $before: String, $last: Int, $after: String, $page: Int, $sizePerPage: Int, $filters: TourFilters) {
  tours(
    first: $first
    before: $before
    last: $last
    after: $after
    page: $page
    sizePerPage: $sizePerPage
    filters: $filters
  ) {
    edges {
      node {
        ...TourCard
      }
    }
    pageInfo {
      ...PageInfoFields
    }
    totalCount
  }
}
    ${TourCardFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const UpdateTourDocument = gql`
    mutation UpdateTour($id: Int!, $input: UpdateTourInput!) {
  UpdateTour(id: $id, input: $input) {
    tour {
      id
    }
    success
  }
}
    `;
export const AuthenticateDocument = gql`
    mutation Authenticate($input: AuthenticateInput!) {
  Authenticate(input: $input) {
    token
  }
}
    `;
export const AuthenticateWithFacebookDocument = gql`
    mutation AuthenticateWithFacebook($input: AuthenticateWithFacebookInput!) {
  AuthenticateWithFacebook(input: $input) {
    token
  }
}
    `;
export const AuthenticateWithGoogleDocument = gql`
    mutation AuthenticateWithGoogle($input: AuthenticateWithGoogleInput!) {
  AuthenticateWithGoogle(input: $input) {
    token
  }
}
    `;
export const BlogWriterByIdDocument = gql`
    query blogWriterById($id: Int!) {
  blogWriterById(id: $id) {
    id
    displayName
    firstName
    lastName
    isBan
    biography
    isBlogger
    isSupplier
    picture {
      url(size: SM)
      cover: url(size: MD)
      serpImage
    }
  }
}
    `;
export const ChangeUserPasswordByTokenDocument = gql`
    mutation ChangeUserPasswordByToken($input: ChangeUserPasswordByTokenInput!) {
  ChangeUserPasswordByToken(input: $input) {
    token
  }
}
    `;
export const CloseCalendarDateDocument = gql`
    mutation CloseCalendarDate($date: String!) {
  CloseCalendarDate(date: $date)
}
    `;
export const ContactDocument = gql`
    mutation Contact($input: ContactInput!) {
  Contact(input: $input) {
    isSent
  }
}
    `;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  CreateUser(input: $input) {
    token
  }
}
    `;
export const OpenCalendarDateDocument = gql`
    mutation OpenCalendarDate($date: String!, $forceToOpen: Boolean) {
  OpenCalendarDate(date: $date, forceToOpen: $forceToOpen)
}
    `;
export const RecoverUserPasswordDocument = gql`
    mutation RecoverUserPassword($input: RecoverUserPasswordInput!) {
  RecoverUserPassword(input: $input) {
    isSent
  }
}
    `;
export const RemoveSupplierPayPalDocument = gql`
    mutation RemoveSupplierPayPal {
  RemoveSupplierPayPal {
    supplier {
      id
      displayName
      payoutMethod
      paypal {
        id
        name
        email
      }
      country {
        id
        name
        url
      }
    }
  }
}
    `;
export const SupplierBookingsForPeriodDocument = gql`
    query supplierBookingsForPeriod($bookingFilter: BookingFilters, $paymentFilter: PaymentFilters) {
  me {
    __typename
    ... on Supplier {
      ...SupplierBooking
    }
  }
}
    ${SupplierBookingFragmentDoc}`;
export const SupplierAllToursToRefreshCacheDocument = gql`
    query SupplierAllToursToRefreshCache($id: Int!) {
  supplierById(id: $id) {
    id
    displayName
    allTours {
      id
      url
    }
  }
}
    `;
export const SupplierAnalyticsDocument = gql`
    query SupplierAnalytics {
  me {
    ... on Supplier {
      analytics {
        conversionRate
        calendarUpdate
        responseTime
        profileVideo
      }
    }
  }
}
    `;
export const SupplierAnalyticsByIdDocument = gql`
    query SupplierAnalyticsById($id: Int!) {
  supplierById(id: $id) {
    analytics {
      conversionRate
      calendarUpdate
      responseTime
      profileVideo
    }
  }
}
    `;
export const SupplierApplyDocument = gql`
    mutation SupplierApply($input: SupplierApplyInput!) {
  SupplierApply(input: $input) {
    supplier {
      id
      firstName
      lastName
      email
      birthday
      gender
      nationality {
        id
        name
      }
    }
  }
}
    `;
export const SupplierBookingByIdDocument = gql`
    query SupplierBookingById($id: Int!) {
  me {
    __typename
    ... on Supplier {
      ...SupplierBookingId
    }
  }
}
    ${SupplierBookingIdFragmentDoc}`;
export const SupplierByIdDocument = gql`
    query supplierById($id: Int!, $badgeFilters: SupplierTourBadgesFilters) {
  supplierById(id: $id) {
    id
    isBan
    displayName
    biography
    responseTime
    responseTimeMessage
    calendarUpdatedAt
    maxAllowedPeople
    url
    isCar
    important
    isOnline
    createdAt
    calendar {
      minDate
      maxDate
      workingDates
      notWorkingDates
      daysOfWeekAvailable
      daysOfWeekBlocked
      bookingsDates
    }
    supplierTourBadges(filters: $badgeFilters) {
      id
      name
      hex_color_code
      picture {
        url
      }
    }
    tours {
      ...SimpleTour
    }
    picture {
      url(size: SM)
      cover: url(size: MD)
      serpImage
    }
    video
    galleryMD: gallery {
      serpImage
      src: url(size: MD)
      caption
    }
    galleryOriginal: gallery {
      serpImage
      src: url(size: ORIGINAL)
      caption
    }
    country {
      id
      name
      url
      slug
    }
    city {
      id
      name
      url
      slug
    }
    workingCountries {
      id
      name
    }
    cities {
      ...CityFooter
    }
    languages {
      level
      language {
        id
        name
      }
    }
    stats {
      reviewsCount
      rating
    }
    guideBookingNotice
    guideBookingWindow
    analytics {
      conversionRate
    }
  }
}
    ${SimpleTourFragmentDoc}
${CityFooterFragmentDoc}`;
export const SupplierForCreatePaymentRequestDocument = gql`
    query supplierForCreatePaymentRequest($id: Int!) {
  supplierById(id: $id) {
    id
    isBan
    calendar {
      minDate
      maxDate
      workingDates
      notWorkingDates
      daysOfWeekAvailable
      daysOfWeekBlocked
      bookingsDates
    }
    supplierBookingDates
    commission
    country {
      id
      name
      url
      currency {
        id
        symbol
        quotes {
          currency
          rate
        }
        minPrice
      }
    }
    workingCountries {
      id
      name
    }
    cities {
      id
      name
      url
      country {
        id
        name
      }
    }
  }
}
    `;
export const SupplierCalendarByIdDocument = gql`
    query SupplierCalendarById($id: Int!) {
  supplierById(id: $id) {
    calendar {
      minDate
      maxDate
      workingDates
      notWorkingDates
      daysOfWeekAvailable
      daysOfWeekBlocked
      bookingsDates
    }
  }
}
    `;
export const SupplierPaymentsForMonthlyInvoiceDocument = gql`
    query supplierPaymentsForMonthlyInvoice($bookingFilter: BookingFilters, $paymentFilter: PaymentFilters) {
  me {
    __typename
    ... on Supplier {
      ...SupplierBookingPayment
    }
  }
}
    ${SupplierBookingPaymentFragmentDoc}`;
export const SupplierScarcityDocument = gql`
    query supplierScarcity($supplierId: Int!, $date: String) {
  supplierScarcity(date: $date, supplierId: $supplierId) {
    slot
    month
  }
}
    `;
export const SupplierWithPaymentByIdDocument = gql`
    query SupplierWithPaymentById($id: Int!) {
  supplierById(id: $id) {
    ...SupplierWithPayment
  }
}
    ${SupplierWithPaymentFragmentDoc}`;
export const SupplierWithPaymentsDocument = gql`
    query supplierWithPayments($filters: PaymentFilters) {
  me {
    __typename
    ... on Supplier {
      ...SupplierWithPayments
    }
  }
}
    ${SupplierWithPaymentsFragmentDoc}`;
export const SuppliersDocument = gql`
    query suppliers($first: Int, $before: String, $last: Int, $after: String, $page: Int, $sizePerPage: Int, $filters: SupplierFilters) {
  suppliers(
    first: $first
    after: $after
    before: $before
    last: $last
    page: $page
    sizePerPage: $sizePerPage
    filters: $filters
  ) {
    edges {
      node {
        ...SupplierCard
      }
    }
    pageInfo {
      ...PageInfoFields
    }
    totalCount
  }
}
    ${SupplierCardFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const UpdateCustomerAccountDocument = gql`
    mutation UpdateCustomerAccount($input: UpdateCustomerAccountInput!) {
  UpdateCustomerAccount(input: $input) {
    user {
      id
    }
  }
}
    `;
export const UpdateSupplierAccountDocument = gql`
    mutation UpdateSupplierAccount($input: UpdateSupplierAccountInput!) {
  UpdateSupplierAccount(input: $input) {
    supplier {
      id
      calendar {
        daysOfWeekAvailable
        daysOfWeekBlocked
        bookingsDates
      }
      guideBookingNotice
      guideBookingWindow
    }
  }
}
    `;
export const UpdateSupplierBankDocument = gql`
    mutation UpdateSupplierBank($input: UpdateSupplierBankInput!) {
  UpdateSupplierBank(input: $input) {
    supplier {
      id
      displayName
      payoutMethod
      bank {
        bankName
        bankCode
        branchName
        branchCode
        accountNumber
        accountName
        accountType
        firstName
        lastName
      }
      country {
        id
        name
        url
      }
    }
  }
}
    `;
export const UpdateSupplierGalleryDocument = gql`
    mutation UpdateSupplierGallery($input: UpdateSupplierGalleryInput!) {
  UpdateSupplierGallery(input: $input) {
    supplier {
      id
      gallery {
        id
        src: url(size: MD)
        caption
        position
      }
    }
  }
}
    `;
export const UpdateSupplierPaypalDocument = gql`
    mutation UpdateSupplierPaypal($input: UpdateSupplierPaypalInput!) {
  UpdateSupplierPaypal(input: $input) {
    supplier {
      id
      displayName
      payoutMethod
      paypal {
        id
        name
        email
      }
      country {
        id
        name
        url
      }
    }
  }
}
    `;
export const ToursOfSupplierByIdDocument = gql`
    query toursOfSupplierById($id: Int!) {
  supplierById(id: $id) {
    id
    displayName
    tours {
      id
      title
      duration
      url
      city {
        id
        name
        slug
        url
      }
      picture {
        url(size: MD)
        cover: url(size: MD)
        caption
      }
      price {
        currency {
          id
          quotes {
            currency
            rate
          }
        }
        amount
        model
      }
    }
  }
}
    `;
export const AcceptBookingDocument = gql`
    mutation AcceptBooking($bookingId: Int!) {
  AcceptBooking(bookingId: $bookingId) {
    booking {
      id
      status
    }
  }
}
    `;
export const AddCouponToBookingDocument = gql`
    mutation AddCouponToBooking($input: AddCouponToBookingInput!) {
  AddCouponToBooking(input: $input) {
    id
    userId
    title
    duration
    supplierId
    userId
  }
}
    `;
export const AuthorizePayPalOrderDocument = gql`
    mutation AuthorizePayPalOrder($input: AuthorizePayPalOrderInput!) {
  AuthorizePayPalOrder(input: $input) {
    booking {
      id
      userId
      title
      duration
      supplierId
    }
  }
}
    `;
export const BookingPaymentErrorDocument = gql`
    mutation BookingPaymentError($input: BookingPaymentErrorInput!) {
  BookingPaymentError(input: $input)
}
    `;
export const CalculateBookingCancellationPolicyRuleDocument = gql`
    mutation CalculateBookingCancellationPolicyRule($id: Int!) {
  CalculateBookingCancellationPolicyRule(id: $id) {
    refundPercent
    daysDiff
    meetingDateAt
    cancelAt
  }
}
    `;
export const CancelBookingDocument = gql`
    mutation CancelBooking($input: CancelBookingInput!) {
  CancelBooking(input: $input) {
    booking {
      id
      status
    }
  }
}
    `;
export const CompleteBookingDocument = gql`
    mutation CompleteBooking($id: Int!, $input: CompleteBookingInput!) {
  CompleteBooking(id: $id, input: $input) {
    booking {
      id
      status
      hotel
    }
    messageId
    message
  }
}
    `;
export const CreateBookingQuotationDocument = gql`
    mutation CreateBookingQuotation($input: CreateBookingQuotationInput!) {
  CreateBookingQuotation(input: $input) {
    onlinePrice
    cashPrice
    payoutMethod
    tour {
      id
      title
      duration
      url
      country {
        id
      }
      picture {
        url
      }
      price {
        amount
        model
      }
      supplier {
        id
        displayName
        payoutMethodType
        url
        picture {
          url(size: XS)
        }
        stats {
          reviewsCount
          rating
        }
      }
    }
    items {
      type
      description
      price
    }
    currency {
      id
      symbol
      minPrice
      quotes {
        currency
        rate
      }
    }
  }
}
    `;
export const CreatePayPalOrderDocument = gql`
    mutation CreatePayPalOrder($input: CreatePayPalOrderInput!) {
  CreatePayPalOrder(input: $input) {
    orderId
  }
}
    `;
export const CreateTourBookingDocument = gql`
    mutation CreateTourBooking($input: CreateTourBookingInput!) {
  CreateTourBooking(input: $input) {
    id
    userId
    title
    duration
    supplierId
    userId
  }
}
    `;
export const CreateTourQuotationDocument = gql`
    mutation CreateTourQuotation($input: CreateTourQuotationInput!) {
  CreateTourQuotation(input: $input) {
    onlinePrice
    cashPrice
    payoutMethod
    tour {
      id
      title
      duration
      url
      country {
        id
        isActive
      }
      city {
        id
        name
      }
      cancelPolicyRules {
        from
        to
        charge
      }
      picture {
        url
      }
      price {
        amount
        model
      }
      supplier {
        id
        displayName
        payoutMethodType
        url
        picture {
          url(size: XS)
        }
        stats {
          reviewsCount
          rating
        }
      }
    }
    items {
      type
      description
      price
    }
    currency {
      id
      symbol
      minPrice
      quotes {
        currency
        rate
      }
    }
  }
}
    `;
export const CreateVeritransPaymentDocument = gql`
    mutation CreateVeritransPayment($input: CreateVeritransPaymentInput!) {
  CreateVeritransPayment(input: $input) {
    booking {
      id
      userId
      title
      duration
      supplierId
    }
  }
}
    `;
export const CustomerBookingDetailDocument = gql`
    query CustomerBookingDetail($id: Int!) {
  me {
    __typename
    ... on User {
      ...CustomerBookingDetail
    }
  }
}
    ${CustomerBookingDetailFragmentDoc}`;
export const CustomerBookingsDocument = gql`
    query CustomerBookings($first: Int, $before: String, $last: Int, $after: String, $search: String, $bookingStatus: [BookingStatus!]) {
  me {
    __typename
    ... on User {
      ...CustomerBookings
    }
  }
}
    ${CustomerBookingsFragmentDoc}`;
export const CustomerReviewsDocument = gql`
    query CustomerReviews($first: Int, $before: String, $last: Int, $after: String, $withReview: Boolean, $bookingStatus: [BookingStatus!]) {
  me {
    __typename
    ... on User {
      ...CustomerReviews
    }
  }
}
    ${CustomerReviewsFragmentDoc}`;
export const DeletePendingBookingDocument = gql`
    mutation DeletePendingBooking($bookingId: Int!) {
  DeletePendingBooking(bookingId: $bookingId)
}
    `;
export const SupplierAsCustomerBookingsDocument = gql`
    query SupplierAsCustomerBookings($first: Int, $before: String, $last: Int, $after: String, $search: String, $bookingStatus: [BookingStatus!]) {
  me {
    __typename
    ... on Supplier {
      ...SupplierAsCustomerBookings
    }
  }
}
    ${SupplierAsCustomerBookingsFragmentDoc}`;
export const SupplierAsCustomerReviewsDocument = gql`
    query SupplierAsCustomerReviews($first: Int, $before: String, $last: Int, $after: String, $withReview: Boolean, $bookingStatus: [BookingStatus!]) {
  me {
    __typename
    ... on Supplier {
      ...SupplierAsCustomerReviews
    }
  }
}
    ${SupplierAsCustomerReviewsFragmentDoc}`;
export const SupplierBookingDetailDocument = gql`
    query SupplierBookingDetail($id: Int!) {
  me {
    __typename
    ... on Supplier {
      ...SupplierBookingDetail
    }
  }
}
    ${SupplierBookingDetailFragmentDoc}`;
export const SupplierBookingsDocument = gql`
    query SupplierBookings($first: Int, $before: String, $last: Int, $after: String, $search: String, $bookingStatus: [BookingStatus!]) {
  me {
    __typename
    ... on Supplier {
      ...SupplierBookings
    }
  }
}
    ${SupplierBookingsFragmentDoc}`;
export const SupplierBookingsForCalendarDocument = gql`
    query SupplierBookingsForCalendar($first: Int, $before: String, $last: Int, $after: String, $search: String, $bookingStatus: [BookingStatus!], $orderBy: BookingOrderBy, $meetingDateFrom: String, $meetingDateTo: String) {
  me {
    __typename
    ... on Supplier {
      ...SupplierBookingsCalendar
    }
  }
}
    ${SupplierBookingsCalendarFragmentDoc}`;
export const SupplierReviewsDocument = gql`
    query SupplierReviews($first: Int, $before: String, $last: Int, $after: String, $withReview: Boolean, $bookingStatus: [BookingStatus!]) {
  me {
    __typename
    ... on Supplier {
      ...SupplierReviews
    }
  }
}
    ${SupplierReviewsFragmentDoc}`;
export const UpdateBookingDocument = gql`
    mutation UpdateBooking($input: UpdateBookingInput!, $id: Int!) {
  UpdateBooking(input: $input, id: $id) {
    booking {
      id
      status
    }
  }
}
    `;
export const OpenAiDataRequestDocument = gql`
    mutation OpenAiDataRequest($input: OpenAiDataRequestInput!) {
  OpenAiDataRequest(input: $input) {
    resultPayload {
      tours {
        results {
          id
          title
          abstract
          image
          url
          comments
        }
        viewMore
        comments
      }
      guides {
        results {
          id
          title
          abstract
          image
          url
          comments
        }
        viewMore
        comments
      }
      virtual_tours {
        results {
          id
          title
          abstract
          image
          url
          comments
        }
        viewMore
        comments
      }
      private_cars {
        results {
          id
          title
          abstract
          image
          url
          comments
        }
        viewMore
        comments
      }
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getToursFiltersAvailable(variables?: GetToursFiltersAvailableQueryVariables): Promise<GetToursFiltersAvailableQuery> {
      return withWrapper(() => client.request<GetToursFiltersAvailableQuery>(print(GetToursFiltersAvailableDocument), variables));
    },
    getSuppliersFiltersAvailable(variables?: GetSuppliersFiltersAvailableQueryVariables): Promise<GetSuppliersFiltersAvailableQuery> {
      return withWrapper(() => client.request<GetSuppliersFiltersAvailableQuery>(print(GetSuppliersFiltersAvailableDocument), variables));
    },
    activeAnnouncement(variables?: ActiveAnnouncementQueryVariables): Promise<ActiveAnnouncementQuery> {
      return withWrapper(() => client.request<ActiveAnnouncementQuery>(print(ActiveAnnouncementDocument), variables));
    },
    blogCategoryBySlug(variables: BlogCategoryBySlugQueryVariables): Promise<BlogCategoryBySlugQuery> {
      return withWrapper(() => client.request<BlogCategoryBySlugQuery>(print(BlogCategoryBySlugDocument), variables));
    },
    categoryBySlug(variables: CategoryBySlugQueryVariables): Promise<CategoryBySlugQuery> {
      return withWrapper(() => client.request<CategoryBySlugQuery>(print(CategoryBySlugDocument), variables));
    },
    tourCategories(variables?: TourCategoriesQueryVariables): Promise<TourCategoriesQuery> {
      return withWrapper(() => client.request<TourCategoriesQuery>(print(TourCategoriesDocument), variables));
    },
    postCategories(variables?: PostCategoriesQueryVariables): Promise<PostCategoriesQuery> {
      return withWrapper(() => client.request<PostCategoriesQuery>(print(PostCategoriesDocument), variables));
    },
    tourCategoriesForTourCreation(variables?: TourCategoriesForTourCreationQueryVariables): Promise<TourCategoriesForTourCreationQuery> {
      return withWrapper(() => client.request<TourCategoriesForTourCreationQuery>(print(TourCategoriesForTourCreationDocument), variables));
    },
    meCustomerAccount(variables?: MeCustomerAccountQueryVariables): Promise<MeCustomerAccountQuery> {
      return withWrapper(() => client.request<MeCustomerAccountQuery>(print(MeCustomerAccountDocument), variables));
    },
    faqById(variables: FaqByIdQueryVariables): Promise<FaqByIdQuery> {
      return withWrapper(() => client.request<FaqByIdQuery>(print(FaqByIdDocument), variables));
    },
    faqCategoryById(variables: FaqCategoryByIdQueryVariables): Promise<FaqCategoryByIdQuery> {
      return withWrapper(() => client.request<FaqCategoryByIdQuery>(print(FaqCategoryByIdDocument), variables));
    },
    getFaqCategories(variables?: GetFaqCategoriesQueryVariables): Promise<GetFaqCategoriesQuery> {
      return withWrapper(() => client.request<GetFaqCategoriesQuery>(print(GetFaqCategoriesDocument), variables));
    },
    getFaqs(variables?: GetFaqsQueryVariables): Promise<GetFaqsQuery> {
      return withWrapper(() => client.request<GetFaqsQuery>(print(GetFaqsDocument), variables));
    },
    hasSupportAccess(variables?: HasSupportAccessQueryVariables): Promise<HasSupportAccessQuery> {
      return withWrapper(() => client.request<HasSupportAccessQuery>(print(HasSupportAccessDocument), variables));
    },
    IncrementFaqViewCount(variables: IncrementFaqViewCountMutationVariables): Promise<IncrementFaqViewCountMutation> {
      return withWrapper(() => client.request<IncrementFaqViewCountMutation>(print(IncrementFaqViewCountDocument), variables));
    },
    SupplierContactSupport(variables: SupplierContactSupportMutationVariables): Promise<SupplierContactSupportMutation> {
      return withWrapper(() => client.request<SupplierContactSupportMutation>(print(SupplierContactSupportDocument), variables));
    },
    languages(variables?: LanguagesQueryVariables): Promise<LanguagesQuery> {
      return withWrapper(() => client.request<LanguagesQuery>(print(LanguagesDocument), variables));
    },
    cities(variables?: CitiesQueryVariables): Promise<CitiesQuery> {
      return withWrapper(() => client.request<CitiesQuery>(print(CitiesDocument), variables));
    },
    citiesWithSuppliers(variables: CitiesWithSuppliersQueryVariables): Promise<CitiesWithSuppliersQuery> {
      return withWrapper(() => client.request<CitiesWithSuppliersQuery>(print(CitiesWithSuppliersDocument), variables));
    },
    citiesWithTours(variables: CitiesWithToursQueryVariables): Promise<CitiesWithToursQuery> {
      return withWrapper(() => client.request<CitiesWithToursQuery>(print(CitiesWithToursDocument), variables));
    },
    cityBySlug(variables: CityBySlugQueryVariables): Promise<CityBySlugQuery> {
      return withWrapper(() => client.request<CityBySlugQuery>(print(CityBySlugDocument), variables));
    },
    countries(variables?: CountriesQueryVariables): Promise<CountriesQuery> {
      return withWrapper(() => client.request<CountriesQuery>(print(CountriesDocument), variables));
    },
    countryBySlug(variables: CountryBySlugQueryVariables): Promise<CountryBySlugQuery> {
      return withWrapper(() => client.request<CountryBySlugQuery>(print(CountryBySlugDocument), variables));
    },
    locations(variables: LocationsQueryVariables): Promise<LocationsQuery> {
      return withWrapper(() => client.request<LocationsQuery>(print(LocationsDocument), variables));
    },
    locationsWithData(variables: LocationsWithDataQueryVariables): Promise<LocationsWithDataQuery> {
      return withWrapper(() => client.request<LocationsWithDataQuery>(print(LocationsWithDataDocument), variables));
    },
    cityBySlugWithCountry(variables: CityBySlugWithCountryQueryVariables): Promise<CityBySlugWithCountryQuery> {
      return withWrapper(() => client.request<CityBySlugWithCountryQuery>(print(CityBySlugWithCountryDocument), variables));
    },
    meContext(variables?: MeContextQueryVariables): Promise<MeContextQuery> {
      return withWrapper(() => client.request<MeContextQuery>(print(MeContextDocument), variables));
    },
    meContextBasic(variables?: MeContextBasicQueryVariables): Promise<MeContextBasicQuery> {
      return withWrapper(() => client.request<MeContextBasicQuery>(print(MeContextBasicDocument), variables));
    },
    meContextForBlogCreation(variables?: MeContextForBlogCreationQueryVariables): Promise<MeContextForBlogCreationQuery> {
      return withWrapper(() => client.request<MeContextForBlogCreationQuery>(print(MeContextForBlogCreationDocument), variables));
    },
    meContextForSupplierApply(variables?: MeContextForSupplierApplyQueryVariables): Promise<MeContextForSupplierApplyQuery> {
      return withWrapper(() => client.request<MeContextForSupplierApplyQuery>(print(MeContextForSupplierApplyDocument), variables));
    },
    CreateFile(variables: CreateFileMutationVariables): Promise<CreateFileMutation> {
      return withWrapper(() => client.request<CreateFileMutation>(print(CreateFileDocument), variables));
    },
    CreateFileSignedUrl(variables: CreateFileSignedUrlMutationVariables): Promise<CreateFileSignedUrlMutation> {
      return withWrapper(() => client.request<CreateFileSignedUrlMutation>(print(CreateFileSignedUrlDocument), variables));
    },
    getHighQualityImageFromUrl(variables: GetHighQualityImageFromUrlQueryVariables): Promise<GetHighQualityImageFromUrlQuery> {
      return withWrapper(() => client.request<GetHighQualityImageFromUrlQuery>(print(GetHighQualityImageFromUrlDocument), variables));
    },
    CreateMessage(variables: CreateMessageMutationVariables): Promise<CreateMessageMutation> {
      return withWrapper(() => client.request<CreateMessageMutation>(print(CreateMessageDocument), variables));
    },
    CreatePaymentRequest(variables: CreatePaymentRequestMutationVariables): Promise<CreatePaymentRequestMutation> {
      return withWrapper(() => client.request<CreatePaymentRequestMutation>(print(CreatePaymentRequestDocument), variables));
    },
    CreateTourOffer(variables: CreateTourOfferMutationVariables): Promise<CreateTourOfferMutation> {
      return withWrapper(() => client.request<CreateTourOfferMutation>(print(CreateTourOfferDocument), variables));
    },
    CustomerMessageBookingsById(variables: CustomerMessageBookingsByIdQueryVariables): Promise<CustomerMessageBookingsByIdQuery> {
      return withWrapper(() => client.request<CustomerMessageBookingsByIdQuery>(print(CustomerMessageBookingsByIdDocument), variables));
    },
    CustomerMessageById(variables: CustomerMessageByIdQueryVariables): Promise<CustomerMessageByIdQuery> {
      return withWrapper(() => client.request<CustomerMessageByIdQuery>(print(CustomerMessageByIdDocument), variables));
    },
    CustomerMessages(variables?: CustomerMessagesQueryVariables): Promise<CustomerMessagesQuery> {
      return withWrapper(() => client.request<CustomerMessagesQuery>(print(CustomerMessagesDocument), variables));
    },
    IsMessageThreadExists(variables: IsMessageThreadExistsMutationVariables): Promise<IsMessageThreadExistsMutation> {
      return withWrapper(() => client.request<IsMessageThreadExistsMutation>(print(IsMessageThreadExistsDocument), variables));
    },
    NewMessageWithBooking(variables: NewMessageWithBookingMutationVariables): Promise<NewMessageWithBookingMutation> {
      return withWrapper(() => client.request<NewMessageWithBookingMutation>(print(NewMessageWithBookingDocument), variables));
    },
    ReplyMessage(variables: ReplyMessageMutationVariables): Promise<ReplyMessageMutation> {
      return withWrapper(() => client.request<ReplyMessageMutation>(print(ReplyMessageDocument), variables));
    },
    ReportMessageById(variables: ReportMessageByIdMutationVariables): Promise<ReportMessageByIdMutation> {
      return withWrapper(() => client.request<ReportMessageByIdMutation>(print(ReportMessageByIdDocument), variables));
    },
    ShouldMessageSecured(variables: ShouldMessageSecuredMutationVariables): Promise<ShouldMessageSecuredMutation> {
      return withWrapper(() => client.request<ShouldMessageSecuredMutation>(print(ShouldMessageSecuredDocument), variables));
    },
    SupplierMessageBookingsById(variables: SupplierMessageBookingsByIdQueryVariables): Promise<SupplierMessageBookingsByIdQuery> {
      return withWrapper(() => client.request<SupplierMessageBookingsByIdQuery>(print(SupplierMessageBookingsByIdDocument), variables));
    },
    SupplierMessageById(variables: SupplierMessageByIdQueryVariables): Promise<SupplierMessageByIdQuery> {
      return withWrapper(() => client.request<SupplierMessageByIdQuery>(print(SupplierMessageByIdDocument), variables));
    },
    SupplierMessages(variables?: SupplierMessagesQueryVariables): Promise<SupplierMessagesQuery> {
      return withWrapper(() => client.request<SupplierMessagesQuery>(print(SupplierMessagesDocument), variables));
    },
    UpdateMessage(variables: UpdateMessageMutationVariables): Promise<UpdateMessageMutation> {
      return withWrapper(() => client.request<UpdateMessageMutation>(print(UpdateMessageDocument), variables));
    },
    UpdateMessageAsRead(variables: UpdateMessageAsReadMutationVariables): Promise<UpdateMessageAsReadMutation> {
      return withWrapper(() => client.request<UpdateMessageAsReadMutation>(print(UpdateMessageAsReadDocument), variables));
    },
    UpdateMessageThread(variables: UpdateMessageThreadMutationVariables): Promise<UpdateMessageThreadMutation> {
      return withWrapper(() => client.request<UpdateMessageThreadMutation>(print(UpdateMessageThreadDocument), variables));
    },
    UserNotification(variables?: UserNotificationQueryVariables): Promise<UserNotificationQuery> {
      return withWrapper(() => client.request<UserNotificationQuery>(print(UserNotificationDocument), variables));
    },
    cityTopPage(variables: CityTopPageQueryVariables): Promise<CityTopPageQuery> {
      return withWrapper(() => client.request<CityTopPageQuery>(print(CityTopPageDocument), variables));
    },
    countryTopPage(variables: CountryTopPageQueryVariables): Promise<CountryTopPageQuery> {
      return withWrapper(() => client.request<CountryTopPageQuery>(print(CountryTopPageDocument), variables));
    },
    topPage(variables?: TopPageQueryVariables): Promise<TopPageQuery> {
      return withWrapper(() => client.request<TopPageQuery>(print(TopPageDocument), variables));
    },
    CompleteBlogCreation(variables: CompleteBlogCreationMutationVariables): Promise<CompleteBlogCreationMutation> {
      return withWrapper(() => client.request<CompleteBlogCreationMutation>(print(CompleteBlogCreationDocument), variables));
    },
    CreateBlog(variables: CreateBlogMutationVariables): Promise<CreateBlogMutation> {
      return withWrapper(() => client.request<CreateBlogMutation>(print(CreateBlogDocument), variables));
    },
    DeleteBlog(variables: DeleteBlogMutationVariables): Promise<DeleteBlogMutation> {
      return withWrapper(() => client.request<DeleteBlogMutation>(print(DeleteBlogDocument), variables));
    },
    incrementPostViews(variables: IncrementPostViewsMutationVariables): Promise<IncrementPostViewsMutation> {
      return withWrapper(() => client.request<IncrementPostViewsMutation>(print(IncrementPostViewsDocument), variables));
    },
    MyBlogs(variables?: MyBlogsQueryVariables): Promise<MyBlogsQuery> {
      return withWrapper(() => client.request<MyBlogsQuery>(print(MyBlogsDocument), variables));
    },
    posts(variables?: PostsQueryVariables): Promise<PostsQuery> {
      return withWrapper(() => client.request<PostsQuery>(print(PostsDocument), variables));
    },
    RelatedPosts(variables: RelatedPostsQueryVariables): Promise<RelatedPostsQuery> {
      return withWrapper(() => client.request<RelatedPostsQuery>(print(RelatedPostsDocument), variables));
    },
    UpdateBlog(variables: UpdateBlogMutationVariables): Promise<UpdateBlogMutation> {
      return withWrapper(() => client.request<UpdateBlogMutation>(print(UpdateBlogDocument), variables));
    },
    postById(variables: PostByIdQueryVariables): Promise<PostByIdQuery> {
      return withWrapper(() => client.request<PostByIdQuery>(print(PostByIdDocument), variables));
    },
    GetDataToAboutUs(variables?: GetDataToAboutUsQueryVariables): Promise<GetDataToAboutUsQuery> {
      return withWrapper(() => client.request<GetDataToAboutUsQuery>(print(GetDataToAboutUsDocument), variables));
    },
    CreateReview(variables: CreateReviewMutationVariables): Promise<CreateReviewMutation> {
      return withWrapper(() => client.request<CreateReviewMutation>(print(CreateReviewDocument), variables));
    },
    ReplyReview(variables: ReplyReviewMutationVariables): Promise<ReplyReviewMutation> {
      return withWrapper(() => client.request<ReplyReviewMutation>(print(ReplyReviewDocument), variables));
    },
    reviews(variables?: ReviewsQueryVariables): Promise<ReviewsQuery> {
      return withWrapper(() => client.request<ReviewsQuery>(print(ReviewsDocument), variables));
    },
    reviewsInTopPage(variables?: ReviewsInTopPageQueryVariables): Promise<ReviewsInTopPageQuery> {
      return withWrapper(() => client.request<ReviewsInTopPageQuery>(print(ReviewsInTopPageDocument), variables));
    },
    UpdateReviewPhotoFlag(variables: UpdateReviewPhotoFlagMutationVariables): Promise<UpdateReviewPhotoFlagMutation> {
      return withWrapper(() => client.request<UpdateReviewPhotoFlagMutation>(print(UpdateReviewPhotoFlagDocument), variables));
    },
    SearchPageTabsData(variables: SearchPageTabsDataQueryVariables): Promise<SearchPageTabsDataQuery> {
      return withWrapper(() => client.request<SearchPageTabsDataQuery>(print(SearchPageTabsDataDocument), variables));
    },
    GetEnviromentInfo(variables?: GetEnviromentInfoQueryVariables): Promise<GetEnviromentInfoQuery> {
      return withWrapper(() => client.request<GetEnviromentInfoQuery>(print(GetEnviromentInfoDocument), variables));
    },
    SitemapBlogWriters(variables?: SitemapBlogWritersQueryVariables): Promise<SitemapBlogWritersQuery> {
      return withWrapper(() => client.request<SitemapBlogWritersQuery>(print(SitemapBlogWritersDocument), variables));
    },
    SitemapDestinations(variables?: SitemapDestinationsQueryVariables): Promise<SitemapDestinationsQuery> {
      return withWrapper(() => client.request<SitemapDestinationsQuery>(print(SitemapDestinationsDocument), variables));
    },
    SitemapPosts(variables?: SitemapPostsQueryVariables): Promise<SitemapPostsQuery> {
      return withWrapper(() => client.request<SitemapPostsQuery>(print(SitemapPostsDocument), variables));
    },
    SitemapSuppliers(variables?: SitemapSuppliersQueryVariables): Promise<SitemapSuppliersQuery> {
      return withWrapper(() => client.request<SitemapSuppliersQuery>(print(SitemapSuppliersDocument), variables));
    },
    SitemapTours(variables?: SitemapToursQueryVariables): Promise<SitemapToursQuery> {
      return withWrapper(() => client.request<SitemapToursQuery>(print(SitemapToursDocument), variables));
    },
    stats(variables?: StatsQueryVariables): Promise<StatsQuery> {
      return withWrapper(() => client.request<StatsQuery>(print(StatsDocument), variables));
    },
    meSupplierAccount(variables?: MeSupplierAccountQueryVariables): Promise<MeSupplierAccountQuery> {
      return withWrapper(() => client.request<MeSupplierAccountQuery>(print(MeSupplierAccountDocument), variables));
    },
    MeSupplierAccountBiographyInfo(variables?: MeSupplierAccountBiographyInfoQueryVariables): Promise<MeSupplierAccountBiographyInfoQuery> {
      return withWrapper(() => client.request<MeSupplierAccountBiographyInfoQuery>(print(MeSupplierAccountBiographyInfoDocument), variables));
    },
    MeSupplierAccountBookingConditions(variables?: MeSupplierAccountBookingConditionsQueryVariables): Promise<MeSupplierAccountBookingConditionsQuery> {
      return withWrapper(() => client.request<MeSupplierAccountBookingConditionsQuery>(print(MeSupplierAccountBookingConditionsDocument), variables));
    },
    MeSupplierAccountLanguages(variables?: MeSupplierAccountLanguagesQueryVariables): Promise<MeSupplierAccountLanguagesQuery> {
      return withWrapper(() => client.request<MeSupplierAccountLanguagesQuery>(print(MeSupplierAccountLanguagesDocument), variables));
    },
    meSupplierAccountPersonalInfo(variables?: MeSupplierAccountPersonalInfoQueryVariables): Promise<MeSupplierAccountPersonalInfoQuery> {
      return withWrapper(() => client.request<MeSupplierAccountPersonalInfoQuery>(print(MeSupplierAccountPersonalInfoDocument), variables));
    },
    meSupplierAccountVideo(variables?: MeSupplierAccountVideoQueryVariables): Promise<MeSupplierAccountVideoQuery> {
      return withWrapper(() => client.request<MeSupplierAccountVideoQuery>(print(MeSupplierAccountVideoDocument), variables));
    },
    MeSupplierAccountWorkingAreas(variables?: MeSupplierAccountWorkingAreasQueryVariables): Promise<MeSupplierAccountWorkingAreasQuery> {
      return withWrapper(() => client.request<MeSupplierAccountWorkingAreasQuery>(print(MeSupplierAccountWorkingAreasDocument), variables));
    },
    MeUserAccountProfilePictureInfo(variables?: MeUserAccountProfilePictureInfoQueryVariables): Promise<MeUserAccountProfilePictureInfoQuery> {
      return withWrapper(() => client.request<MeUserAccountProfilePictureInfoQuery>(print(MeUserAccountProfilePictureInfoDocument), variables));
    },
    SupplierRequestChange(variables: SupplierRequestChangeMutationVariables): Promise<SupplierRequestChangeMutation> {
      return withWrapper(() => client.request<SupplierRequestChangeMutation>(print(SupplierRequestChangeDocument), variables));
    },
    TagBySlug(variables: TagBySlugQueryVariables): Promise<TagBySlugQuery> {
      return withWrapper(() => client.request<TagBySlugQuery>(print(TagBySlugDocument), variables));
    },
    CreateMessageTemplate(variables: CreateMessageTemplateMutationVariables): Promise<CreateMessageTemplateMutation> {
      return withWrapper(() => client.request<CreateMessageTemplateMutation>(print(CreateMessageTemplateDocument), variables));
    },
    DeleteMessageTemplate(variables: DeleteMessageTemplateMutationVariables): Promise<DeleteMessageTemplateMutation> {
      return withWrapper(() => client.request<DeleteMessageTemplateMutation>(print(DeleteMessageTemplateDocument), variables));
    },
    SupplierMessageTemplateById(variables: SupplierMessageTemplateByIdQueryVariables): Promise<SupplierMessageTemplateByIdQuery> {
      return withWrapper(() => client.request<SupplierMessageTemplateByIdQuery>(print(SupplierMessageTemplateByIdDocument), variables));
    },
    SupplierMessageTemplates(variables?: SupplierMessageTemplatesQueryVariables): Promise<SupplierMessageTemplatesQuery> {
      return withWrapper(() => client.request<SupplierMessageTemplatesQuery>(print(SupplierMessageTemplatesDocument), variables));
    },
    UpdateMessageTemplate(variables: UpdateMessageTemplateMutationVariables): Promise<UpdateMessageTemplateMutation> {
      return withWrapper(() => client.request<UpdateMessageTemplateMutation>(print(UpdateMessageTemplateDocument), variables));
    },
    CreateTour(variables: CreateTourMutationVariables): Promise<CreateTourMutation> {
      return withWrapper(() => client.request<CreateTourMutation>(print(CreateTourDocument), variables));
    },
    DeleteTour(variables: DeleteTourMutationVariables): Promise<DeleteTourMutation> {
      return withWrapper(() => client.request<DeleteTourMutation>(print(DeleteTourDocument), variables));
    },
    DuplicateTour(variables: DuplicateTourMutationVariables): Promise<DuplicateTourMutation> {
      return withWrapper(() => client.request<DuplicateTourMutation>(print(DuplicateTourDocument), variables));
    },
    InactiveTour(variables: InactiveTourMutationVariables): Promise<InactiveTourMutation> {
      return withWrapper(() => client.request<InactiveTourMutation>(print(InactiveTourDocument), variables));
    },
    incrementTourViews(variables: IncrementTourViewsMutationVariables): Promise<IncrementTourViewsMutation> {
      return withWrapper(() => client.request<IncrementTourViewsMutation>(print(IncrementTourViewsDocument), variables));
    },
    RecommendedDataByBookingId(variables: RecommendedDataByBookingIdQueryVariables): Promise<RecommendedDataByBookingIdQuery> {
      return withWrapper(() => client.request<RecommendedDataByBookingIdQuery>(print(RecommendedDataByBookingIdDocument), variables));
    },
    RelatedToursByPost(variables: RelatedToursByPostQueryVariables): Promise<RelatedToursByPostQuery> {
      return withWrapper(() => client.request<RelatedToursByPostQuery>(print(RelatedToursByPostDocument), variables));
    },
    SubmitTour(variables: SubmitTourMutationVariables): Promise<SubmitTourMutation> {
      return withWrapper(() => client.request<SubmitTourMutation>(print(SubmitTourDocument), variables));
    },
    SupplierTourrById(variables: SupplierTourrByIdQueryVariables): Promise<SupplierTourrByIdQuery> {
      return withWrapper(() => client.request<SupplierTourrByIdQuery>(print(SupplierTourrByIdDocument), variables));
    },
    SupplierTours(variables?: SupplierToursQueryVariables): Promise<SupplierToursQuery> {
      return withWrapper(() => client.request<SupplierToursQuery>(print(SupplierToursDocument), variables));
    },
    tourById(variables: TourByIdQueryVariables): Promise<TourByIdQuery> {
      return withWrapper(() => client.request<TourByIdQuery>(print(TourByIdDocument), variables));
    },
    TourByIdTourPreview(variables: TourByIdTourPreviewQueryVariables): Promise<TourByIdTourPreviewQuery> {
      return withWrapper(() => client.request<TourByIdTourPreviewQuery>(print(TourByIdTourPreviewDocument), variables));
    },
    tourCalendar(variables: TourCalendarQueryVariables): Promise<TourCalendarQuery> {
      return withWrapper(() => client.request<TourCalendarQuery>(print(TourCalendarDocument), variables));
    },
    tourPrice(variables: TourPriceQueryVariables): Promise<TourPriceQuery> {
      return withWrapper(() => client.request<TourPriceQuery>(print(TourPriceDocument), variables));
    },
    tourScarcity(variables: TourScarcityQueryVariables): Promise<TourScarcityQuery> {
      return withWrapper(() => client.request<TourScarcityQuery>(print(TourScarcityDocument), variables));
    },
    tours(variables?: ToursQueryVariables): Promise<ToursQuery> {
      return withWrapper(() => client.request<ToursQuery>(print(ToursDocument), variables));
    },
    UpdateTour(variables: UpdateTourMutationVariables): Promise<UpdateTourMutation> {
      return withWrapper(() => client.request<UpdateTourMutation>(print(UpdateTourDocument), variables));
    },
    Authenticate(variables: AuthenticateMutationVariables): Promise<AuthenticateMutation> {
      return withWrapper(() => client.request<AuthenticateMutation>(print(AuthenticateDocument), variables));
    },
    AuthenticateWithFacebook(variables: AuthenticateWithFacebookMutationVariables): Promise<AuthenticateWithFacebookMutation> {
      return withWrapper(() => client.request<AuthenticateWithFacebookMutation>(print(AuthenticateWithFacebookDocument), variables));
    },
    AuthenticateWithGoogle(variables: AuthenticateWithGoogleMutationVariables): Promise<AuthenticateWithGoogleMutation> {
      return withWrapper(() => client.request<AuthenticateWithGoogleMutation>(print(AuthenticateWithGoogleDocument), variables));
    },
    blogWriterById(variables: BlogWriterByIdQueryVariables): Promise<BlogWriterByIdQuery> {
      return withWrapper(() => client.request<BlogWriterByIdQuery>(print(BlogWriterByIdDocument), variables));
    },
    ChangeUserPasswordByToken(variables: ChangeUserPasswordByTokenMutationVariables): Promise<ChangeUserPasswordByTokenMutation> {
      return withWrapper(() => client.request<ChangeUserPasswordByTokenMutation>(print(ChangeUserPasswordByTokenDocument), variables));
    },
    CloseCalendarDate(variables: CloseCalendarDateMutationVariables): Promise<CloseCalendarDateMutation> {
      return withWrapper(() => client.request<CloseCalendarDateMutation>(print(CloseCalendarDateDocument), variables));
    },
    Contact(variables: ContactMutationVariables): Promise<ContactMutation> {
      return withWrapper(() => client.request<ContactMutation>(print(ContactDocument), variables));
    },
    CreateUser(variables: CreateUserMutationVariables): Promise<CreateUserMutation> {
      return withWrapper(() => client.request<CreateUserMutation>(print(CreateUserDocument), variables));
    },
    OpenCalendarDate(variables: OpenCalendarDateMutationVariables): Promise<OpenCalendarDateMutation> {
      return withWrapper(() => client.request<OpenCalendarDateMutation>(print(OpenCalendarDateDocument), variables));
    },
    RecoverUserPassword(variables: RecoverUserPasswordMutationVariables): Promise<RecoverUserPasswordMutation> {
      return withWrapper(() => client.request<RecoverUserPasswordMutation>(print(RecoverUserPasswordDocument), variables));
    },
    RemoveSupplierPayPal(variables?: RemoveSupplierPayPalMutationVariables): Promise<RemoveSupplierPayPalMutation> {
      return withWrapper(() => client.request<RemoveSupplierPayPalMutation>(print(RemoveSupplierPayPalDocument), variables));
    },
    supplierBookingsForPeriod(variables?: SupplierBookingsForPeriodQueryVariables): Promise<SupplierBookingsForPeriodQuery> {
      return withWrapper(() => client.request<SupplierBookingsForPeriodQuery>(print(SupplierBookingsForPeriodDocument), variables));
    },
    SupplierAllToursToRefreshCache(variables: SupplierAllToursToRefreshCacheQueryVariables): Promise<SupplierAllToursToRefreshCacheQuery> {
      return withWrapper(() => client.request<SupplierAllToursToRefreshCacheQuery>(print(SupplierAllToursToRefreshCacheDocument), variables));
    },
    SupplierAnalytics(variables?: SupplierAnalyticsQueryVariables): Promise<SupplierAnalyticsQuery> {
      return withWrapper(() => client.request<SupplierAnalyticsQuery>(print(SupplierAnalyticsDocument), variables));
    },
    SupplierAnalyticsById(variables: SupplierAnalyticsByIdQueryVariables): Promise<SupplierAnalyticsByIdQuery> {
      return withWrapper(() => client.request<SupplierAnalyticsByIdQuery>(print(SupplierAnalyticsByIdDocument), variables));
    },
    SupplierApply(variables: SupplierApplyMutationVariables): Promise<SupplierApplyMutation> {
      return withWrapper(() => client.request<SupplierApplyMutation>(print(SupplierApplyDocument), variables));
    },
    SupplierBookingById(variables: SupplierBookingByIdQueryVariables): Promise<SupplierBookingByIdQuery> {
      return withWrapper(() => client.request<SupplierBookingByIdQuery>(print(SupplierBookingByIdDocument), variables));
    },
    supplierById(variables: SupplierByIdQueryVariables): Promise<SupplierByIdQuery> {
      return withWrapper(() => client.request<SupplierByIdQuery>(print(SupplierByIdDocument), variables));
    },
    supplierForCreatePaymentRequest(variables: SupplierForCreatePaymentRequestQueryVariables): Promise<SupplierForCreatePaymentRequestQuery> {
      return withWrapper(() => client.request<SupplierForCreatePaymentRequestQuery>(print(SupplierForCreatePaymentRequestDocument), variables));
    },
    SupplierCalendarById(variables: SupplierCalendarByIdQueryVariables): Promise<SupplierCalendarByIdQuery> {
      return withWrapper(() => client.request<SupplierCalendarByIdQuery>(print(SupplierCalendarByIdDocument), variables));
    },
    supplierPaymentsForMonthlyInvoice(variables?: SupplierPaymentsForMonthlyInvoiceQueryVariables): Promise<SupplierPaymentsForMonthlyInvoiceQuery> {
      return withWrapper(() => client.request<SupplierPaymentsForMonthlyInvoiceQuery>(print(SupplierPaymentsForMonthlyInvoiceDocument), variables));
    },
    supplierScarcity(variables: SupplierScarcityQueryVariables): Promise<SupplierScarcityQuery> {
      return withWrapper(() => client.request<SupplierScarcityQuery>(print(SupplierScarcityDocument), variables));
    },
    SupplierWithPaymentById(variables: SupplierWithPaymentByIdQueryVariables): Promise<SupplierWithPaymentByIdQuery> {
      return withWrapper(() => client.request<SupplierWithPaymentByIdQuery>(print(SupplierWithPaymentByIdDocument), variables));
    },
    supplierWithPayments(variables?: SupplierWithPaymentsQueryVariables): Promise<SupplierWithPaymentsQuery> {
      return withWrapper(() => client.request<SupplierWithPaymentsQuery>(print(SupplierWithPaymentsDocument), variables));
    },
    suppliers(variables?: SuppliersQueryVariables): Promise<SuppliersQuery> {
      return withWrapper(() => client.request<SuppliersQuery>(print(SuppliersDocument), variables));
    },
    UpdateCustomerAccount(variables: UpdateCustomerAccountMutationVariables): Promise<UpdateCustomerAccountMutation> {
      return withWrapper(() => client.request<UpdateCustomerAccountMutation>(print(UpdateCustomerAccountDocument), variables));
    },
    UpdateSupplierAccount(variables: UpdateSupplierAccountMutationVariables): Promise<UpdateSupplierAccountMutation> {
      return withWrapper(() => client.request<UpdateSupplierAccountMutation>(print(UpdateSupplierAccountDocument), variables));
    },
    UpdateSupplierBank(variables: UpdateSupplierBankMutationVariables): Promise<UpdateSupplierBankMutation> {
      return withWrapper(() => client.request<UpdateSupplierBankMutation>(print(UpdateSupplierBankDocument), variables));
    },
    UpdateSupplierGallery(variables: UpdateSupplierGalleryMutationVariables): Promise<UpdateSupplierGalleryMutation> {
      return withWrapper(() => client.request<UpdateSupplierGalleryMutation>(print(UpdateSupplierGalleryDocument), variables));
    },
    UpdateSupplierPaypal(variables: UpdateSupplierPaypalMutationVariables): Promise<UpdateSupplierPaypalMutation> {
      return withWrapper(() => client.request<UpdateSupplierPaypalMutation>(print(UpdateSupplierPaypalDocument), variables));
    },
    toursOfSupplierById(variables: ToursOfSupplierByIdQueryVariables): Promise<ToursOfSupplierByIdQuery> {
      return withWrapper(() => client.request<ToursOfSupplierByIdQuery>(print(ToursOfSupplierByIdDocument), variables));
    },
    AcceptBooking(variables: AcceptBookingMutationVariables): Promise<AcceptBookingMutation> {
      return withWrapper(() => client.request<AcceptBookingMutation>(print(AcceptBookingDocument), variables));
    },
    AddCouponToBooking(variables: AddCouponToBookingMutationVariables): Promise<AddCouponToBookingMutation> {
      return withWrapper(() => client.request<AddCouponToBookingMutation>(print(AddCouponToBookingDocument), variables));
    },
    AuthorizePayPalOrder(variables: AuthorizePayPalOrderMutationVariables): Promise<AuthorizePayPalOrderMutation> {
      return withWrapper(() => client.request<AuthorizePayPalOrderMutation>(print(AuthorizePayPalOrderDocument), variables));
    },
    BookingPaymentError(variables: BookingPaymentErrorMutationVariables): Promise<BookingPaymentErrorMutation> {
      return withWrapper(() => client.request<BookingPaymentErrorMutation>(print(BookingPaymentErrorDocument), variables));
    },
    CalculateBookingCancellationPolicyRule(variables: CalculateBookingCancellationPolicyRuleMutationVariables): Promise<CalculateBookingCancellationPolicyRuleMutation> {
      return withWrapper(() => client.request<CalculateBookingCancellationPolicyRuleMutation>(print(CalculateBookingCancellationPolicyRuleDocument), variables));
    },
    CancelBooking(variables: CancelBookingMutationVariables): Promise<CancelBookingMutation> {
      return withWrapper(() => client.request<CancelBookingMutation>(print(CancelBookingDocument), variables));
    },
    CompleteBooking(variables: CompleteBookingMutationVariables): Promise<CompleteBookingMutation> {
      return withWrapper(() => client.request<CompleteBookingMutation>(print(CompleteBookingDocument), variables));
    },
    CreateBookingQuotation(variables: CreateBookingQuotationMutationVariables): Promise<CreateBookingQuotationMutation> {
      return withWrapper(() => client.request<CreateBookingQuotationMutation>(print(CreateBookingQuotationDocument), variables));
    },
    CreatePayPalOrder(variables: CreatePayPalOrderMutationVariables): Promise<CreatePayPalOrderMutation> {
      return withWrapper(() => client.request<CreatePayPalOrderMutation>(print(CreatePayPalOrderDocument), variables));
    },
    CreateTourBooking(variables: CreateTourBookingMutationVariables): Promise<CreateTourBookingMutation> {
      return withWrapper(() => client.request<CreateTourBookingMutation>(print(CreateTourBookingDocument), variables));
    },
    CreateTourQuotation(variables: CreateTourQuotationMutationVariables): Promise<CreateTourQuotationMutation> {
      return withWrapper(() => client.request<CreateTourQuotationMutation>(print(CreateTourQuotationDocument), variables));
    },
    CreateVeritransPayment(variables: CreateVeritransPaymentMutationVariables): Promise<CreateVeritransPaymentMutation> {
      return withWrapper(() => client.request<CreateVeritransPaymentMutation>(print(CreateVeritransPaymentDocument), variables));
    },
    CustomerBookingDetail(variables: CustomerBookingDetailQueryVariables): Promise<CustomerBookingDetailQuery> {
      return withWrapper(() => client.request<CustomerBookingDetailQuery>(print(CustomerBookingDetailDocument), variables));
    },
    CustomerBookings(variables?: CustomerBookingsQueryVariables): Promise<CustomerBookingsQuery> {
      return withWrapper(() => client.request<CustomerBookingsQuery>(print(CustomerBookingsDocument), variables));
    },
    CustomerReviews(variables?: CustomerReviewsQueryVariables): Promise<CustomerReviewsQuery> {
      return withWrapper(() => client.request<CustomerReviewsQuery>(print(CustomerReviewsDocument), variables));
    },
    DeletePendingBooking(variables: DeletePendingBookingMutationVariables): Promise<DeletePendingBookingMutation> {
      return withWrapper(() => client.request<DeletePendingBookingMutation>(print(DeletePendingBookingDocument), variables));
    },
    SupplierAsCustomerBookings(variables?: SupplierAsCustomerBookingsQueryVariables): Promise<SupplierAsCustomerBookingsQuery> {
      return withWrapper(() => client.request<SupplierAsCustomerBookingsQuery>(print(SupplierAsCustomerBookingsDocument), variables));
    },
    SupplierAsCustomerReviews(variables?: SupplierAsCustomerReviewsQueryVariables): Promise<SupplierAsCustomerReviewsQuery> {
      return withWrapper(() => client.request<SupplierAsCustomerReviewsQuery>(print(SupplierAsCustomerReviewsDocument), variables));
    },
    SupplierBookingDetail(variables: SupplierBookingDetailQueryVariables): Promise<SupplierBookingDetailQuery> {
      return withWrapper(() => client.request<SupplierBookingDetailQuery>(print(SupplierBookingDetailDocument), variables));
    },
    SupplierBookings(variables?: SupplierBookingsQueryVariables): Promise<SupplierBookingsQuery> {
      return withWrapper(() => client.request<SupplierBookingsQuery>(print(SupplierBookingsDocument), variables));
    },
    SupplierBookingsForCalendar(variables?: SupplierBookingsForCalendarQueryVariables): Promise<SupplierBookingsForCalendarQuery> {
      return withWrapper(() => client.request<SupplierBookingsForCalendarQuery>(print(SupplierBookingsForCalendarDocument), variables));
    },
    SupplierReviews(variables?: SupplierReviewsQueryVariables): Promise<SupplierReviewsQuery> {
      return withWrapper(() => client.request<SupplierReviewsQuery>(print(SupplierReviewsDocument), variables));
    },
    UpdateBooking(variables: UpdateBookingMutationVariables): Promise<UpdateBookingMutation> {
      return withWrapper(() => client.request<UpdateBookingMutation>(print(UpdateBookingDocument), variables));
    },
    OpenAiDataRequest(variables: OpenAiDataRequestMutationVariables): Promise<OpenAiDataRequestMutation> {
      return withWrapper(() => client.request<OpenAiDataRequestMutation>(print(OpenAiDataRequestDocument), variables));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;