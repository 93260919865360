import { GraphQLClient } from "graphql-request";
import { getSdk, SdkFunctionWrapper } from "@graphql/types";
import cookies from "./cookies";
import {
  COOKIE_TOKEN,
  API_HOST,
  COOKIE_CURRENCY,
  DEFAULT_CURRENCY,
  CURRENCY_BY_LOCALE,
  COOKIE_USER_ME,
  COOKIE_LOCALE,
} from "@constants";
import { ClientError } from "graphql-request";

const redirectOnError = () => {
  cookies().remove(COOKIE_TOKEN);
  cookies().remove(COOKIE_USER_ME);

  if (typeof window !== "undefined") {
    window.location.href = `/login?unauthenticated`;
  }
};

interface APIErrors {
  response: {
    errors: ClientError["response"] | undefined;
  };
}

const onError: SdkFunctionWrapper = async <T>(action: () => Promise<T>) => {
  return action()
    .then((data) => data)
    .catch((graphqlErrors: APIErrors) => {
      if (graphqlErrors.response && graphqlErrors.response.errors) {
        const graphlError = graphqlErrors.response.errors[0];

        if (
          graphlError.extensions &&
          (graphlError.extensions["code"] === "UNAUTHENTICATED" ||
            graphlError.extensions["code"] === "FORBIDDEN")
        ) {
          redirectOnError();
          return new Promise(() => ({ data: undefined }));
        }
      }
      throw graphqlErrors;
    });
};

export const getCurrency = () => {
  const currency = cookies().get(COOKIE_CURRENCY);
  if (currency) {
    return currency;
  }

  cookies().set(COOKIE_CURRENCY, DEFAULT_CURRENCY);
  return DEFAULT_CURRENCY;
};

export const getAuthorization = () => {
  return cookies().get(COOKIE_TOKEN) || "";
};

export const defaultHeaders = () => {
  return {
    authorization: getAuthorization(),
    locale: cookies().get(COOKIE_LOCALE) || "en",
    currency: getCurrency(),
    ...removeEmptyValues({
      "gwg-session-id": cookies().get("_gq") || "", // google analytics cookie
    }),
  };
};

const client = () => {
  return new GraphQLClient(API_HOST, {
    headers: defaultHeaders(),
  });
};

export let graphql = getSdk(client(), onError);

//In case of send message, if user is not authenticated,
//User types the message , and then on click send message
//Login page is opened, local login is done(set token), send message to api
//and then sync with old app.For that refresh is needed

export function refreshGraphQl(headers?: HeadersInit) {
  const newClient = client().setHeaders(headers);
  graphql = getSdk(newClient, onError);
  return graphql;
}

export const useGraphQL = (
  token?: string,
  metadata?: Record<"gwg-ip-country" | "gwg-session-id", string>
) => {
  const client = new GraphQLClient(API_HOST, {
    headers: {
      ...defaultHeaders(),
      ...(metadata ? removeEmptyValues(metadata) : {}),
      ...(token ? { authorization: token } : {}),
    },
  });

  return getSdk(client);
};

export const isStaleData = () => {
  const currentLocale = cookies().get(COOKIE_LOCALE) as string;
  const currentCurrency = cookies().get(COOKIE_CURRENCY);
  const defaultCurrency = CURRENCY_BY_LOCALE[currentLocale];

  if (currentCurrency && defaultCurrency != currentCurrency) {
    return true;
  }

  return false;
};

function removeEmptyValues(
  obj: Record<string, string>
): Record<string, string> {
  return (
    Object.entries(obj)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, value]) => value !== "") // Filter out empty string values
      .reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as Record<string, string>
      )
  );
}
