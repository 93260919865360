import BugsnagJS from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { BUGSNAG_KEY } from "../constants";

BugsnagJS.start({
  apiKey: BUGSNAG_KEY,
  appType: "web",
  releaseStage: process.env.NEXT_PUBLIC_BUGSNAG_ENV,
  enabledReleaseStages: ["production"],
  plugins: [new BugsnagPluginReact()],
  appVersion: "0.2.27",
});

export const bugsnag = BugsnagJS;
