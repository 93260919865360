import { AWIN_CONVERSION_QUERY_PARAM } from "@constants";
import cookies from "@helpers/cookies";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const useAwinServerTracking = () => {
  const router = useRouter();
  useEffect(() => {
    const awinConversionParam = cookies().get(AWIN_CONVERSION_QUERY_PARAM);

    // Set to the cookie only if we get different value to avoid refresh and update expiry
    if (
      router.query.awc &&
      awinConversionParam != (router.query.awc as string)
    ) {
      // Expires in 30 days.
      cookies().setWithExpiry(
        AWIN_CONVERSION_QUERY_PARAM,
        30,
        router.query.awc as string
      );
    }
  }, [router.isReady, router.query]);
  return null;
};
